import React, { useEffect } from "react";
import CommonService from "../services/CommonService";
import useAsyncEffect from "../utility/use-async-effect";
const Maintenance = (props) => {
  let successAttempts = 0;
  useAsyncEffect(
    async (isMounted) => {
      try {
        // let resp = await CommonService.status();
        // console.log(resp)
        // if(resp.data == '<STATUS>OK</STATUS>') window.location.href ="/"
        if (!isMounted()) return;

        // setInterval(async () => {
        //   await HealthCheck();
        // }, 30000);

        async function HealthCheck() {
          let status = await CommonService.statusOk();
          if (successAttempts == 2) window.location.href = "/";

          if (status) successAttempts++;
        }
      } catch (error) {}
    },
    () => {},
    []
  );
  return (
    <div className="text-center">
      <article className="construction">
        <img
          style={{ width: 300 + "px" }}
          src="https://s3.amazonaws.com/connectria-branding/Connectria_logo.png"
        />

        <h1 style={{ fontSize: 50 + "px" }}>TRiA will be back soon!</h1>
        <div>
          <p>
            TRiA is currently unavailable at this time due to scheduled
            maintenance. Please contact our Connectria Command Center for any of
            your questions or ticketing needs while the site is down. Thank you
            for your patience.
          </p>
          <p>
            Connectria Command Center{" "}
            <a className="contrsuction-a " href="tel:1-800-781-7178">
              1-800-781-7178
            </a>
          </p>
          <p>- TRIA Team</p>
        </div>
      </article>
    </div>
  );
};

export default Maintenance;