import React from "react";
import { useState } from "react"
import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";

import {Card, CardHeader, CardBody, CardTitle} from "reactstrap";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";

const ContactDashboard = (props) => {
  const [dynamicContacts, setDynamicContacts] = useState([]);
  const [staticContacts, setStaticContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const source = axios.CancelToken.source();
  useAsyncEffect(
    async isMounted => {
      try {

        let contacts = await ConnectriaTicketsService.getParamData({
          cancelToken: source.token
        });
        console.log(`here is the contacts ${contacts}`);
        if (!isMounted()) return;

        // Only get contacts with these titles
        const contactTypes = ['TAM', 'Account Manager', 'Customer Success Manager'];
        let dynamicContacts = contacts.dynamic.response.data.customer_account_agents;
        dynamicContacts = (dynamicContacts || []).filter((contact) => { return contactTypes.includes(contact['agent_type']['name']) });

        // Filter out these titles, feedback was they are redundant
        const staticFilteredTypes = ['Sales Questions', 'Technical Support'];
        let staticContacts = contacts.static;
        staticContacts = staticContacts.filter((contact) => { return !staticFilteredTypes.includes(contact.title) });
        // Find Connectria Helpdesk and change to Command Center
        for (var contact of staticContacts) {
          if (contact.title == "Connectria Helpdesk") {
            contact.title = "Connectria Command Center";
            contact.name = "Command Center";
          }
        }

        setDynamicContacts(dynamicContacts);
        setStaticContacts(staticContacts);
        setIsLoading(false);
      
      } catch (error) {
        if (axios.isCancel(error)) {
        console.log(error);
          
        // request cancelled
        } else {
        console.log(error);
        setIsError(true);
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <>
      {isLoading && 
        <div className="text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: '30px' }}></i>
        </div>}
      {!isLoading &&
    <>
    <div className="row">
      <div className="col-md-12">
      <h2>Contacts</h2>
       <br></br>
      </div>
    </div>
      <div className="row">
       
      {dynamicContacts.map((contact, i) => {
        return (
          <ContactCard 
            title={contact['agent_type']['name'] + (contact['customer_service_primary'] === true ? " - Primary Contact" : "")}
            phone={[contact['user']['phone']]}
            email={contact['user']['email']}
            name={contact['user']['fname'] + ' ' + contact['user']['surname']}
            key={i}/>
        )
      })}
      {staticContacts.map((contact, i) => {
        return (
          <ContactCard 
            title={contact.title}
            phone={contact.phone}
            email={contact.email}
            name={contact.name}
            key={i}/>
        )
      })}
      </div></>
      
      }
    </>
  )
}

const ContactCard = (props) => {
  return (
    <div className="col-md-4">
    <Card className="card-minimal">
      <CardHeader className="card-header-minimal">
        <CardTitle className="card-title-minimal" tag="h3">
          {props.title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {props.name &&
        <div className="row">
          <div className="col-4 font-weight-bold">Name</div>
          <div className="col-md-8 pl-3">{props.name}</div>
        </div>}
        {props.phone.map((number, i) => {
          return (
            <div className="row" key={i}>
              <div className="col-4 font-weight-bold">Phone</div>
              <div className="col-md-8 pl-3">{number}</div>
            </div>
          )
        })}
        {props.email &&
        <div className="row">
          <div className="col-4 font-weight-bold">Email</div>
          <div className="col-md-8 pl-3">{props.email}</div>
        </div>}
      </CardBody>
    </Card>
    </div>
  )
}

export default ContactDashboard;