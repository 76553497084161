import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { appState } from "../../AppState";

import ConnectriaBackupService from "../../services/ConnectriaBackupService";
import moment from 'moment';
import { isMobile } from "react-device-detect";

import {
  VictoryChart,
  VictoryPie,
  VictoryLabel,
  VictoryLegend,
  VictoryAxis
} from 'victory';

const colors = [
  '#006494',    //Completed
  '#f8ca24',    //Completed with errors
  '#1bc5bd',    //In Progress
  '#24aece',    //No Backups
  '#d24727'     //Unsuccessful
];

//CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

const BackupsDashboardCard = props => {
  const [stateApp, stateAppActions] = appState();
  const [jobs,setJobs] = useState([]);
  const [groupStats, setGroupStats] = useState({});
  const [timeRange, setTimeRange] = useState(stateApp.commserveTimeRange || "Last 7 Days")
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const history = useHistory();
  const source = axios.CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const legendLabels = [
    {name: "Completed"},
    {name: "Completed With Errors"},
    {name: "In Progess"},
    {name: "No Backups"},
    {name: "Unsuccessful"}
  ]

  const findNullJobCounts = (counts) => {
    for (var [key, value] of Object.entries(counts)) {
      if (value != null) {
        // Not all values are null
        return false;
      }
    }
    // All values were null
    return true;
  }

  const createNewTicket = (e) => {
    if (e != null) {
        e.preventDefault();
    }
    history.push({
      pathname: "/app/create-ticket",
      state: {
        closeOnSuccess: true,
        subject: 'TRiA did not identify Backup services associated with my account and this is an error.',
        description: 'TRiA did not identify Backup services associated with my account and this is an error.',
        attachmentElementId: ''
      }
    });
  }

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);

        // Get the client groups
        let clientsRes = await ConnectriaBackupService.getCommServeClientGroup({cancelToken: source.token});

        let clients = clientsRes['data'];
        if (clients.length == 0) {
          setIsError(true);
          return;
        }
        let clientGroupId = clients[0].GroupId;
        let groupStatsRes = await ConnectriaBackupService.getCommServeClientGroupStats(clientGroupId, {cancelToken: source.token});
        if (groupStatsRes.data.message || findNullJobCounts(groupStatsRes.data[0])) {
          setIsError(true);
          return;
        }
        let groupStats = groupStatsRes.data[0];

        if (!isMounted()) return;

        setGroupStats(groupStats);
        setIsLoading(false);

      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error)
          // request cancelled
        } else {
          console.log(error)
          setIsError(true);
          setIsLoading(false);
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  const toggleTimeRange = (newRange) => {
    setTimeRange(newRange);
    stateAppActions.setCommserveTimeRange(newRange)
  }

  useEffect(() => {
    let numDays;
    if (timeRange == "Last 24 Hours") {
      numDays = "1";
    } else if (timeRange == "Last 7 Days") {
      numDays = "7";
    } else if (timeRange == "Last 30 Days") {
      numDays = "30";
    }

    var statusCounts = {
      "Completed": groupStats["completed_" + numDays],
      "In Progress": groupStats["inProgress_" + numDays],
      "Completed With Errors": groupStats["completedWithErrors_" + numDays],
      "No Backups": groupStats["noBackups_" + numDays],
      "Unsuccessful": groupStats["unsuccessful_" + numDays]
    };

    var data = []
    // Get the data in the format our chart will recognize (specified by the chart's 'x' and 'y' props)
    for (var [key, value] of Object.entries(statusCounts)) {
      data.push({status: key, count: value})
    }
    // Sort the data alphabetically by status
    data.sort((a, b) => (a.status > b.status) ? 1 : -1)

    setJobs(data);

  }, [timeRange, groupStats]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <h3 className="text-left">Backups</h3>
        <div>
        <div className="row">
              {!isError && 
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                  style={{ position: "relative", right: "0px", top: "0px", paddingLeft: "7px" }}
                  className=""
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                  >
                    {timeRange}
                    <i className={"fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => { toggleTimeRange("Last 24 Hours") }}>
                      Last 24 Hours
                    </DropdownItem>
                    <DropdownItem onClick={() => { toggleTimeRange("Last 7 Days") }}>
                      Last 7 Days
                    </DropdownItem>
                    <DropdownItem onClick={() => { toggleTimeRange("Last 30 Days") }}>
                      Last 30 Days
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>}
              </div>
          {isLoading ?
            <i
              className="fas fa-spinner m-3 mt-4 fa-spin"
              style={{ fontSize: "30px" }}
            /> :
            <>
              {isError ?
                (<div className="text-center">
                  <p className="pt-3 pb-3">
                    TRiA did not identify Backup services associated with your account. Please contact us if this is an error.
                  </p>
                  <Button className="btn-report" size="md" onClick={createNewTicket}>Report an Error</Button>
                </div>) :
                jobs.length == 0 && !isLoading ? 
                  <div className="text-center mt-4 text-bold"><i className="fas fa-exclamation-triangle"></i> No cloud alarms found. Try adjusting your filter.</div> :
                  <>
                    {isMobile ? 
                      <>
                        <VDoughnutChart data={jobs} />
                        <VChartLegend legendLabels={legendLabels} itemsPerRow={3} height={50}/>
                      </> :
                      <div className="row">
                        <div className="col-8 p-0 pt-2">
                          <VDoughnutChart data={jobs}/>
                        </div>
                        <div className="col-4 p-0">
                          <VChartLegend legendLabels={legendLabels} orientation={"vertical"} width={175} height={50} y={-50}/>
                      </div>
                    </div>}
                  </>}
            </>
          }
        </div>
      </div>
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push("/app/backup");
        }}
      >
        {" "}
        <span
          style={{ display: "inline", verticalAlign: "middle" }}
          className="fas fa-chart-line"
        ></span>{" "}
        View Detail
      </button>
    </div>
  )
}

const VChartLegend = (props) => {
  return (
    <VictoryLegend 
      {...props}
      style={{ border: { stroke: "none" }}}
      colorScale={colors}
      data={props.legendLabels}
    />
  )
}

const VDoughnutChart = (props) => {
  const [state, setState] = useState(props.data[0].status || "");
  const [count, setCount] = useState(props.data[0].count || "");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setState(props.data[index].status);
    setCount(props.data[index].count);
  }, [props.data])

  return (
    <VictoryChart height={400} width={400}>
      <VictoryPie
        data={props.data}
        x={"status"} y={"count"}
        colorScale={colors}
        labels={() => null}
        radius={({ datum }) => datum.status == "Completed" ? 175 : 150}
        innerRadius={125}
        height={400}
        width={400}
        events={[
          {
            target: ["data"],
            eventHandlers: {
              onClick: (_, selectedSlice) => ({
                target: ["data"],
                eventKey: "all",
                mutation: (nextSlice) => {
                var radius;
                if (selectedSlice.index == index) {
                  setIndex(-1)
                  setState("")
                  setCount("")
                  radius = 150;
                } else {
                  setIndex(selectedSlice.index)
                  setState(selectedSlice.datum.status)
                  setCount(selectedSlice.datum.count)
                  if (index == nextSlice.index) {
                    radius = 150;
                  } else if ((typeof nextSlice.radius === "function" || nextSlice.radius == 150) && nextSlice.index == selectedSlice.index) {
                    radius = 175;
                  } else {
                    radius = 150;
                  }
                }
                return {
                  radius: radius
                }}
              })
            }
          }
        ]}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 40 }}
        x={200} y={180}
        text={count}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 18, color: "gray" }}
        x={200} y={220}
        text={state.toUpperCase()}
      />
      <VictoryAxis style={{ 
          axis: {stroke: "transparent"}, 
          ticks: {stroke: "transparent"},
          tickLabels: { fill:"transparent"} 
      }}/>
    </VictoryChart>
  )
}

export { BackupsDashboardCard };