import React, {useState, useEffect, Suspense} from "react";

import {appState} from "../AppState";
import TabbedDashboard from "../components/Dashboard/TabbedDashboard";
import NotificationsWidget from "../components/LightEdgeNotifications/NotificationsWidget.js";
import {DynamicDashboardComponents} from "../components/Dashboard/DynamicDashboard";

import {dashBoardConfig, dashBoardConfigIbmi} from "../routes.js";
import Enum from "../utility/enum.js";
import ConnectriaTicketsService from "../services/ConnectriaTicketsService";
import { matchedClouds, matchedIntegrations, matchedEnv, hasPolicies } from "../utility/FeatureToggle";

var waitForIt;
const _retries = 3
let retryCount = 0;

// cache function doesn't save data to application state!!!
const loadCache = async (stateApp, stateAppActions) => {
  try {
    let isCustomer = stateApp.userInfo.organization_id !== 0;
    console.log('Load users to cache')
    await ConnectriaTicketsService.getAllUsersLike({}, isCustomer)
    await ConnectriaTicketsService.ticketCategories({})
    await ConnectriaTicketsService.getAllDevicesLike({
      ar_assoc: 'devicetype',
      limit: 100
    })
  } catch (e) {
    console.error(e);
    if (retryCount < _retries) {
      //loadCache(stateApp, stateAppActions)
      retryCount++;
    } else {
      //set to empty
      console.error('setting empty state');
      stateAppActions.setCache({key: "connectriaTicketCC", data: []});
      stateAppActions.setCache({
        key: "connectriaTicketCategories",
        data: [],
      });
      stateAppActions.setCache({
        key: "connectriaTicketDevices",
        data: [],
      });
      stateAppActions.setCache({
        key: "costSavings",
        data: [],
      });
    }
  }
};

function ResponsiveDashboard(props) {
  const [stateApp, stateAppActions] = appState();
  const policies = stateApp.policies;
  
  const user_roles = stateApp.roles_v2;
  useEffect(async () => {
    

  if (props.width === 0) {
    return null;
  }

  //only modify if not a super admin or org admin

  if (true){
  //if (!stateApp.superAdmin && user_roles.filter((e) => e.RoleName == "OrgAdmin").length == 0){
   
  for(var i = 0; i< dashBoardConfig.length; i++){
    let cards = dashBoardConfig[i].cards

    for(var x = 0; x < cards.length; x++){
      let comp = cards[x].components || [];
  
      for(var y = 0; y < comp.length; y++){
        //***curently only has support for one policy
        


        let criteriaMet = matchedClouds(comp[y].props.clouds) && matchedIntegrations(comp[y].props.integrations) && hasPolicies(comp[y].props.policies,stateApp) && matchedEnv(stateApp,comp[y].props.envs)
     
        if (!criteriaMet){
    
        dashBoardConfig[i].cards[x].components.splice(y,1);
        }
      } 
    }
  }
  for(var i = 0; i< dashBoardConfig.length; i++){
    let cards = dashBoardConfig[i].cards
  
    for(var x = 0; x < cards.length; x++){
      let comp = cards[x].components || [];
   
      if(comp.length == 0){ dashBoardConfig[i].cards.splice(x,1);
    
    }
 
    }
  } 
}

}, [user_roles]);

  var dconfig = dashBoardConfigIbmi;
  if (stateApp.clouds.includes(Enum.CloudCategory.PUBLIC_CLOUD)) {
    dconfig = dashBoardConfig;
  }

  if (props.width <= 768) {
    return (
      <div id="db-pane">
        <TabbedDashboard>
          <DynamicDashboardComponents config={dconfig}/>
        </TabbedDashboard>
      </div>
    );
  } else {
    return (
      <div id="db-pane">
        <div className="row">
        <DynamicDashboardComponents config={dconfig}/>
        </div>
      </div>
    );
  }
}

const Dashboard = (props) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    // stateAppActions.setPageNavTitle("TRiA HOME");
    window.addEventListener("resize", () => {
      console.log("resized event caught");
      if (window.innerWidth > 0 && window.innerWidth != width) {
        if (waitForIt) {
          clearTimeout(waitForIt);
        }
        waitForIt = setTimeout(() => {
          setWidth(window.innerWidth);
        }, 300);
      }
    });
    if (window.innerWidth > 0) {
      setWidth(window.innerWidth);
    }
  }, []);

  return (
    <>
      <Suspense fallback={<div></div>}>
        <NotificationsWidget />
        <ResponsiveDashboard width={width}/>
      </Suspense>
    </>
  );
};

export default Dashboard;
