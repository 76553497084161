import React, { Fragment } from "react";
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";

// reactstrap components
import {
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";

import moment from 'moment';
import {range} from "ramda";
import {NewTabComponent} from "../../utility/NewTabComponent";


const AllJobsReusable = (props) => {
  const source = axios.CancelToken.source();
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const handleClick =(e, index) =>{
    e.preventDefault();
    setCurrentPage(index);
  }

  const convertSecToMinutes = (value)=>{
    return Math.floor(value / 60) + ":" + (value % 60 ? value % 60 : '00')
  }

  useAsyncEffect(
    async isMounted => {
      try {
        if (!isMounted()) return;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error)
        } else {
          console.log(error)
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
        <div className="table-responsive-sm">
          <table className="table table-hover" id="csJobs">
            <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
              <th>JobType</th>
              <th>Backup Type</th>
              <th>Percent Completed</th>
              <th>Server</th>
              <th>Start Time</th>
              <th>Last Update</th>
              <th>Elapsed Time</th>
              <th>End Time</th>
            </tr>
            </thead>
            <tbody>
              {props.allJobs.slice((currentPage -1) * props.pageSize, currentPage * props.pageSize)
                  .map((job, index) => {
                    // console.log(job)
                    const pathForJob = `/app/view/backup/job/${job.jobSummary.jobId}/${job.jobSummary.status}`
                    return (
                        <tr key={index} className="cursor" role="button" onClick={() => history.push(pathForJob)}>
                          <td>
                            <span>{job.jobSummary.jobId}</span>
                            <NewTabComponent itemId={job.jobSummary.jobId} link={pathForJob}
                            tooltipMessage={"Show Job In New Tab"}
                            alertMessage={''}/>
                          </td>
                          <td>{job.jobSummary.status}</td>
                          <td>{job.jobSummary.appTypeName}</td>
                          <td>{job.jobSummary.backupLevelName}</td>
                          <td>{job.jobSummary.percentComplete}</td>
                          <td>{job.jobSummary.destinationClient.displayName}</td>
                          <td>{job.jobSummary.jobStartTime > 0 ? moment(job.jobSummary.jobStartTime, 'X').format('lll') : "N/A"}</td>
                          <td>{job.jobSummary.lastUpdateTime > 0 ? moment(job.jobSummary.lastUpdateTime, 'X').format('lll'): "N/A"}</td>
                          <td>{job.jobSummary.jobElapsedTime > 0 ? convertSecToMinutes(job.jobSummary.jobElapsedTime) : "N/A"}</td>
                          <td>{job.jobSummary.jobEndTime > 0 ? moment(job.jobSummary.jobEndTime, 'X').format('lll') : "N/A"}</td>
                        </tr>
                    )
                  })}
            </tbody>
          </table>
          <div className="pagination-wrapper mt-3">
            <Pagination aria-label="Resource Spend">
              <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink onClick={e => handleClick(e, currentPage - 1)} previous href="#"/>
              </PaginationItem>
              {range(
                  Math.max(1, Math.min(currentPage - 2, props.pagesCount - 4)),
                  Math.min(props.pagesCount + 1, Math.max(6, currentPage + 3))
                ).map((page, i) =>
                  <PaginationItem active={page === currentPage} key={i}>
                    <PaginationLink onClick={e => handleClick(e, page)} href="#">
                      {page}
                    </PaginationLink>
                  </PaginationItem>
              )}
              <PaginationItem disabled={currentPage >= props.pagesCount - 1}>
                <PaginationLink onClick={e => handleClick(e, currentPage + 1)} next href="#"/>
              </PaginationItem>
            </Pagination>
          </div>
        </div>)
}

export {
  AllJobsReusable
};
