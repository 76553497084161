import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import truncate from 'lodash/truncate';

import { AlertSymbol } from './componnents';
import { useOutsideClick } from '../../hooks/DomHook'; 
import { appState } from '../../AppState';
import { useSocket } from '../../hooks/SocketHook';

const NotificationsFeed = ({ onToggle }) => {
  const wrapperRef = useRef(null);
  const history = useHistory();
  const [stateApp, stateAppActions] = appState();

  useOutsideClick(wrapperRef, onToggle);

  const handleSocketMessage = useCallback((message) => {
    console.log('new message on sidebar feed: ', message);
    if (message.type === 'feed') {
      stateAppActions.setNotificationsList(message.data);
    }
  }, []);

  const { isOpen, send: sendSocketMessage, addMessageHandler, removeMessageHandler } = useSocket();

  const onClickItem = (alert) => {
    history.push({
      pathname: '/app/notifications',
      state: { targetEvent: alert.event_id },
    });
    onToggle();
  }

  useEffect(() => {
    addMessageHandler('feed', handleSocketMessage);

    return () => {
      removeMessageHandler('feed');
    }
  }, [handleSocketMessage, addMessageHandler, removeMessageHandler]);

  useEffect(() => {
    if (isOpen) { // 1 means connection open
      console.log('sending feed message...');
      sendSocketMessage({ action: 'feed' });
    }
  }, [isOpen, sendSocketMessage]);

  return (
    <div className="tria-notifications" ref={wrapperRef}>
      {stateApp.notifications?.list?.map((alert) => (
        <div
          key={alert.event_id}
          className="tria-card border-top border-secondary cursor my-0"
          onClick={() => onClickItem(alert)}
        >
          <div className="tria-card-body d-flex align-items-start">
            <AlertSymbol
              className="mt-4"
              icon={alert.style?.icon}
              category={alert.event_category}
              dense
            />
            <div className="ml-2">
              <p className="encode-sans-12 text-header text-uppercase font-weight-normal mb-1">
                {alert.event_category === 'Billing Event' ? alert.event_category : alert.title}
              </p>
              <p className="roboto-15 text-dark">
                {truncate(alert.description, { length: 100 })}
              </p>
              <p className="helvetica-12 text-header">
                {moment(alert.timestamp).fromNow()}
              </p>
            </div>
          </div>
        </div>
      ))}
      <div className="tria-card border-top border-secondary text-center my-0 py-2">
        <Link to="/app/notifications" className="text-blue" onClick={onToggle}>
          View All Notifications
        </Link>
      </div>
    </div>
  );;
};

NotificationsFeed.propTypes = {
  onToggle: PropTypes.func,
};

export default NotificationsFeed;
