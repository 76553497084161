const CSVUtility = {

    downloadCSV: (filename) => {
        var csv = CSVUtility.exportTableToCSV();
        var downloadLink;
        var csvFile = new Blob([csv], {
            type: "text/csv"
        });
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    },

    exportTableToCSV: () => {
        var csv = [];
        var rows = document.querySelectorAll("table:not(.no-csv) tr");

        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");

            for (var j = 0; j < cols.length; j++)
                row.push("\"" + cols[j].innerText.trim() + "\"");

            csv.push(row.join(","));
        }
        return (csv.join("\n"));
    },

    attachCSV: () => {
        var csv = exportTableToCSV();
        sessionStorage.setItem("costadvisor", csv);
        window.location.replace("#/ticket");
    },

    attachCSVToSession: () => {
        var csv = exportTableToCSV();
        sessionStorage.setItem("costadvisor", csv);
    },
    downloadCSVFromJson: (filename, json) => {
        var csv = CSVUtility.jsonToCsv(json);
        var downloadLink;
        var csvFile = new Blob([csv], {
            type: "text/csv"
        });
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    },
    jsonToCsv: (json) => {
        const items = json
        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        const header = Object.keys(items[0])
        let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')
        return csv;
    },
    downloadCSVFromUrl: (url) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.click();
    },
}
export {
    CSVUtility
};