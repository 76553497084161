import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
// reactstrap components
import {
  Button,
  Form,
  FormGroup,
  UncontrolledTooltip,
  CustomInput,
  Label,
  Input
} from "reactstrap";

import IBMService from "../../services/IBMService";
import Utils from "../../utility/ibm_utility";
import FeatureToggle from "../../utility/FeatureToggle";
import Enum from "../../utility/enum.js";

function showDashboardBlade(props) {
  props.setBladeState({
    visible: true,
    title: "IBM i LPAR Notifications",
    content: IBMiDashboardNotificationBlade
  });
}

const IBMiDashboardNotificationBlade = (
  <>
    <p>Send a notification to:</p>
    <Input type="select" name="selectChannel" id="selectChannel">
      <option>Slack</option>
      <option>E-mail</option>
      <option>SMS</option>
    </Input>
    <p className="mt-3">When LPARs enter the following states:</p>
    <Form>
      <FormGroup>
        <div className="position-relative form-check">
          <label className="form-check-label">
            Warning
            <input type="checkbox" className="form-check-input" />
            <span className="form-check-sign">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="position-relative form-check">
          <label className="form-check-label">
            Error
            <input type="checkbox" className="form-check-input" />
            <span className="form-check-sign">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </FormGroup>
      <Button className="btn pull-right mt-4">Save</Button>
    </Form>
  </>
);

const getStatusIcon = (host, targetDivId) => {
  let tooltip = (
    <UncontrolledTooltip delay={0} target={host.device_id} placement="right">
      {host.status_text}
    </UncontrolledTooltip>
  );
  // let popId = `popover-statusicon-${host.host_id}`;
  switch (host.status_type) {
    case "ok":
      return (
        <i
          id={host.device_id}
          data-toggle="tooltip"
          title={host.status_text}
          className="fas fa-check text-success"
        ></i>
      );
    case "warning":
      return (
        // example ticket context approach
        // <UncontrolledButtonDropdown>
        //   <DropdownToggle caret className="btn-link btn-image-dd">
        <i
          id={host.device_id}
          data-toggle="tooltip"
          title={host.status_text}
          className="fas fa-exclamation-triangle text-warning"
        ></i>
        // </DropdownToggle>
        //   <DropdownMenu>
        //     <DropdownItem>
        //       <NewTicketButton
        //         subject={`LPAR ${host.host_name} (${host.ip_address}) is in a warning state.`}
        //         description={`Current status: ${host.status_text}`}>
        //           Open Ticket
        //       </NewTicketButton>
        //     </DropdownItem>
        //   </DropdownMenu>
        // </UncontrolledButtonDropdown>
      );
    case "error":
      return (
        <i
          id={host.device_id}
          data-toggle="tooltip"
          title={host.status_text}
          className="fas fa-exclamation-circle text-danger"
        ></i>
      );
    case "disabled":
      return (
        <i
          id={host.id}
          data-toggle="tooltip"
          title="LPAR Requires Updates to Display Monitoring"
          className="fas fa-minus"
        ></i>
      );
  }
};

const IBMiLPARMonitorBackSide = props => {
  return (
    <>
      <Form>
        <FormGroup>
          <Label for="enableNotification"></Label>
          <div>
            <CustomInput
              type="checkbox"
              id="switchEnable"
              name="switchEnable"
              label="Enabled"
              checked
            />
          </div>
        </FormGroup>
      </Form>
      <blockquote className="success">
        When an LPAR's status is in a warning or error state an email will be
        sent to "lparsupport@connectria.com".
      </blockquote>
      <Button
        className="btn pull-right"
        onClick={() => showDashboardBlade(props)}
      >
        Configure
      </Button>
    </>
  );
};

const IBMiLparMonitorDesktopWidget = props => {
  return (
    <IBMiLPARMonitorFrontSide></IBMiLPARMonitorFrontSide>
  );
};

const IBMiLPARMonitorFrontSide = props => {
  const [state, setState] = useState({ hosts: [], hostEmpty: [] });
  const history = useHistory();
  const source = axios.CancelToken.source();
  useAsyncEffect(
    async isMounted => {
      try {
        let hosts = await IBMService.hosts({
          cancelToken: source.token
        });
        for (let i = 0; i < hosts.data.length; i++) {
          Utils.getHostStatus(hosts.data[i]);
        }
        if (!isMounted()) return;
        setState(values => ({ ...values, hosts: hosts.data }));
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );
  let totalServices = 0, totalCriticals = 0, totalWarnings = 0;
  let warningsCriticals = false;
  state.hosts.forEach((host) => {
    host.services.forEach((service, i) => {
      totalServices++;
      let name = service.name;
      let status = service.value.split(': ')[1];
      if (name === "Version Information") {
        host.version = status;
      }
      if (status === undefined) {
        status = "no status";
      } else {
        status = String(status);
      }
      if (status) {
        service.status = status;
        if (name === "CPU Utilization") {
          if (Number(status.substring(0, 2)) > 88) {
            service.statusIconClass = 'warning';
            host.warningsErrors = true;
            warningsCriticals = true;
            host.warningCount++;
            totalWarnings++;
          }
          if (Number(status.substring(0, 2)) > 96) {
            service.statusIconClass = 'error';
            host.warningsErrors = true;
            warningsCriticals = true;
            host.warningCount--;
            totalWarnings--;
            host.errorCount++;
            totalCriticals++;
          }
          if (Number(status.substring(0, 3)) === 100) {
            //service.statusRowClass = 'iseries-critical';
            service.statusIconClass = 'error';
            //host.errorCount = host.errorCount + 1;
          }
        }
        if (name === "Disk Utilization") {
          if (Number(status.substring(0, 2)) > 74) {
            //service.statusRowClass = 'iseries-warning';
            service.statusIconClass = 'warning';
            host.warningsErrors = true;
            warningsCriticals = true;
            host.warningCount++;
            totalWarnings++;
          }
          if (Number(status.substring(0, 2)) > 84) {
            //service.statusRowClass = 'iseries-critical';
            service.statusIconClass = 'error';
            host.warningsErrors = true;
            warningsCriticals = true;
            host.warningCount--;
            totalWarnings --;
            host.errorCount++;
            totalCriticals++;
          }
          if (Number(status.substring(0, 3)) === 100) {
            //service.statusRowClass = 'iseries-critical';
            service.statusIconClass = 'error';
            //host.errorCount = host.errorCount + 1;
          }
        }
        if (status === "WARN") {
          //service.statusRowClass = 'iseries-warning';
          service.statusIconClass = 'warning';
          host.warningsErrors = true;
          warningsCriticals = true;
          host.warningCount++;
          totalWarnings++;
        }
        if (status === "CRITICAL") {
          //service.statusRowClass = 'iseries-critical';
          service.statusIconClass = 'error';
          host.warningsErrors = true;
          warningsCriticals = true;
          host.errorCount++;
          totalCriticals++;
        }
      } else {
        host.services.splice(i, 1);
      }
    });
  });
  let counters = '';
  if (state.hosts.length > 5) {
    counters = (
      <div style={{'fontSize': '20px', 'paddingTop': '60px'}}>
        {warningsCriticals &&
          <span>
            <span className="mr-3"><span
              className="fas fa-exclamation-triangle text-danger"></span>&nbsp;{totalCriticals}</span>
            <span className="mx-3"><span className="fas fa-exclamation-circle text-warning"></span>&nbsp;{totalWarnings}</span>
            <span className="ml-3"><span
              className="fas fa-check text-success"></span>&nbsp;{state.hosts.length - totalWarnings - totalCriticals}</span>
          </span>
        }
        {!warningsCriticals &&
          <div className="mt-3"><span className="fas fa-check text-success"></span> ALL {state.hosts.length} LPARS</div>
        }
      </div>
    );
  }
  let content = '';
  if (state.hosts.length === 0) {
    content = (
      <div className="text-center">
        <i
          className="fas fa-spinner m-3 mt-4 fa-spin"
          style={{ fontSize: "30px" }}
        ></i>
      </div>)
  } else {
    content = (
      <div id="ibmi-lpar-monitors-contain">
        <table className={state.hosts.length > 5 ? "d-none" : "table"}>
          <tbody style={{fontSize: (state.hosts.length < 4 ? "20px" : '16px')}}>
            {state.hosts
              .filter(function(item) {
                return item.services.length > 0;
              })
              .map(function(object) {
                return (
                  <tr key={object.device_id}>
                    <td>
                      {Utils.getHostStatus(object) == "disabled" ? (
                        <>{object.host_name}</>
                      ) : (
                        <a href={"/app/ibm/host/" + object.device_id}>{object.host_name}</a>
                      )}
                    </td>
                    <td>{object.ip_address}</td>
                    <td>
                      {/*getStatusIcon(object, "ibmi-lpar-monitors-contain")*/}

                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {counters}
      </div>
    )
  }
  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <div className="d-flex">
          <h3 className="text-left">IBM i LPAR Monitoring</h3>
          {/* <Button
            className="btn btn-link m-0 p-0 card-notification-bell"
            onClick={props.onToggle}
          >
            <span className="tim-icons icon-bell-55 text-success"></span>
          </Button> */}
        </div>

        <FeatureToggle clouds={[Enum.CloudCategory.IBMI]} policies={[Enum.Policies.VIEW_LPAR]} envs={[Enum.Env.DEV, Enum.Env.DEMO]}>{ content }</FeatureToggle>
        {/* THIS NEEDS TO GET REMVED BELOW WHEN THE IBM I COUNTER IS APPROVED FOR PROD */}
        <FeatureToggle policies={[Enum.Policies.VIEW_LPAR]} clouds={[Enum.CloudCategory.IBMI]} envs={[Enum.Env.PROD]}>
          <>
            <div className="d-flex">
            
              {/* <Button
                className="btn btn-link m-0 p-0 card-notification-bell"
                onClick={props.onToggle}
              >
                <span className="tim-icons icon-bell-55 text-success"></span>
              </Button> */}
            </div>

            {state.hosts.length === 0 ? (
              <div className="text-center">
                <i
                  className="fas fa-spinner m-3 mt-4 fa-spin"
                  style={{ fontSize: "30px" }}
                ></i>
              </div>
            ) : (
              <div id="ibmi-lpar-monitors-contain">
                <table className={state.hosts.length == 0 ? "d-none" : "table"}>
                  <tbody>
                    {state.hosts
                      .filter(function(item) {
                        return item.services.length > 0;
                      })
                      .map(function(object) {
                        return (
                          <tr key={object.device_id}>
                            <td>
                              {Utils.getHostStatus(object) == "disabled" ? (
                                <>{object.host_name}</>
                              ) : (
                                <a
                                  href="#"
                                  onClick={() => {
                                    history.push("/app/ibm/host/" + object.device_id);
                                  }}
                                >
                                  {object.host_name}
                                </a>
                              )}
                            </td>
                            <td>{object.ip_address}</td>
                            <td>
                              {/*getStatusIcon(object, "ibmi-lpar-monitors-contain")*/}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        </FeatureToggle>
      </div>

      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push("/app/ibm-dashboard");
        }}
      >
        {" "}
        <span
          style={{ display: "inline", verticalAlign: "middle" }}
          className="fas fa-chart-line"
        ></span>{" "}
        View LPARS
      </button>
    </div>
  );
};

export default IBMiLparMonitorDesktopWidget;

export {
  IBMiLPARMonitorBackSide,
  IBMiLPARMonitorFrontSide,
  IBMiDashboardNotificationBlade
};