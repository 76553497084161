import html2canvas  from 'html2canvas';


//example function call: ScreenshotService.createScreenshot('root')
const ScreenshotService = {

    createScreenshotBase64: async function(divId) {
        var canvas = await html2canvas(document.querySelector("#" + divId));
        var rawDataWithHeader = canvas.toDataURL("image/png");
        //strip header and return
        return rawDataWithHeader.replace(/^data:image\/(png|jpg);base64,/, "");
    }
};

export default ScreenshotService;