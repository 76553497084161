import React from "react";

export default class Utils {

  static getAppended = (service_name, services, appender) => {
    var val = Utils.get(service_name, services);
    if ((val == null) || (val.length === 0)) return "";
    return val + appender;
  }

  static get = (service_name, services, use_status_text_Long = false, isString = false, justValue = false) => {
    if (!services) return "";

    let item = Utils.findItem(service_name, services);
    if (item.length === 0) return "";
    if (item[0].value.indexOf('HostConfig-load()') > -1) return "";

    if (isString && use_status_text_Long && justValue) return (item[0].status_text_long || '');
    return Utils.parseValue(item[0].value, item[0].status_text_long, use_status_text_Long, isString);
  }

  static findItem = (name, arr) => {
    let item = arr.filter(obj => {
      return obj.name === name;
    });

    if (item.constructor.name == "Object") return [item];

    return item;
  }

  static parseValue = (value, status_text_long, use_status_text_Long, isString) => {
    if ((value || "").indexOf("cannot find") > -1) return "";
    if ((status_text_long || "").indexOf("com.ibm") > -1) return "";
    if (use_status_text_Long) value = status_text_long;

    //Steve G changed from a "=" to a ":" no need to replace.
    // value = value.replace("=", ":");

    if (isString) return (((value || ": ").split(':')[1] || '').trim()).split(' ')[0] || '';

    if (value.indexOf(":") > -1 || value.indexOf("=") > -1) {
      let stripped = value.indexOf(":") > -1 ? value.split(":")[1].trim() : value.split("=")[1].trim();
      var floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
      if (stripped.match(floatValues)) {
        let parsed = stripped.match(floatValues)[0];
        return parsed;
      }
      return stripped;
    }
    return ''
  }

  static getColoredSpan = (type, value, formatting) => {
    let status_type = "";
    switch (type) {
      case "CPU Utilization":
        if (value > 88) status_type = "warning";
        if (value > 96) status_type = "error";

        break;
      case "Disk Utilization":
        if (value > 74) status_type = "warning";
        if (value > 84) status_type = "error";

        break;
      case "Jobs in MSGW":
        if (value > 0) status_type = "error";
        break;

      default:
        break;
    }

    return <span /* className={status_type}*/> {value + "" + formatting} </span>;
  }

  static getChart = (name, host, data) => {

    if (data.error) return;

    let timeseries = data.data.row.map(obj => {
      return new Date(parseInt(obj.t) * 1000);
    });

    let dataseries = data.data.row.map(obj => {
      return Number(obj.v).toFixed(2);
    });

    let y = {
      labels: timeseries,
      datasets: [
        {
          label: host.host_name + " " + name,
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataseries
        }
      ]
    };

    return y;
  };
  static getLastDataSetValue = (array, qualifier = '') => {
    if (array.length === 0) return '';
    if (isNaN(array[array.length - 1]) && isNaN(array[array.length - 2])) return '';

    if (isNaN(array[array.length - 1])) return array[array.length - 2] + qualifier;

    return array[array.length - 1] + qualifier;
  }

  static extractIntegers(value) {
    let result = (value || "0").match(/\d+/g).map(Number);

    return result.join('.')
  }

  static getStatusIcon = (type, value) => {
    let icon = '';
    let status_type = '';
    if (value == null) return '';
    value = value.replace('%', '');


    if (isNaN(value)) return '';

    switch (type) {
      case "CPU Utilization":

        if (Number(value) > 88) status_type = "warning";
        if (Number(value) > 96) status_type = "error";
        if (Number(value) === 100) status_type = "error";

        return status_type;

        break;
      case "Disk Utilization":

        if (Number(value) > 74) status_type = "warning";
        if (Number(value) > 84) status_type = "error";

        if (Number(value) === 100) host.status_type = "error";

        return status_type;

        break;
      case "Jobs in MSGW":
        // if (value > 1) status_type = "warning";
        if (value > 0) status_type = "error";
        return status_type;
        break;

      default:
        break;
    }

    return icon;
  };

  static getHostStatus = (host) => {
    //check for disabled monitoring
    var cpu = Utils.get("CPU Utilization", host.services);
    if (cpu != null && cpu === "") {
      host.status_type = "disabled";
      return host.status_type;
    }
    host.status_type = "ok";
    host.status_text = "Ok";
    for (let i = 0; i < host.services.length; ++i) {
      let name = host.services[i].name;
      let status = host.services[i].value.split(": ")[1];
      if (status != undefined) {
        var cleanStatus = Number(status.replace("%", ""));
        switch (name) {
          case "CPU Utilization":
            host.status_text = name + " " + Number(status.substring(0, 3));

            if (cleanStatus > 88)
              host.status_type = "warning";
            if (cleanStatus > 96) {
              host.status_type = "error";
              return host.status_type;
            }
            if (Number(status.substring(0, 3)) === 100) {
              host.status_type = "error";
              return host.status_type;
            }

            break;
          case "Disk Utilization":
            host.status_text = name + " " + Number(status.substring(0, 3));

            if (cleanStatus > 74)
              host.status_type = "warning";
            if (cleanStatus > 84) {
              host.status_type = "error";
              return host.status_type;
            }
            if (Number(status.substring(0, 3)) === 100) {
              host.status_type = "error";
              return host.status_type;
            }

            break;

          case "Jobs in MSGW":
            if (Number(status.substring(0, 1)) > 0) {
              host.status_text = name + " " + Number(status.substring(0, 1));
              host.status_type = "error"
              return host.status_type;
            }
            break;

          default:
            break;
        }
      }
    }
    return host.status_type;
  }
}