import React, {useState} from "react";
import {Tooltip} from "reactstrap";
import {appState} from "../AppState";

const NewTabComponent = (props) => {
    const [stateApp, stateAppActions] = appState();
  const { value, tooltipMessage, alertMessage, idx, className, style } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [text, setText] = useState(tooltipMessage);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
    // When hovering off of tooltip, reset the message
    if (!tooltipOpen) setText(tooltipMessage);
  }
 const openNewTab = (e) =>{
      e.preventDefault();
      window.open(props.link, "_blank")
     e.stopPropagation()
     return false;
 }

  return (
  <span onClick={(e) => openNewTab(e)}>
    <i
      className={"fas clickable fa-external-link-alt cursor pt-1 pl-1" + (className ? className : "")}
      id={"TooltipExample" + (idx+1 ? idx : "")} // Index is optional

      style={{fontSize: "0.9rem", ...style}}
      />
    <Tooltip
      placement="right"
      target={"TooltipExample" + (idx+1 ? idx : "")} // Index is optional
      isOpen={tooltipOpen}
      toggle={toggle}
      style={{backgroundColor: "black", color: "white"}}>
        {text}
    </Tooltip>
    </span>
  )
}
export {
    NewTabComponent
};