import React, {useEffect} from "react";
import routes from "../../routes.js";
import useRouter from "../../hooks/RouterHook.js";
import ClickstreamService from "../../services/ClickstreamService.js";
import { appState } from "../../AppState";
import CookieService from "../../services/CookieService"
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios/index";
import Cognito from "../../utility/CognitoAuth";
const feature_toggle = "FEATURE_TOGGLE_CLICKSTREAM_TRUE";

export async function ClickstreamPush(stateApp, meta, pathName) {
    if (feature_toggle !== "FEATURE_TOGGLE_CLICKSTREAM_TRUE") return;
    //swallow and log errors - non-essential
    try {
        let uid = 0;
        let sid = "";
        let email = "";
        let orgId = "";
        let orgName = "";
        if (stateApp.userInfo != null) {
            uid = stateApp.userInfo.user_id ?? 0;
            orgId = stateApp.userInfo.organization_id ?? "";
            orgName = (stateApp.userInfo.organization_name != null) ? stateApp.userInfo.organization_name.trim() : "";
            email = (stateApp.userInfo.email != null) ? stateApp.userInfo.email.trim() : "";
        }
        let t = await Cognito.getAccessTokenDecoded();

        sid = t.jti || "Empty"
        //default event data
        var event_id = "visited";
        var event_target = pathName ?? "n/a";
        //check for meta overrides
        if ((meta != null) && (meta.event_id != null)) {
            event_id = meta.event_id;
        }
        if ((meta != null) && (meta.event_target != null)) {
            event_target = meta.event_target;
        }
        //add current route to event_data
        var eData = {};
        if ((meta != null) && (meta.event_data != null)) {
            eData = meta.event_data;
        }
        eData["route"] = pathName ?? "n/a";
        await ClickstreamService.push(uid, sid, event_id, eData, event_target, email, orgId, orgName);
    } catch (error) {
        console.log(error);
    }
}

const Clickstream = (props) => {
    const router = useRouter();
    const source = axios.CancelToken.source();
    //app state
    const [stateApp, stateAppActions] = appState();

    useAsyncEffect(
        async isMounted => {
            if (!isMounted()) return;
            await ClickstreamPush(stateApp, props.meta, router.pathname);
        },
        () => {
            source.cancel();
        },
        []
    );

    return (
        <>
            {props.children}
        </>
    );
}

export default Clickstream;