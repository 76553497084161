import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";

const base_api =
  "https://4jsb2f47s8.execute-api.us-east-1.amazonaws.com/dev/api/";

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: base_api,
    headers: { Authorization: idToken },
  });

  return req;
}

const YetiNotifications = {
  notificationlist: async function (start_date, page, pageSize) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(
      base_api +
        `${orgId}/notifications/notificationlist?&date_filter_field=ANY&date_filter_start=${start_date}&page=${page}&pagesize=${pageSize}&sort=PORTAL`
    );
  },
  getNotification: async function (id) {
    const req = await defaultRequest();

    let request = req.get(base_api + `/notifications/notification?id=${id}`);
    return request;
  }
};

export default YetiNotifications;
