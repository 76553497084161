import React, { useEffect } from "react";
import CommonService from "../services/CommonService";
import useAsyncEffect from "../utility/use-async-effect";
const AccessDenied = (props) => {

  return (
    <div className="text-center">
      <article className="construction">
       <p> <img
          style={{ width: 300 + "px" }}
          src="https://s3.amazonaws.com/connectria-branding/Connectria_logo.png"
        /></p>
  <br></br>
        <h2 style={{ fontSize: 35 + "px" }}>You currently do not have access to this page</h2>
        <div>
        <p>
        <a href="/app/dashboard">Click here to Return Home</a>
        </p><br></br>
          <p>
            Please contact your TRiA Organization Administrator for any of
            your questions around access and policies. Thank you
            for your patience.
          </p>
          <p>
            Connectria Command Center{" "}
            <a className="contrsuction-a " href="tel:1-800-781-7178">
              1-800-781-7178
            </a>
          </p><br></br>
          <p>- TRIA Team</p>
        </div>
      </article>
    </div>
  );
};

export default AccessDenied;