import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "../services/CookieService";

const base_api = 'https://dev.tria.connectria.com';

const ibmiApiUrl = 'https://dev.api.connectria.com/ibmi';


const defaultRequest = async () => {
  const req = axios.create({
    baseURL: ibmiApiUrl,
  });
  const idToken = await Cognito.getIdToken();
  req.defaults.headers['Authorization'] = idToken;
  return req
}

const IBMService = {
    
  hosts: async function(params={}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie('OrganizationId')
    let request = req.get(
      ibmiApiUrl + "/iseries",
      { params: { orgId, ...params }}
      );
    return request;
  },
  details: async function(device_id, params={}){
    const req = await defaultRequest();
    const orgId = CookieService.getCookie('OrganizationId')
    let request = req.get(
      ibmiApiUrl + "/iseries/detail/" + device_id,
      { params: { orgId, ...params }}
    );
    return request;
  },
  chart_series: async function(host, service, start, end, params ={}){
    const req = await defaultRequest();
    const orgId = CookieService.getCookie('OrganizationId')
    let request = req.get(
      ibmiApiUrl + `/iseries/export?host_name=${host}&service_description=${service}&start=${start}&end=${end}&orgId=${orgId}`,
      params
    );
    return request
  },
  service_metric_history: async function(device_id, service_name, start_date, days_back = 15,params ={}){
    const req = await defaultRequest();
    const orgId = CookieService.getCookie('OrganizationId')
    let request = req.post(
      ibmiApiUrl + `/clouds/ibm/servicemetric/history`,
      {
        device_id: device_id,
        service_name: service_name,
        start_date: start_date,
        days_back: days_back,
      },
      { params: { orgId, ...params }}
    )
    return request
  },
  setup: async function(params={}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie('OrganizationId')
    let request = req.get(
      ibmiApiUrl + "/setup",
      { params: { orgId, ...params }}
      );
    return request;
  },
    
};

export default IBMService;