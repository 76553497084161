import React from "react";
import {  useState } from "react";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Form,
  FormGroup,
  UncontrolledTooltip,
  CustomInput,
  Label,
  Input
} from "reactstrap";
import Select from 'react-select';
import IBMService from "../../services/IBMService";
import moment from 'moment';


const IBMCartCard = props => {
  const history = useHistory();

  const reports = [
    {
      value: "CART01",
      label: "CART01",
      files: [
        {
          name: "All Item Analysis",
          date: moment().subtract(1, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        },
        {
          name: "All Item Analysis",
          date: moment().subtract(2, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        },
        {
          name: "All Item Analysis",
          date: moment().subtract(3, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        }
      ]
    },
    {
      value: "CCSS01",
      label: "CCSS01",
      files: [
        {
          name: "All Item Analysis",
          date: moment().subtract(1, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        },
        {
          name: "All Item Analysis",
          date: moment().subtract(2, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        },
        {
          name: "All Item Analysis",
          date: moment().subtract(3, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        }
      ]
    },
    {
      value: "CCSS02",
      label: "CCSS02",
      files: [
        {
          name: "All Item Analysis",
          date: moment().subtract(1, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        },
        {
          name: "All Item Analysis",
          date: moment().subtract(2, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        },
        {
          name: "All Item Analysis",
          date: moment().subtract(3, 'days').format("MM/DD/YYYY"),
          path: "https://tria-images.s3.amazonaws.com/ALL+Item+Analysis+-+CART01.xlsx"
        }
      ]
    }
  ];
  const [state, setState] = useState(reports);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleChange = (selectedOption) => {

  console.log(selectedOption);
  console.log(reports);
  // setLoading(true);
  let idx = reports.findIndex(x => x.value == selectedOption.value);
  console.log(idx);
  // setTimeout(function(){ setIndex(idx); setLoading(false);}, 500);
  setIndex(idx);
  }
  const saveFile = file =>{
    console.log(file);
    var link = document.createElement("a"); // Or maybe get it from the current document
link.href =  file;
link.download = "ALL Item Analysis.xlsx";
document.body.appendChild(link); // Or append it whereever you want
  }
  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <div className="d-flex">
          <h3 className="w-100">Compliance Reports</h3>
        </div>
        <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={reports[0]}
            options={reports}
            onChange={handleChange}
            isSearchable={true}
            name="LPARS"
            
          />
          <br/>
        {loading? (
          <div className="text-center">
            <i
              className="fas fa-spinner m-3 mt-4 fa-spin"
              style={{ fontSize: "30px" }}
            ></i>
          </div>
        ) : (
          <div style={{minHeight: 130 + 'px'}}>
        
            <table className={state.length == 0 ? "d-none" : "table table-borderless"}>
              <tbody>
                {state[index].files.map(function(object, idx) {
                  return (
                    <tr key={idx}>
                      <td>{object.date}</td>
                      <td>{object.label}</td>
                      <td>All Item Analysis</td>
                      <td>
                      <a href={object.path} download onClick={() => {saveFile(object.path)}}  role="button" aria-pressed="true">Download</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push("/app/ibm-dashboard");
        }}
      >
        {" "}
        <span
          style={{ display: "inline", verticalAlign: "middle" }}
          className="fas fa-chart-line"
        ></span>{" "}
        View LPARS
      </button>
    </div>
  );
};

export { IBMCartCard };
