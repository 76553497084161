import React, {useEffect} from "react";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {
    Button
  } from "reactstrap";
import { _removeCachedTicketHistory } from "../../services/ConnectriaTicketsService";

var bladeElementId;

function _hideBlade(props) {
    props.setBladeState({visible: false});
    enableBodyScroll(bladeElementId);
    removeCachedData();
}

function removeCachedData() {
    _removeCachedTicketHistory();
}

const Blade = (props) => {

    useEffect(() => {
        bladeElementId = document.querySelector("#blade-panel");
    }, []);

    useEffect(() => {
        if (props.visible) {
            disableBodyScroll(bladeElementId);
        } else {
            enableBodyScroll(bladeElementId);
        }
    });

    return (
        <>
            <div onClick={() => _hideBlade(props)} className={(props.visible) ? "blade-backdrop" : "d-none"}></div>
            <div className={(props.visible) ? "blade-panel blade-panel--is-visible" : "blade-panel"}>    
                <div className="blade-panel-container" id="app-blade">
                    <div className="blade-panel-header d-flex">
                        <h1 className="w-100">{props.title}</h1>
                        <Button className="btn btn-link blade-panel-close-button" onClick={() => _hideBlade(props)}><span className="tim-icons icon-simple-remove"></span></Button>
                    </div>
                    <div className="blade-panel-content">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blade;