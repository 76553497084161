import React, { useRef, useState, useCallback, useEffect, createContext, useContext } from 'react';
import omit from 'ramda/src/omit';

import CookieService from '../services/CookieService';
import { appState } from '../AppState';
import Cognito from '../utility/CognitoAuth';

export const socket_api = 'wss://ton7c5xsmg.execute-api.us-east-1.amazonaws.com/dev';
/**
 * Hook that manage websockets
 */
const socketContext = createContext();

export const useSocket = () => useContext(socketContext);

export function useProvideSocket() {
  const [stateApp] = appState();
  const ws = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [callbacks, setCallbacks] = useState({});

  const onSocketClose = useCallback((e) => {
    // console.log('=== closing socket...', retryCount, ws.current);
    setIsOpen(false);
    if (ws.current && retryCount < 5) {
      // console.log('=== socket closed. reconnecting...', e);
      connect();
      setRetryCount(c => c + 1);
    }
  }, [retryCount]);

  const onSocketMessage = useCallback((res) => {
    try {
      console.log('socket message: ', res, callbacks);
      const msg = JSON.parse(res.data);
      if (msg) {
        for (const source in callbacks) {
          if (typeof callbacks[source] === 'function') {
            callbacks[source](msg)
          }
        }
      }
    } catch (e) {
      console.error('original payload', res.data);
      console.error(e);
    }
  }, [callbacks]);

  const connect = async () => {
    if (!ws.current || ws.current?.readyState !== 1) {
      // console.log('connecting to the websocket... ');
      const token = await Cognito.getIdToken();
      ws.current = new WebSocket(`${socket_api}?org_id=${CookieService.getCookie('OrganizationId')}&token=${token}`);
      ws.current.onopen = (e) => {
        if (ws.current) {
          console.log('=== socket opened ===', e, ws.current.readyState);
          setIsOpen(ws.current.readyState === 1);
          setRetryCount(0);
        }
      };

      ws.current.onclose = onSocketClose;
  
      ws.current.onerror = (e) => {
        console.log('=== Socket connection error. retrying to connect in 5s', e);
        setTimeout(function () {
          // ws.current.close();
        }, 5000);
      };

      ws.current.onmessage = onSocketMessage;
    }
  };

  const disconnect = () => {
    console.log('disconnecting the websocket... ');
    ws.current.close();
    ws.current = null;
  }

  const send = useCallback((data) => {
    if (ws.current && isOpen) {
      ws.current.send(JSON.stringify(data));
    }
  }, [ws, isOpen]);

  const addMessageHandler = useCallback((source, cb) => setCallbacks(cs => ({ ...cs, [source]: cb })), []);

  const removeMessageHandler = useCallback((source) => setCallbacks(cs => omit([source], cs)), []);

  useEffect(() => {
    if (ws.current) {
      ws.current.onmessage = onSocketMessage;
    }
  }, [ws, onSocketMessage]);

  useEffect(() => {
    if (ws.current) {
      ws.current.onclose = onSocketClose;
    }
  }, [ws, onSocketClose]);

  return {
    isOpen,
    connect,
    disconnect,
    send,
    addMessageHandler,
    removeMessageHandler,
  };
};

export const SocketProvider = ({ children }) => {
  const socket = useProvideSocket();
  return <socketContext.Provider value={socket}>{children}</socketContext.Provider>;
};
