import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import useAsyncEffect from '../../utility/use-async-effect';
import axios from 'axios';
import moment from 'moment';

// reactstrap components
import { TabContent, TabPane, Row, Col, ButtonGroup, Button } from 'reactstrap';
import BlueMatadorService from '../../services/BlueMatadorService';
import GenericBrand from '../../utility/GenericBrandText';
const BlueMatadorLineChart = (props) => {
  let options = {
    plugins: {
      labels: {
        render: () => {},
      },
      datalabels: { display: false },
    },
    title: { text: '' },
    responsive: true,
    legend: { display: true },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label = label.split(' ')[0] + ' ' + tooltipItem.value;
          }
          return label;
        },
      },
    },
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, maxTicksLimit: 7 } }],
      xAxes: [
        {
          title: 'time',
          type: 'time',

          gridLines: {
            lineWidth: 2,
          },
          time: {
            unit: 'day',
            unitStepSize: 7,
            displayFormats: {
              millisecond: 'MMM DD',
              second: 'MMM DD',
              minute: 'MMM DD',
              hour: 'MMM DD',
              day: 'MMM DD',
              week: 'MMM DD',
              month: 'MMM DD',
              quarter: 'MMM DD',
              year: 'MMM DD',
            },
            ...(props.labels && props.labels.length > 0 ? { min: moment(props.labels[0]).startOf('day') } : {}),
          },
        },
      ],
    },
  };

  let label = props.type + ': ' + props.activeCount;

  let chartData = {
    labels: props.labels,
    datasets: [
      {
        label: label,
        backgroundColor: props.backgroundColor,
        borderColor: props.borderColor,
        data: props.data,
      },
    ],
  };

  return <Line data={chartData} options={options} height={120} />;
};

const BlueMatadorDashboardCharts = (props) => {
  const [labels, setLabels] = useState([]);
  const [alerts, setAlerts] = useState({ data: [] });
  const [warnings, setWarnings] = useState({ data: [] });
  const [anomalies, setAnomalies] = useState({ data: [] });
  const [start, setStart] = useState(moment().toISOString());
  const [end, setEnd] = useState(moment().toISOString());
  const [activeCounts, setActiveCounts] = useState({});
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [notConfigured, setNotConfigured] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const source = axios.CancelToken.source();
  const history = useHistory();
  useAsyncEffect(
    async (isMounted) => {
      setLoading(true);
      try {
        const end = moment().utc().startOf('day').toISOString().split('.')[0] + 'Z';
        const start = moment().utc().startOf('day').subtract(1, 'months').toISOString().split('.')[0] + 'Z';
        let openedRes = await BlueMatadorService.graphs(
          { start, end },
          {
            cancelToken: source.token,
          }
        );
        let activeRes = await BlueMatadorService.activeEvents(
          { state: 'acknowledged' },
          {
            cancelToken: source.token,
          }
        );

        if (openedRes.data.triaerrormessage) {
          // we got an error. It means integration is broken or doesn't exist.
          setNotConfigured(true);
          setLoading(false);
        }

        let alertData = [];
        let warningData = [];
        let anomalyData = [];
        let sortedDates = Object.keys(openedRes.data.opened.alert).sort(function (a, b) {
          a = Date.parse(a);
          b = Date.parse(b);
          return a < b ? -1 : a > b ? 1 : 0;
        });
        sortedDates.forEach(function (date) {
          alertData.push(openedRes.data.opened.alert[date]);
          warningData.push(openedRes.data.opened.warning[date]);
          anomalyData.push(openedRes.data.opened.anomaly[date]);
        });
        sortedDates = sortedDates.map(Date.parse);
        let activeCountsMap = { alert: 0, warning: 0, anomaly: 0 };
        (Array.isArray(activeRes?.data) ? activeRes?.data : []).map((event) => {
          activeCountsMap[event.severity] += 1;
        });
        setActiveCounts(activeCountsMap);

        if (!isMounted()) return;
        setLabels(sortedDates);
        setAlerts({ data: alertData });
        setWarnings({ data: warningData });
        setAnomalies({ data: anomalyData });
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <div className="d-flex flex-column h-100" style={{ flexDirection: 'column' }}>
      <div className="w-100 mb-2">
        <h3 className="text-left">
          <GenericBrand>Cloud Monitoring</GenericBrand>
        </h3>
      </div>
      <div className="flex-fill">
        {notConfigured && <h4 className="mt-5">Monitoring system isn't configured.</h4>}
        {!notConfigured && (
          <>
            <ButtonGroup className="d-flex" role="group">
              <Button
                onClick={() => toggleTab('1')}
                active={activeTab === '1'}
                className={`btn-sm w-100 ${activeTab !== '1' ? 'btn-simple' : ''}`}
                style={{ maxWidth: '250px', border: 'unset' }}
              >
                Alerts: {activeCounts.alert}
              </Button>
              <Button
                onClick={() => toggleTab('2')}
                active={activeTab === '2'}
                className={`btn-sm w-100 ${activeTab !== '2' ? 'btn-simple' : ''}`}
                style={{ maxWidth: '250px', border: 'unset' }}
              >
                Warnings: {activeCounts.warning}
              </Button>
              <Button
                onClick={() => toggleTab('3')}
                active={activeTab === '3'}
                className={`btn-sm w-100 ${activeTab !== '3' ? 'btn-simple' : ''}`}
                style={{ maxWidth: '250px', border: 'unset' }}
              >
                Anomalies: {activeCounts.anomaly}
              </Button>
            </ButtonGroup>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    {alerts.data.length === 0 ? (
                      <h4 className="mt-3">There are no alerts.</h4>
                    ) : (
                      <BlueMatadorLineChart
                        type={'Alerts'}
                        data={alerts.data}
                        activeCount={activeCounts.alert}
                        labels={labels}
                        backgroundColor={'rgba(235, 64, 52, 0.4)'}
                        borderColor={'rgba(235, 64, 52, 1)'}
                      ></BlueMatadorLineChart>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    {warnings.data.length === 0 ? (
                      <h4 className="mt-3">There are no warnings</h4>
                    ) : (
                      <BlueMatadorLineChart
                        type={'Warnings'}
                        data={warnings.data}
                        activeCount={activeCounts.warning}
                        labels={labels}
                        backgroundColor={'rgba(255, 236, 0, 0.4)'}
                        borderColor={'rgba(255, 236, 0, 1)'}
                      ></BlueMatadorLineChart>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    {anomalies.data.length === 0 ? (
                      <h4 className="mt-3">There are no anomalies</h4>
                    ) : (
                      <BlueMatadorLineChart
                        type={'Anomalies'}
                        data={anomalies.data}
                        activeCount={activeCounts.anomaly}
                        labels={labels}
                        backgroundColor={'rgba(145, 240, 244, 0.4)'}
                        borderColor={'rgba(145, 240, 244, 1)'}
                      ></BlueMatadorLineChart>
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </>
        )}
      </div>
      {loading ? (
        <div className="text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: '30px' }}></i>
        </div>
      ) : (
        <div id="bluematador-charts-contain">
          <table className={alerts.data.length == 0 ? 'd-none' : 'table'}>
            <tbody></tbody>
          </table>
        </div>
      )}
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push('/app/cloud/monitoring');
        }}
      >
        {' '}
        <span style={{ display: 'inline', verticalAlign: 'middle' }} className="fas fa-chart-line"></span> View Detail
      </button>
    </div>
  );
};

const BlueMatadorWidget = (props) => {
  return <BlueMatadorDashboardCharts></BlueMatadorDashboardCharts>;
};

export default BlueMatadorWidget;

export { BlueMatadorDashboardCharts, BlueMatadorLineChart };
