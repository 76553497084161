import React, {useEffect, useState} from "react";
import { appState } from "../../AppState";
import { NewTicketForm } from "../ConnectriaTickets/ConnectriaTicketsSupportDashboard";
import { useHistory } from "react-router-dom";

const NewTicketButton = (props) => {
    //global state
    const [stateApp, stateAppActions] = appState();
    const history = useHistory();

    const handleBtnClick = (e) => {
        if (e != null) {
            e.preventDefault();
        }
        if (props.onBladeShow) {
            props.onBladeShow();
        }
        stateAppActions.setBlade({
            title: "Create New Ticket",
            visible: true,
            content: <NewTicketForm closeOnSuccess={true} subject={props.subject} description={props.description} attachmentElementId={props.attachmentElementId} deviceId={props.deviceId}></NewTicketForm>
        });
    }

    const goToFullPageView = (e) => {
        history.push({
            pathname: "/app/create-ticket",
            state: {
                closeOnSuccess: true,
                subject: props.subject,
                description: props.description,
                attachmentElementId: props.attachmentElementId,
                deviceId: props.deviceId}
        });
    }

    return (
        <>
            <a onClick={(e) => goToFullPageView(e)} className={`new-ticket-button ${props.className}`} style={props.style}>
                {props.children}
            </a>
        </>
    );
}

export default NewTicketButton;