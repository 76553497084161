import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';

export const TriaCard = props => {
  return (
    <div id={props.id || ""} className={"tria-card " + (props.className || "")} style={{...props.style || null}} onClick={props.onClick || null}>
      <div className={"tria-card-header " + (props.headerClassName || "")} style={{...props.headerStyle || null}}>
        <div className={"tria-card-title d-flex flex-wrap " + (props.titleClassName || "")} style={{...props.titleStyle || null}}>
          {props.title || ""}
          {props.sideButton ||
            <i className="fal fa-ellipsis-h align-self-start my-auto ml-auto cursor tria-card-ellipsis"/>
          }
        </div>
      </div>
      <div className={"tria-card-body " + (props.bodyClassName || "")} style={{...props.bodyStyle || null}}>
        {props.children}
      </div>
      {props.footer &&
        <div className={"tria-card-footer " + (props.footerClassName || "")} style={{...props.footerStyle || null}}>
          {props.footer}
        </div>}
    </div>
  );
};

export const CardFieldValue = ({
  field, 
  fieldClassName, 
  value, 
  valueClassName, 
  valueStyle, 
  defaultValue, 
  id, 
  tooltip,
}) => {

  const sanitizeValue = (value) => {
    if (value === 'n/a') {
      return defaultValue || '';
    }

    if (typeof value === 'string') {
      return value.replace(/\\n/g, '\n');
    }

    if (Array.isArray(value) && value.length > 0 && (typeof value[0] === 'string' || typeof value[0] === 'number')) {
      return value.join(', ');
    }

    return value;
  };

  return (
    <div className="card-field-value d-flex mb-2">
      <div className="col-md-4 col-lg-5 p-0">
        <div id={id} className={classNames('field-label', fieldClassName)}>
          {field}
        </div>
      </div>

      {(id && tooltip) && 
        <UncontrolledTooltip placement="right" target={id}>{tooltip || value || ''}</UncontrolledTooltip>
      }

      <div className="col-md-8 col-lg-7 p-0">
        <div 
          className={classNames('field-value', valueClassName)}
          style={valueStyle || {}}
        >
          {sanitizeValue(value)}
        </div>
      </div>
    </div>
  );
};

CardFieldValue.propTypes = {
  field: PropTypes.node,
  fieldClassName: PropTypes.string,
  value: PropTypes.node,
  valueClassName: PropTypes.string,
  defaultValue: PropTypes.node,
  id: PropTypes.string,
  tooltip: PropTypes.string,
};
