import axios from 'axios';

import CookieService from '../services/CookieService';
import Cognito from '../utility/CognitoAuth';

const base_api = 'https://dev.tria.connectria.com';
const swapi_url = 'https://dev.api.connectria.com/swapi';

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
      baseURL: swapi_url,
      headers: { Authorization: idToken },

  });
  //req.defaults.headers['Authorization'] = await Cognito.getIdToken();
  return req
}

const SolarwindService = {

    forecast: async function(params ={}) {
      const req = await defaultRequest();
      const orgId = CookieService.getCookie('OrganizationId')
      const res = req.get(`/org/${orgId}/forecast`,
        params
        );
      return res;
    },
    get_device_metrics: async function(params, options = {}) {
      try {
        const req = await defaultRequest();
        const orgId = CookieService.getCookie('OrganizationId')
        const res = await req.get(`/org/${orgId}/devices`, { params, ...options });

        return res.data?.nodes;
      } catch (error) {
          console.log(error);
          throw (error);
      }
    },
    device_detail: async function(deviceId, params = {}) {
      try {
        const req = await defaultRequest();
        const orgId = CookieService.getCookie('OrganizationId')
        const res = await req.get(`/org/${orgId}/device/${deviceId}`, params);

        return res;
      } catch (error) {
          console.log(error);
          throw (error);
      }
    },
    get_device_maintenance_window: async function(deviceId, params ={}) {
      try {
        const req = await defaultRequest();
        const orgId = CookieService.getCookie('OrganizationId')
        const res = await req.get(`/org/${orgId}/device/${deviceId}/mm`, params);

        return res;
      } catch (error) {
          console.log(error);
          throw (error);
      }
    },
    get_device_volume_info: async function(deviceId, params ={}) {
      try {
        const req = await defaultRequest();
        const orgId = CookieService.getCookie('OrganizationId')
        const res = await req.get(`/org/${orgId}/device/${deviceId}/volume`, params);

        return res;
      } catch (error) {
          console.log(error);
          throw (error);
      }
    },
    create_device_maintenance_window: async function(deviceId, payload, params ={}) {
      try {
        const req = await defaultRequest();
        const orgId = CookieService.getCookie('OrganizationId')
        const res = await req.post(`/org/${orgId}/device/${deviceId}/mm`, payload, params);

        return res;
      } catch (error) {
          console.log(error);
          throw (error);
      }
    },
    delete_device_maintenance_window: async function(deviceId, params ={}) {
      try {
        const req = await defaultRequest();
        const orgId = CookieService.getCookie('OrganizationId')
        const res = await req.delete(`/org/${orgId}/device/${deviceId}/mm`, params);

        return res;
      } catch (error) {
          console.log(error);
          throw (error);
      }
    },
};

export default SolarwindService;