import React from 'react';
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";

// Import other ticket provider services as needed
// import { OtherProviderService } from './OtherProviderService';

const getTicketPriority = (provider, priority) => {
  
  switch (provider) {
    case 'connectria':
      return ConnectriaTicketsService.ticketPriority(priority)?.name;
    // Add cases for other providers
    // case 'otherProvider':
    //   return OtherProviderService.getPriority(priority);
    case 'lightedge':
        return priority;
    default:
      return 'Unknown';
  }
};

const MultiProviderTicketPriority = ({ provider, priority }) => {
  console.log(provider)
  const priorityName = getTicketPriority(provider, priority);
  
  return <span>{priorityName}</span>;
};

export default MultiProviderTicketPriority;