import React from "react";
import { appState } from "../AppState";

const GenericBrand = props => {
    return props.children;
    //not needed for now... post-AWS demo
    // const [stateApp, stateAppActions] = appState();
    // let text = props.children;

    // if(stateApp.env.toLowerCase() != 'demo') return text;

    // switch (text){
    //     case 'Blue Matador Cloud Monitoring':
    //         return 'Cloud Monitoring';
    //     case 'CloudCheckr':
    //     return 'TRiA';

    //     default: return text;

    // }
}

export default GenericBrand