/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState, useRef, useMemo } from "react";
import { NavLink, Link, Redirect, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import debounce from 'lodash.debounce';
import { sort, ascend, prop } from 'ramda';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import { Nav, Card, CardHeader, CardBody, Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";

import LogoutNavLink from "../Logout/LogoutNavLink";
import FeatureToggle from "../../utility/FeatureToggle";

import Enum from "../../utility/enum";
import OrganizationService from "../../services/OrganizationService";
import CookieService from "../../services/CookieService";
import ContactDashboard from "../../components/Contact/ContactDashboard";
import { ClickstreamPush } from "../Clickstream/Clickstream";
import { appState } from "../../AppState";

var ps;

const RouteLink = (routeProps) => {
  if (routeProps.redirect || routeProps.suppress) return null;
  return (
    <FeatureToggle policies={routeProps.policy || []} envs={routeProps.envs} clouds={routeProps.clouds} integrations={routeProps.integrations}>
        {routeProps.isExternal ?
          <a className="nav-link" 
             href={routeProps.url}
             onClick={async() => {
                if (routeProps.clickstreamMeta) {
                  var clickedMeta = {
                    "event_id": "clicked",
                    "event_target": routeProps.clickstreamMeta.event_target,
                    "event_data": routeProps.clickstreamMeta.event_data
                  }
                  clickedMeta.event_data["source"] = "sidebar";
                  await ClickstreamPush(routeProps.stateApp, clickedMeta);
                }
             }} 
             target="_blank">
            <i className={routeProps.icon} />{routeProps.name}
          </a>
        :
          <NavLink
            to={routeProps.layout + routeProps.path}
            className="nav-link"
            activeClassName="active"
            onClick={() => {
              // Reload the iframe so it goes back to the original legacy page
              if (routeProps.iframe) {
                var iframe = document.getElementById('legacyFrame');
                if (iframe) iframe.src = iframe.src;
              }
              routeProps.toggleSidebar()
            }}
          >
            <i className={routeProps.icon} />{routeProps.name}
          </NavLink>
        }
    </FeatureToggle>
  );
}

const RouteGroup = (routeProps) => {
  return (
    <FeatureToggle   policies={routeProps.policy || []} envs={routeProps.envs} clouds={routeProps.clouds}>
        <Card style={{marginBottom:0}} key={routeProps.group}>
          <CardHeader onClick={(e) =>  { if (routeProps.visibleGroup === routeProps.group) { routeProps.setVisibleGroup(null); } else { routeProps.setVisibleGroup(routeProps.group)} }} 
            className="nav-group-card-header"
            data-event={routeProps.group} 
            data-type="collapseBanner">
               <div className="group-header-title"><i style={{marginRight:4, marginLeft:3}} className={(routeProps.visibleGroup === routeProps.group) ? "fas fa-caret-up" : "fas fa-caret-down"}></i> {routeProps.group}</div>
          </CardHeader>
          <Collapse isOpen={routeProps.visibleGroup === routeProps.group}>
            <CardBody>
              <ul style={{listStyle:"none", padding:0}} className="nav-group-ul">
                {routeProps.routes.map((r, k) => (
                  <li key={k}><RouteLink {...r} activeRoute={routeProps.activeRoute} toggleSidebar={routeProps.toggleSidebar} stateApp={routeProps.stateApp}></RouteLink></li>
                ))} 
              </ul>          
            </CardBody>
          </Collapse>
        </Card>
      </FeatureToggle>
    );
}

const Sidebar = (props) => {
  const [visibleGroup, setVisibleGroup] = useState();
  const [orgDropdownVisible, setOrgDropdownVisible] = useState();
  const [orgSearchKeyword, setOrgSearchKeyword] = useState('');
  const [stateApp, stateAppActions] = appState();
  const refSidebar = useRef(null);
  const history = useHistory();
  const filteredOrgs = useMemo(() =>
    (orgSearchKeyword ? stateApp.organizations.filter(
      org => (org.name || '').toLowerCase().includes(orgSearchKeyword.toLowerCase())
    ) : stateApp.organizations),
    [orgSearchKeyword, stateApp.organizations]
  );
  //console.log(stateApp.userInfo);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  const toggleOrgDropdown = () => setOrgDropdownVisible(isOpen => !isOpen)

  const onChangeKeyword = debounce(setOrgSearchKeyword, 500);

  const switchOrg = async (org) => {
    await OrganizationService.switch_org({ payload: { organization_name: org } });
    CookieService.eraseCookie("show_skylift", "/");
    props.toggleSidebar();
    stateAppActions.setReload();
    history.push('/');
    stateAppActions.setCache({key: "costSavings", data: [], loading: true});
  }

  const externalToolsRouteGroup = {
    group: "External Tools",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    routes: [
      {
        url: "https://www.mh.connectria.com",
        name: "Portal",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        icon: "fas fa-external-link-alt",
        isExternal: true,
        clickstreamMeta: {
          "event_id": "clicked",
          "event_target": "portal",
          "event_data": {
            "category": "tria",
            "source": "sidebar"
          }
        }
      },
      ...(stateApp.portalCustomerMeta.tria_show_skylift_link === "TRUE" ? [{
        url: "https://apps.connectria.com",
        name: "Sky-Lift",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        icon: "fas fa-external-link-alt",
        isExternal: true,
        clickstreamMeta: {
          "event_id": "clicked",
          "event_target": "skylift",
          "event_data": {
            "category": "tria",
            "source": "sidebar"
          }
        }
      }] : []),
    ],
  };

  const contactUsRoute = {
    path: "/contact",
    name: "Contact Us",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fas fa-address-book",
    component: ContactDashboard,
    layout: "/app",
    clickstreamMeta: {
      "event_id": "visited",
      "event_target": "contact-us",
      "event_data": {
        "category": "tria"
      }
    }
  };

  const legacyRoute = {
    url: "https://dev.tria.connectria.com",
    name: "TRiA Legacy",
    envs: [Enum.Env.DEV],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fas fa-external-link-alt",
    isExternal: true,
    clickstreamMeta: {
      "event_id": "clicked",
      "event_target": "tria-legacy",
      "event_data": {
        "category": "tria",
        "source": "sidebar"
      }
    }
  };

  let allRoutes = [...props.routes, externalToolsRouteGroup, contactUsRoute, legacyRoute];
  const groupRoutes = allRoutes.filter(r => !!r.group);
  const linkRoutes = allRoutes.slice(1).filter(r => !r.group); // leave home at the top
  const sortedRoutes = [
    allRoutes[0],
    ...sort(ascend(prop('group')), groupRoutes),
    ...sort(ascend(prop('name')), linkRoutes),
  ]

  return (
      <div className="sidebar" data={"primary"}>
        <div className="sidebar-wrapper" ref={refSidebar}>
            <>
              {(stateApp.userInfo && (stateApp.userInfo.domain_admin || stateApp.userInfo.domain_viewer)) ?
                <Dropdown className="org-dropdown" isOpen={orgDropdownVisible} toggle={toggleOrgDropdown}>
                  <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={orgDropdownVisible}>
                    <div className="d-flex align-items-center px-3 py-2 text-white">
                      <i className="fas fa-user fa-2x mr-2" />
                      <div>
                        <p className="text-truncate mb-0">{stateApp.userInfo.name}</p>
                        <div className="row" style={{paddingLeft: "6px"}}>
                          <p className="text-truncate small">{stateApp.userInfo.organization_name}</p>
                          <i className={"pl-1 fas fa-caret-" + (orgDropdownVisible ? 'up' : 'down')}></i>
                        </div>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>Switch Organization</DropdownItem>
                    <DropdownItem disabled>
                      <Input
                        placeholder="Search Organization..."
                        onChange={e => onChangeKeyword(e.target.value)}
                      />
                    </DropdownItem>
                    <DropdownItem divider />
                    {filteredOrgs.map(org => (
                      <DropdownItem
                        key={org.organization_id}
                        onClick={stateApp.userInfo.organization_id !== org.organization_id ? () => switchOrg(org.name) : () => {}}
                      >
                        {stateApp.userInfo.organization_id === org.organization_id ? <strong>{org.name}</strong> : org.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                :
                <>   <div className="d-flex align-items-center px-3 py-2 text-white">
                <i className="fas fa-user fa-2x mr-2" />
                <div>
                  <p className="text-truncate mb-0">{(stateApp.userInfo || {}).name || ''}</p>
                  <p className="text-truncate small">{(stateApp.userInfo || {}).organization_name || ''}</p>
                </div>
              </div></>
              }
              {sortedRoutes.map((r, key) => {
                return (
                  <React.Fragment key={key}>
                    {r.group ? (
                      <RouteGroup activeRoute={activeRoute} {...r} toggleSidebar={props.toggleSidebar} visibleGroup={visibleGroup} setVisibleGroup={setVisibleGroup} stateApp={stateApp}></RouteGroup>
                    ) : (
                      <RouteLink activeRoute={activeRoute} {...r} toggleSidebar={props.toggleSidebar} stateApp={stateApp}></RouteLink>
                    )}
                  </React.Fragment>
                );
              })}
              <LogoutNavLink toggleSidebar={props.toggleSidebar}></LogoutNavLink>
            </>

      </div>
    </div>
  );
}

export default Sidebar;
