import { useState, useEffect } from "react";
import React from "react";
const { detect } = require("detect-browser");
import { appState } from "../AppState";
import axios from "axios/index";
import { useAsyncEffect } from "./use-async-effect";
import ConnectriaTicketsService from "../services/ConnectriaTicketsService";
const getStyle = (item) => {
  switch (item) {
    case "INFO":
      return "alert-info";
    case "NOTICE":
      return "alert-notice";
    case "WARNING":
      return "alert-warning";
    case "OUTAGE":
      return "alert-outage";
    default:
      break;
  }
};
const ConnectriaAnnoucement = (props) => {
  const [stateApp, stateAppActions] = appState();
  const [annoucments, setAnnoucments] = useState([]);
  const source = axios.CancelToken.source();

  useAsyncEffect(
    async (isMounted) => {
      try {
       
        if(stateApp.hasPortalAccess){
        let annoucments = await ConnectriaTicketsService.getAnnoucments({
          cancelToken: source.token,
        });
      
        if (!isMounted()) return;

        setAnnoucments(annoucments.data);
      }
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );
  return (
    <>
    
      {(annoucments || []).length > 0 ? (
        (annoucments || []).map(function (item, i) {
          
          return (
            <div
              className={"alert-dismissible fade show alert " + getStyle(item.title)}
              style={{
                padding: 0,
                paddingBottom: 10 + "px",
                paddingTop: 10 + "px",
                marginBottom: 0,
              }}
              role="alert"
            >
              <p
                className="h4 text-center"
                style={{ color: "white", marginBottom: 0 }}
              >
                <strong style={{paddingRight: 3 + 'px'}}>{item.title}:  </strong>
                {"  "}
                {item.announcement}
              </p>
         
            </div>
            
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
export default ConnectriaAnnoucement;
