import Axios from 'axios';

const sentiment_url = 'https://qyjup47f0d.execute-api.us-east-1.amazonaws.com/dev/';

//example function call: ScreenshotService.createScreenshot('root')
//TODO: Lock down sentiment urls
const SentimentService = {

    getSentiment: async function(input) {
       let request = Axios.post(sentiment_url, {input: input})

       return request;
    }
};

export default SentimentService;