import "../../assets/css/login-box.css";
import React from "react";
import CookieService from "../../services/CookieService"
import {useHistory} from "react-router-dom";
import {appState} from "../../AppState";
import UserService from "../../services/UserService"
import {
  NavLink
} from "reactstrap";
import {ClickstreamPush} from "../Clickstream/Clickstream";
import Cognito from "../../utility/CognitoAuth";
import { ACCOUNTS_URL } from "../../config";

const LogoutNavLink = (props) => {
  const [stateApp, stateAppActions] = appState();

  const logout = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      await ClickstreamPush(stateApp, {
        "event_id": "clicked",
        "event_target": "logout",
        "event_data": {
          "category": "tria"
        }
      });
      await Cognito.logout();
      await UserService.logout();
      props.toggleSidebar();
    } catch (error) {
      console.log(error);
    }

    let cookieDomain = CookieService.getCookieDomain(stateApp.env);
    CookieService.eraseCookie("session_id", "/", cookieDomain);
    CookieService.eraseCookie("session_timeout_time", "/", cookieDomain);
    CookieService.eraseCookie("show_skylift", "/", cookieDomain);

    stateAppActions.setLogout();
    window.location.href = ACCOUNTS_URL
  }

  return (
    <>
      <NavLink onClick={logout} style={{cursor: "pointer"}} href={"#"}>
        <i className="fas fa-sign-out-alt" /> Logout
      </NavLink>
    </>
  );
};

export default LogoutNavLink;
