import React from 'react';
import { Badge } from "reactstrap";

import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";

const getTicketPriority = (provider, priority) => {
  switch (provider) {
    case 'connectria':
      return ConnectriaTicketsService.ticketPriority(priority);
    case 'lightedge':
      return { name: priority, level: priority.toLowerCase() };
    default:
      return { name: priority, level: 'low' };
  }
};

const getBadgeColor = (priorityLevel) => {
  switch (priorityLevel) {
    case 'emergency':
    case 'critical':
    case 'high':
      return 'destructive';
    case 'medium':
      return 'warning';
    case 'low':
      return 'secondary';
    default:
      return 'secondary';
  }
};

const MultiProviderPriorityBadge = ({ provider, priority }) => {
  const item = getTicketPriority(provider, priority);
  const badgeColor = getBadgeColor(item.level.toLowerCase());

  return <Badge variant={badgeColor}>{item.name}</Badge>;
};

export default MultiProviderPriorityBadge;