import moment from 'moment';

export function safeJsonParse(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null; // or return a default value like {} or []
  }
}

export const downloadBlob = (data, filename, mimeType = 'application/octet-stream') => {
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  
  // Append to the document body
  document.body.appendChild(link);
  
  // Dispatch click event
  link.dispatchEvent(new MouseEvent('click'));
  
  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};


export const getMetrics = (tickets, daysToInclude) => {
  const currentDate = moment();

  // Filter tickets by the days to include
  const filteredTickets = tickets.filter((ticket) => {
    const ticketDate = moment(ticket.created);
    return currentDate.diff(ticketDate, 'days') <= daysToInclude;
  });

  const priorityCounts = {
    Emergency: 0,
    High: 0,
    Normal: 0,
    Low: 0,
  };

  // Map through tickets and count the occurrences
  filteredTickets.forEach((ticket) => {
    switch (ticket.priority.toUpperCase()) {
      case 'EMERGENCY':
        priorityCounts.Emergency++;
        break;
      case 'HIGH':
        priorityCounts.High++;
        break;
      case 'NORMAL':
        priorityCounts.Normal++;
        break;
      case 'LOW':
        priorityCounts.Low++;
        break;
      default:
        break;
    }
  });

  // match data format for the chart
  const result = {
    labels: ['Emergency', 'High', 'Normal', 'Low'],
    datasets: [
      {
        data: [
          priorityCounts.Emergency,
          priorityCounts.High,
          priorityCounts.Normal,
          priorityCounts.Low,
        ],
        backgroundColor: ['#dc3545', '#ffc107', '#777777', '#adb5bd'],
        hoverBackgroundColor: ['#dc3545', '#ffc107', '#777777', '#adb5bd'],
      },
    ],
  };

  return result;
};