import React from 'react';
// used for making the prop types of this component
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Table} from 'reactstrap';
import {Button, ButtonGroup, Card, CardHeader, CardBody, CardTitle, CardFooter} from 'reactstrap';
import {
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import SolarwindService from '../../services/Solarwind';
import {range} from '../../utility/misc';
import axios from 'axios/index';
import useAsyncEffect from '../../utility/use-async-effect';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {appState} from '../../AppState';
import moment from 'moment';
import {NewTicketForm} from "../ConnectriaTickets/ConnectriaTicketsSupportDashboard";

const renderTableHeader = function () {
  let columns = ['VM', 'Warning Date', 'Critical Date', ' At Capacity', "Create Ticket"];
  return columns.map((column, index) => {
    return (
      <th key={index} id={index}>
        {column}
      </th>
    );
  });
};

const hasNegativeTrend = (metric) => {
  const {DaysToWarningAvg, DaysToCriticalAvg, DaysToCapacityAvg} = metric;
  if (DaysToWarningAvg == null || isNaN(DaysToWarningAvg) ||
    DaysToCriticalAvg == null || isNaN(DaysToCriticalAvg) ||
    DaysToCapacityAvg == null || isNaN(DaysToCapacityAvg)) {

    return true
  }
  return false;
}

const getMetricLevel = (metric) => {
  var result = "";
  const {DaysToWarningAvg, DaysToCriticalAvg, DaysToCapacityAvg} = metric;
  if (hasNegativeTrend(metric)) {
    return result;
  }
  //capacity
  if (0 < DaysToCapacityAvg && DaysToCapacityAvg < 180) {
    return "capacity";
  }
  //critical
  if (0 < DaysToCriticalAvg && DaysToCriticalAvg < 180) {
    return "critical";
  }
  //warning
  if (0 < DaysToWarningAvg && DaysToWarningAvg < 180) {
    //console.log('warning metric: ', metric);
    return "warning";
  }
  return result;
}

const addDays = function (d) {
  if (d <= 0) {
    return 'Now';
  } else if (d == 1) {
    return "In 1 day";
  } else if (d > 365) {
    return 'More than a year out';
  }

  var months = Math.floor(d / 30);
  if (months > 1) {
    return `In ${months} months`;
  }
  var weeks = Math.floor(d / 7);
  if (weeks > 1) {
    return `In ${weeks} weeks`;
  }

  return `In ${d} days`;
};

const rollupTotals = (forecastData) => {
  let result = {
    "cpu": {
      ok: 0,
      warning: 0,
      critical: 0,
      capacity: 0
    },
    "mem": {
      ok: 0,
      warning: 0,
      critical: 0,
      capacity: 0
    }
  };
  for (let i = 0; i < forecastData.length; i++) {
    for (let m = 0; m < forecastData[i].forecast.results.length; m++) {
      let metric = forecastData[i].forecast.results[m];
      let isCPU = metric.MetricName === "Forecast.Metric.CpuLoad";
      let level = getMetricLevel(metric);
      if (level === "") level = "ok";
      if (isCPU) {
        result.cpu[level]++;
      } else {
        result.mem[level]++;
      }
    }
  }
  return result;
}

const getRowStatusIcon = (metric, index) => {
  var icon, iconColor;
  var tooltipMessage = "";
  if (metric.statusType == "ok") {
    return <i className="fas fa-check text-success"></i>
  } else if (metric.statusType == "warning") {
    return <i className="fas fa-exclamation-triangle text-warning"></i>
  } else if (metric.statusType == "critical") {
    return <i className="fas fa-exclamation-circle text-warning"></i>
  } else {
    return <i className="fas fa-exclamation-circle text-danger"></i>
  }
}

const getDashboardIcon = (type, number) => {
  if (number == 0) {
    return <i className="fas fa-check text-success" style={{paddingLeft: "2px"}}></i>
  }
  if (type == "warning") {
    return <i className="fas fa-exclamation-triangle text-warning" style={{paddingLeft: "2px"}}></i>
  } else if (type == "critical") {
    return <i className="fas fa-exclamation-circle  text-warning" style={{paddingLeft: "2px"}}></i>
  } else {
    return <i className="fas fa-exclamation-circle  text-danger" style={{paddingLeft: "2px"}}></i>
  }
}

const getPushedState = (metric, type, number) => {
  var state = {};
  var statusType;
  if (number == 0) {
    statusType = "ok"
  } else {
    statusType = type;
  }
  state = {vmCapacityFilter: statusType, vmMetricType: metric};
  return state;
}

const getTicketDescriptionByStatus = (result) => {
  if (result.statusType == "ok") {
    return "";
  } else {
    return `TRiA shows that we may have capacity planning needs for VM ${result.DisplayName}. What are our options?`;
  }
}

const SolarwindForecast = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({devices: []});
  const [filteredDevices, setFilteredDevices] = useState({devices: []});
  const [items, setItems] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationRange, setPaginationRange] = useState([]);
  const [statusTypeNums, setStatusTypeNums] = useState({"ok": 0, "warning": 0, "critical": 0, "capacity": 0});
  const [statusType, setStatusType] = useState("All");
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState('');
  const [sortByDesc, setSortByDesc] = useState(false);
  const history = useHistory();
  const source = axios.CancelToken.source();
  const [rollups, setRollups] = useState({
    "cpu": {
      ok: 0,
      warning: 0,
      critical: 0,
      capacity: 0
    },
    "mem": {
      ok: 0,
      warning: 0,
      critical: 0,
      capacity: 0
    }
  });

  const offset = 50;

  useAsyncEffect(
    async isMounted => {
      try {
        let forecast = await SolarwindService.forecast({cancelToken: source.token});
        if (!isMounted()) return;
        var forecastWithStatusTypes = markMetricStatusTypes(forecast.data);
        setState(values => ({...values, devices: forecastWithStatusTypes}));
        setFilteredDevices(values => ({...values, devices: forecastWithStatusTypes}));
        setRollups(rollupTotals(forecast.data));

        if (history.location.state != null) {
          setStatusType(history.location.state.vmCapacityFilter ? history.location.state.vmCapacityFilter : 'All');
        } else {
          setStatusType('All');
        }
        // reset the pushed state to be blank
        history.replace(history.location.pathname, {})
        setPagination(forecastWithStatusTypes)

        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    [],
  );

  useEffect(() => {
    if (props.metric === "CpuLoad") {
      setStatusTypeNums(rollups.cpu);
    } else {
      setStatusTypeNums(rollups.mem);
    }
  }, [rollups]);

  useEffect(() => {
    let items = [];

    for (let device of filteredDevices.devices) {
      device.forecast.results.map((result, idx) => {
        if (result.MetricName === 'Forecast.Metric.' + props.metric) {
          result.deviceID = device.device_id;
          items.push(result);
        }
      });
    }
    setItems(items);
  }, [filteredDevices]);

  useEffect(() => {
    var devices = state.devices;
    var metrics = state.devices.map((device) => {
      return device.forecast.results[device.forecast.results.findIndex(r => (props.metric == "CpuLoad") ? r.MetricId === 1 : r.MetricId === 2)]
    });
    if (search != "") {
      devices = devices.filter((device, idx) => {
        return metrics[idx].DisplayName.toLowerCase().includes(search.toLowerCase());
      });
    }
    if (props.metric === "CpuLoad") {
      setStatusTypeNums(rollups.cpu);
    } else {
      setStatusTypeNums(rollups.mem);
    }
    if (statusType != "All") {
      devices = devices.filter((device, idx) => {
        return metrics[idx].statusType == statusType
      });
    }
    setPagination(devices);
    setFilteredDevices(values => ({...values, devices: devices}));

  }, [statusType, search]);

  const setPagination = (data) => {
    let numPages = Math.ceil(data.length / offset);
    setPagesCount(numPages);
    if (numPages >= 5) {
      setPaginationRange(range(0, 5));
    } else {
      setPaginationRange(range(0, numPages));
    }
    setCurrentPage(0);
  }

  const markMetricStatusTypes = (forecast) => {
    var devices = [];
    for (var device of forecast) {
      var metrics = [];
      for (var result of device.forecast.results) {
        var statusType = "ok";
        var level = getMetricLevel(result);
        if (level !== "") statusType = level;
        metrics.push({...result, statusType: statusType, tooltipOpen: false});
      }
      devices.push({...device, forecast: {results: metrics}});
    }
    return devices;
  }

  const toggleStatusType = (status) => {
    if (status == statusType) {
      setStatusType("All");
    } else {
      setStatusType(status);
    }
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    if (index > 2 && index < pagesCount - 2) {
      setPaginationRange(range(index - 2, index + 3));
    } else if (index <= 2) {
      if (pagesCount <= 5) {
        setPaginationRange(range(0, pagesCount));
      } else {
        setPaginationRange(range(0, 5));
      }
    } else {
      setPaginationRange(range(pagesCount - 5, pagesCount));
    }
  }

  const sortFields = ['DisplayName', 'DaysToWarningAvg', 'DaysToCriticalAvg', 'DaysToCapacityAvg'];

  // set order field and ascending or descending flag
  const sortItems = (field) => {
    if (field === sortBy) {
      // change ascending or descending flag
      setSortByDesc(!sortByDesc);
    } else {
      if (!sortFields.includes(field)) field = sortFields[0];
      setSortByDesc(false);
      setSortBy(field);
    }

    let desc = sortByDesc ? -1 : 1;
    items.sort((a, b) => {
      let item1 = a[sortBy], item2 = b[sortBy];
      if (typeof item1 === 'string') item1 = item1.toLowerCase()
      if (typeof item2 === 'string') item2 = item2.toLowerCase()

      if (item1 < item2) return -1 * desc;
      else if (item1 > item2) return desc;
      else return 0;
    });
    setItems(items);
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i>
        </div>
      ) : (
        props.rollup ? (
          <>
            <Table className="mb-2">
              <thead>
              <tr>
                <th></th>
                <th>Warning</th>
                <th>Critical</th>
                <th>At Capacity</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td className="text-right">CPU</td>
                <td><span className="cursor"
                          onClick={() => {
                            history.push("/app/vm/capacity", getPushedState("cpu", "warning", rollups.cpu.warning))
                          }}>
                    {rollups.cpu.warning}{" "}{getDashboardIcon("warning", rollups.cpu.warning)}
                  </span></td>
                <td><span className="cursor"
                          onClick={() => {
                            history.push("/app/vm/capacity", getPushedState("cpu", "critical", rollups.cpu.critical))
                          }}>
                    {rollups.cpu.critical}{" "}{getDashboardIcon("critical", rollups.cpu.critical)}
                  </span></td>
                <td><span className="cursor"
                          onClick={() => {
                            history.push("/app/vm/capacity", getPushedState("cpu", "capacity", rollups.cpu.capacity))
                          }}>
                    {rollups.cpu.capacity}{" "}{getDashboardIcon("capacity", rollups.cpu.capacity)}
                  </span></td>
                {/*<td><DashboardStatusIndicator metric={rollups.cpu}></DashboardStatusIndicator></td>*/}
              </tr>
              <tr>
                <td className="text-right">Memory</td>
                <td><span className="cursor"
                          onClick={() => {
                            history.push("/app/vm/capacity", getPushedState("mem", "warning", rollups.mem.warning))
                          }}>
                    {rollups.mem.warning}{" "}{getDashboardIcon("warning", rollups.mem.warning)}
                  </span></td>
                <td><span className="cursor"
                          onClick={() => {
                            history.push("/app/vm/capacity", getPushedState("mem", "critical", rollups.mem.critical))
                          }}>
                    {rollups.mem.critical}{" "}{getDashboardIcon("critical", rollups.mem.critical)}
                  </span></td>
                <td><span className="cursor"
                          onClick={() => {
                            history.push("/app/vm/capacity", getPushedState("mem", "capacity", rollups.mem.capacity))
                          }}>
                    {rollups.mem.capacity}{" "}{getDashboardIcon("capacity", rollups.mem.capacity)}
                  </span></td>
                {/*<td><DashboardStatusIndicator metric={rollups.mem}></DashboardStatusIndicator></td>*/}
              </tr>
              </tbody>
            </Table>
            <p className="text-left"><small>Number of virtual machines approaching capacity issues.</small></p>
          </>
        ) : (
          <>
            <div className="row small-desktop-table">
              <div className="col-md-3 pl-0" style={{paddingBottom: "15px"}}>
                <div>
                  <form
                    onSubmit={(e) => {
                      setSearch(query);
                      e.preventDefault();
                    }}
                    style={{maxWidth: "250px", position: "relative"}}
                  >
                    <Input
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                      type="text"
                      placeholder="Search for VMs"
                      style={{paddingRight: "60px"}}
                    >
                    </Input>
                    <i className="fas fa-times-circle"
                       id="clearButton"
                       style={{cursor: "pointer", position: "absolute", right: "10px", top: "12px"}}
                       onClick={() => {
                         setQuery("");
                         setSearch("");
                       }}
                    >
                    </i>

                  </form>
                </div>
              </div>
              <div className="col-md-9 col-xl-6">
                <ButtonGroup size="sm" className="d-flex w-100 justify-content-center" role="group"
                             style={{paddingBottom: "15px"}}>
                  <Button
                    onClick={() => {
                      toggleStatusType("ok")
                    }}
                    className={`bm-status-btn btn-sm w-100 ${(statusType !== "ok") ? "btn-simple" : ""}`}
                    style={{maxWidth: "190px", border: "unset"}}
                  >
                    <div className="row justify-content-center fas">
                      <i className="text-success fas fa-check mr-1"></i>{` Ok (${statusTypeNums.ok})`}
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      toggleStatusType("warning")
                    }}
                    className={`bm-status-btn btn-sm w-100 ${(statusType !== "warning") ? "btn-simple" : ""}`}
                    style={{maxWidth: "190px", border: "unset"}}
                  >
                    <div className="row justify-content-center fas">
                      <i
                        className="text-warning fas fa-exclamation-triangle mr-1"></i>{` Warning (${statusTypeNums.warning})`}
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      toggleStatusType("critical")
                    }}
                    className={`bm-status-btn btn-sm w-100 ${(statusType !== "critical") ? "btn-simple" : ""}`}
                    style={{maxWidth: "190px", border: "unset"}}
                  >
                    <div className="row justify-content-center fas">
                      <i
                        className="text-warning fas fa-exclamation-circle mr-1"></i>{` Critical (${statusTypeNums.critical})`}
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      toggleStatusType("capacity")
                    }}
                    className={`bm-status-btn btn-sm w-100 ${(statusType !== "capacity") ? "btn-simple" : ""}`}
                    style={{maxWidth: "190px", border: "unset"}}
                  >
                    <div className="row justify-content-center fas">
                      <i
                        className="text-danger fas fa-exclamation-circle mr-1"></i>{` Capacity (${statusTypeNums.capacity})`}
                    </div>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="table-responsive-sm justify-content-center small-desktop-table">
              <Table bordered id={'solarwind-' + props.metric}>
                <thead>
                <tr>
                  <th onClick={() => {
                    sortItems("DisplayName")
                  }} style={{cursor: "pointer"}}>VM
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortByDesc ? "up" : "down")}></i>
                  </th>
                  <th onClick={() => {
                    sortItems("DaysToWarningAvg")
                  }} style={{cursor: "pointer"}}>Warning Date
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortByDesc ? "up" : "down")}></i>
                  </th>
                  <th onClick={() => {
                    sortItems("DaysToCriticalAvg")
                  }} style={{cursor: "pointer"}}>Critical Date
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortByDesc ? "up" : "down")}></i>
                  </th>
                  <th onClick={() => {
                    sortItems("DaysToCapacityAvg")
                  }} style={{cursor: "pointer"}}>At Capacity
                    <i className={"ml-1 fas fa-long-arrow-alt-" + (sortByDesc ? "up" : "down")}></i>
                  </th>
                  <th className="text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.slice(currentPage * offset, (currentPage + 1) * offset).map((item, idx) => {
                  const {DaysToWarningAvg, DaysToCriticalAvg, DaysToCapacityAvg} = item;
                  const highestPriorityThreshold = getMetricLevel(item);
                  return (
                    <tr key={item.DisplayName + '-' + props.metric}>
                      <td>
                        {getRowStatusIcon(item, idx)}{" "}
                        <span className={highestPriorityThreshold != "" ? "font-weight-bold" : ""}>
                      {item.DisplayName}
                    </span>
                      </td>
                      <td>
                    <span className={highestPriorityThreshold == "warning" ? "font-weight-bold" : ""}>
                      {hasNegativeTrend(item) ? 'N/A' : addDays(DaysToWarningAvg)}
                    </span>
                      </td>
                      <td>
                    <span className={highestPriorityThreshold == "critical" ? "font-weight-bold" : ""}>
                      {hasNegativeTrend(item) ? 'N/A' : addDays(DaysToCriticalAvg)}
                    </span>
                      </td>
                      <td>
                    <span className={highestPriorityThreshold == "capacity" ? "font-weight-bold" : ""}>
                      {hasNegativeTrend(item) ? 'N/A' : addDays(DaysToCapacityAvg)}
                    </span>
                      </td>
                      <td className="dropdown-cell text-center">
                        <ActionsDropdown deviceID={item.deviceID}></ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </Table>
              <div className="pt-3 d-flex w-100 justify-content-center">
                <Pagination>
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink first href="#" onClick={(e) => handlePageClick(e, 0)}/>
                  </PaginationItem>
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink previous href="#" onClick={(e) => handlePageClick(e, currentPage - 1)}/>
                  </PaginationItem>
                  {paginationRange.map((page, i) => (
                    <PaginationItem active={page === currentPage} key={page}>
                      <PaginationLink onClick={e => handlePageClick(e, page)} href="#">
                        {page + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink next href="#" onClick={(e) => handlePageClick(e, currentPage + 1)}/>
                  </PaginationItem>
                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink last href="#" onClick={(e) => handlePageClick(e, pagesCount - 1)}/>
                  </PaginationItem>
                </Pagination>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

const createNewTicket = (e, deviceID, history) => {
  if (e != null) {
    e.preventDefault();
  }
  history.push({
    pathname: "/app/create-ticket",
    state: {
      closeOnSuccess: true,
      subject: '',
      description: '',
      attachmentElementId: '',
      deviceId: deviceID
    }
  });
}

const ActionsDropdown = (props) => {
  const history = useHistory();
  const [stateApp, stateAppActions] = appState();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = (item) => {
    setDropdownOpen(!dropdownOpen);
    if (typeof item === "object") return;
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <React.Fragment>
          Actions
        </React.Fragment>
        <i className={"" + " fas fa-caret-" + (dropdownOpen ? 'up' : 'down')} style={{paddingLeft: 3 + 'px'}}></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={(e) => {
          createNewTicket(e, props.deviceID, history)
        }}>Create Ticket</DropdownItem>
        <DropdownItem onClick={() => {
          history.push("/app/devices", {deviceID: props.deviceID});
        }}>View Device</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

const SolarWindsDetailWidget = props => {
  let types = {
    CpuLoad: 'CPU Utilization',
    PercentMemoryUsed: 'Memory Utilization',
  };

  const targetPart = types[props.type].split(' ')[0];

  return (
    <Card className="card-home" key={props.id}>
      <CardBody>
        <SolarwindForecast metric={props.type}></SolarwindForecast>
        <div style={{marginLeft: "10%"}}>
          <p className="text-left mt-2 ">
            <span style={{fontSize: "1rem"}}>N/A</span> = Usage has decreased over time.
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

const SolarWindsDesktopWidget = props => {
  const history = useHistory();

  let types = {
    CpuLoad: 'CPU Utilization',
    PercentMemoryUsed: 'Memory Utilization',
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-fill">
        <h3 className="text-left">VMware Capacity</h3>
        <SolarwindForecast rollup={true}></SolarwindForecast>
      </div>
      <button
        type="button"
        className="btn btn-light align-self-end btn-sm mt-3"
        onClick={() => {
          history.push("/app/vm/capacity", {vmCapacityFilter: "All"});
        }}
      >
        {" "}
        <span
          style={{display: "inline", verticalAlign: "middle"}}
          className="fas fa-chart-line"
        ></span>{" "}
        View Detail
      </button>
    </div>
  );
};

const SolarWindsDetailLayout = props => {
  //global state
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  //const [activeTab, setActiveTab] = useState("1");
  const [cpuTabLoaded, setCpuTabLoaded] = useState(false);
  const [memoryTabLoaded, setMemoryTabLoaded] = useState(false);

  useEffect(() => {
    if ((history.location.state != null) && history.location.state.hasOwnProperty("vmMetricType")) {
      if (history.location.state.vmMetricType == "cpu") {
        setActiveTab("1");
      } else {
        setActiveTab("2");
      }
    }
  }, []);

  const toggleTab = (tab) => {
    setActiveTab(tab);
    if (tab == "1") {
      setCpuTabLoaded(true);
    }
    if (tab == "2") {
      setMemoryTabLoaded(true);
    }
  }

  return (
    <>
      <h3 className="mb-3 mt-4 tria-header-class">VMware Capacity</h3>
      <hr></hr>
      <ButtonGroup role="group" className="d-flex mb-3 justify-content-center">
        <Button onClick={() => toggleTab('1')} active={activeTab === '1'}
                className={`btn-sm w-100 ${(activeTab !== '1') ? "btn-simple" : ""}`}
                style={{maxWidth: "250px", border: "unset"}}>CPU Utilization</Button>
        <Button onClick={() => toggleTab('2')} active={activeTab === '2'}
                className={`btn-sm w-100 ${(activeTab !== '2') ? "btn-simple" : ""}`}
                style={{maxWidth: "250px", border: "unset"}}>Memory Utilization</Button>
      </ButtonGroup>
      <TabContent activeTab={activeTab} id="tab-content">
        <TabPane tabId="1">
          {(activeTab == "1" || cpuTabLoaded) &&
          <SolarWindsDetailWidget type={'CpuLoad'} id={'cpu-solarwinds'}></SolarWindsDetailWidget>}
        </TabPane>
        <TabPane tabId="2">
          {(activeTab == "2" || memoryTabLoaded) &&
          <SolarWindsDetailWidget type={'PercentMemoryUsed'} id={'disk-solarwinds'}></SolarWindsDetailWidget>}
        </TabPane>
      </TabContent>
    </>
  )
}

export {SolarwindForecast, SolarWindsDesktopWidget, SolarWindsDetailWidget, SolarWindsDetailLayout};
