import AWSLogo from "../assets/img/icons/clouds/aws-logo.png"
import AWSGovLogo from "../assets/img/icons/clouds/aws_gov-logo.png"
import AzureArmLogo from "../assets/img/icons/clouds/azure_arm-logo.png"
import GCELogo from "../assets/img/icons/clouds/gce-logo.png"
import IBMiLogo from "../assets/img/icons/clouds/IBM_i.png"
import K8SLogo from "../assets/img/icons/clouds/k8s-logo.png"
import VMWareVsphereLogo from "../assets/img/icons/clouds/vmware_vsphere-logo.png"

export const cloudIconMapping = {
	'aws': AWSLogo,
	'azure': AzureArmLogo,
	'azure_arm': AzureArmLogo,
	'aws_gov': AWSGovLogo,
	'gce': GCELogo,
	'ibmi': IBMiLogo,
	'k8s': K8SLogo,
	'vmware_vsphere': VMWareVsphereLogo,
}