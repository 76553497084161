import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import React, { useState, useEffect, useMemo } from 'react';
import { appState } from '../../AppState';
import Enum from '../../utility/enum.js';
import { match } from 'ramda';
import { matchedClouds, matchedIntegrations, matchedEnv, hasPolicies } from '../../utility/FeatureToggle';
const DynamicDashboardCardBodyCarousel = (props) => {
  const [stateApp] = appState();

  // Update the index in the card header
  const updateCarouselIndex = (index) => {
    props.setCarouselIndex(index);
  };

  return (
    <Carousel
      selectedItem={props.carouselIndex}
      showStatus={false}
      showThumbs={false}
      onChange={updateCarouselIndex}
      className="carousel-control-dots-abs"
    >
      {props.components.map((comp, index) => {
        return (
          <div key={index} className="h-100">
            {comp}
          </div>
        );
      })}
    </Carousel>
  );
};
function flatten(ary, prop) {
  var ret = [];
  for (var i = 0; i < ary.length; i++) {
    if (Array.isArray(ary[i][prop])) {
      ret = ret.concat(flatten(ary[i][prop], prop));
    } else {
      ret.push(ary[i]);
    }
  }
  return ret;
}
const DynamicDashboardCard = (props) => {
  const [stateApp] = appState();
  const user_roles = stateApp.roles_v2;
  const policies = stateApp.policies;
  const [matches, setMatches] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const noCard = useMemo(
    () => (typeof props.noCard === 'function' ? props.noCard(stateApp.env, stateApp.clouds) : props.noCard),
    [stateApp.env, stateApp.clouds, props.noCard]
  );

  useEffect(() => {
    var m = props.components.filter((c) => {
      var matchCloud = false;
      var matchEnv = false;
      var matchPolicies = hasPolicies(c.props.policies, stateApp);
      var matchIntegration = true;
      for (var i = 0; i < (c.props.clouds || []).length; i++) {
        if (c.props.clouds[i] == Enum.CloudCategory.ALL) {
          matchCloud = true;
          break;
        }
        if (stateApp.clouds.includes(c.props.clouds[i])) {
          matchCloud = true;
          break;
        }
      }
      for (var i = 0; i < (c.props.envs || []).length; i++) {
        if (c.props.envs[i] == Enum.Env.ALL || c.props.envs[i] === stateApp.env) {
          matchEnv = true;
          break;
        }
      }
      for (var i = 0; i < (c.props.integrations || []).length; i++) {
        if (!stateApp.integrations.includes(c.props.integrations[i])) {
          matchIntegration = false;
          break;
        }
      }

      return matchCloud && matchEnv && matchIntegration && matchPolicies;
    });

    //    if (!stateApp.superAdmin && user_roles.filter((e) => e.RoleName == "OrgAdmin").length == 0){

    //     m = (props.components.filter(c => {

    //     if(c.props.policies){
    //       if(!policies.includes(c.props.policies[0]))
    //       return false;
    //     }

    //     return true;
    //   }));
    // }

    setMatches(m);
  }, []);

  const hasContent = matches !== null && (matches || []).length > 0;
  const content =
    hasContent &&
    (matches.length > 1 ? (
      <DynamicDashboardCardBodyCarousel
        components={matches}
        carouselIndex={carouselIndex}
        setCarouselIndex={setCarouselIndex}
      ></DynamicDashboardCardBodyCarousel>
    ) : (
      matches[0]
    ));

  return (
    <>
      {hasContent && (
        <div className={props.className}>
          <div className={props.equalHeightCards ? 'd-flex h-100' : ''}>
            {noCard ? (
              content
            ) : (
              <Card className="card-chart card-home">
                <CardHeader className="card-header" style={{background: 'white', borderBottom: 'none', boxShadow: 'none'}}>
                  <CardTitle className="tria-card-title" tag="h3">
                    {props.title}
                    {matches.length > 1 && (
                      <span className="pull-right">
                        {carouselIndex + 1}/{matches.length}
                      </span>
                    )}
                  </CardTitle>
                </CardHeader>
                <CardBody>{content}</CardBody>
              </Card>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const DynamicDashboardRow = (props) => {
  //  if(!hasPolicies(props.policies)) return <></>

  return (
    <>
      {props.cards.map((c, i) => {
        //check for nesting - only support one-level
        //take the col class from the first row

        if (c.nestedConfig) {
          return (
            <div key={i} className={c.nestedConfig[0].className}>
              <DynamicDashboardComponents config={c.nestedConfig}></DynamicDashboardComponents>
            </div>
          );
        } else {
          return (
            <DynamicDashboardCard
              key={i}
              title={c.title}
              className={c.className}
              components={c.components}
              noCard={c.noCard}
              equalHeightCards={props.equalHeightCards}
            />
          );
        }
      })}
    </>
  );
};

const DynamicDashboardComponents = (props) => {
  const components = props.config.map((c, i) => {
    return (
      <DynamicDashboardRow
        policies={c.policies || []}
        cards={c.cards}
        key={i}
        equalHeightCards={c.equalHeightCards}
      ></DynamicDashboardRow>
    );
  });
  return <>{components}</>;
};

export { DynamicDashboardComponents };
