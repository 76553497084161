import React, { useEffect } from 'react';
import ClickstreamService from '../../services/ClickstreamService';
import { appState } from "../../AppState";
import Cognito from "../../utility/CognitoAuth";
const useHeadScript = (src, onLoad) => {
        const [stateApp, stateAppActions] = appState();
    useEffect(() => {
      // Check if the script is already present in the head
      const existingScript = document.head.querySelector(`script[src="${src}"]`);
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
  
        // Set the onload callback
        script.onload = onLoad;
  
        // Append script to the head
        document.head.appendChild(script);
  
        // Clean up the script on unmount
        return () => {
          document.head.removeChild(script);
        };
      } else {
        // If the script is already loaded and the onLoad function is provided
        // we call it immediately.
        onLoad?.();
      }
    }, [src, onLoad]);
  };
  
const CookieHubComponent = () => {
    const [stateApp, stateAppActions] = appState();
  const scriptUrl = 'https://cdn.cookiehub.eu/c2/0b0e2b68.js';

  const handleScriptLoad = () => {
    // Define the callback methods
    const asyncFunction = async (event_id, eData, event_target) => {
        // Your async code here
      
        let uid = 0;
        let sid = "";
        let email = "";
        let orgId = "";
        let orgName = "";
        if (stateApp.userInfo != null) {
            uid = stateApp.userInfo.user_id ?? 0;
            orgId = stateApp.userInfo.organization_id ?? "";
            orgName = (stateApp.userInfo.organization_name != null) ? stateApp.userInfo.organization_name.trim() : "";
            email = (stateApp.userInfo.email != null) ? stateApp.userInfo.email : "";
        }
        let t = await Cognito.getAccessTokenDecoded();

        sid = t.jti || "Empty"
        //default event data
        var event_id = event_id
        var event_target = "cookies";
       
        await ClickstreamService.push(uid, sid, event_id, eData, event_target, email, orgId, orgName);
 
      };
    const cpm = {
      onInitialise: async function(status) {
        if (this.hasConsented('analytics')) {
            await asyncFunction('analytics accepted', {action: 'consented', category: 'analytics'}, 'cookie-consent')
        
        }
      },
      onStatusChange: async function(status, previousStatus) {
        if (this.hasConsented("analytics")) {
            await asyncFunction(`analytics consented ${status}`, {action: 'consented', category: 'analytics'}, 'cookie-consent')
        
        }
      },
      hasConsented: async function(category) {
        await asyncFunction(`consented ${category}`, {}, 'cookie-consent')
        if (category == 'analytics') {
          
        
        }
      },
      onAllow: async function(category) {
        await asyncFunction(`allowed ${category}`,{action: 'allowed', category:category}, 'cookie-consent')
        if (category == 'analytics') {
            await asyncFunction('analytics consented', {}, 'cookie-consent')
        
        }
      },
      onRevoke: async function(category) {
        await asyncFunction(`revoked ${category}`, {action: 'revoked', category:category}, 'cookie-consent')
        if (category == 'analytics') {
            await asyncFunction('analytics revoked', {}, 'cookie-consent')
          
        }
      },
    };

    if (window.cookiehub) {
      window.cookiehub.load(cpm);
    } else {
      document.addEventListener("DOMContentLoaded", () => {
        window.cookiehub.load(cpm);
      });
    }
  };

  useHeadScript(scriptUrl, handleScriptLoad);

  return <></>;
};

export default CookieHubComponent;
