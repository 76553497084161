import React from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';

import IBMiIcon from '../../assets/img/IBMi.svg';
import ConnectriaIcon from '../../assets/img/connectria-logo-c.png';
import { cloudIconMapping } from '../../utility/cloudIconMapping';

export const cloudIcons = {
  aws: cloudIconMapping['aws'],
  azure: cloudIconMapping['azure_arm'],
  gcp: cloudIconMapping['gce'],
  kubernetes: cloudIconMapping['k8s'],
  k8s: cloudIconMapping['k8s'],
  vmware: cloudIconMapping['vmware_vsphere'],
  ibmi: IBMiIcon,
  connectria: ConnectriaIcon,
  all: ConnectriaIcon,
};

const alertIcons = {
  critical_error: 'far fa-engine-warning',
  critical_warning: 'far fa-siren-on',
  alert: 'far fa-clipboard-list-check',
  important_alert: 'far fa-exclamation-triangle',
  non_critical_event: 'fas fa-bolt',
  automation_event: 'far fa-magic',
  resolved: 'far fa-check',
  default: 'far fa-bell-exclamation',
};

export const CloudLogo = ({ type, size }) => {
  let iconSize = 45;
  switch (size) {
    case 'sm':
      iconSize = 30;
      break;
    case 'xs':
      iconSize = 24;
      break;
    default:
      break;
  }

  return <img src={cloudIcons[type]} alt={type} height={iconSize} />;
};

CloudLogo.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export const AlertStatus = ({ status, label, size }) => (
  <div className="d-inline-flex align-items-center">
    <i
      className={`
        ${status === 'resolved' ? 'far fa-check' : 'fas fa-circle'}
        ${status === 'awaiting_your_input' ? 'text-red' : status === 'in_progress' ? 'text-header' : 'text-blue'}
        tria-icon ${size || ''} text-blue mr-2 mr-lg-3
      `}
    />
    <span className="encode-sans-15 text-capitalize text-header">{label}</span>
  </div>
);

AlertStatus.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
};

AlertStatus.defaultProps = {
  size: 'small',
};

export const AlertSymbol = ({ icon, category, label, className, dense }) => {
  let iconBgColor = 'light-gray';
  let iconColor = null;
  let iconBadgeColor = 'text-blue';
  const categoryStr = snakeCase(category);

  if (categoryStr === 'critical_error' || categoryStr === 'critical_alert') {
    iconBgColor = 'red';
  } else if (categoryStr === 'important_alert') {
    iconBgColor = 'blue';
  }

  if (categoryStr === 'critical_warning' || categoryStr === 'alert') {
    iconColor = 'red';
  } else if (categoryStr === 'non_critical_event' || categoryStr === 'resolved') {
    iconColor = 'blue';
  }

  if (categoryStr.startsWith('critical') || categoryStr === 'alert') {
    iconBadgeColor = 'text-red';
  } else if (categoryStr === 'automation_event') {
    iconBadgeColor = 'text-header';
  }

  return (
    <div className={`d-inline-flex align-items-center ${className || ''}`}>
      <div className="d-inline-block position-relative">
        <div className="status-icon-token" sz="sm" color={iconBgColor}>
          <i
            className={`${icon || alertIcons[categoryStr] || alertIcons.default} status-icon`}
            {...(iconColor ? { color: iconColor } : {})}
          />
        </div>
        <i className={`fas fa-circle tria-icon xs position-absolute ${iconBadgeColor}`} />
      </div>
      {label && (
        <span className={`ml-2 ml-lg-3 ${dense ? 'encode-sans-12 text-header text-uppercase' : 'encode-sans-15'}`}>
          {label}
        </span>
      )}
    </div>
  );
};

AlertSymbol.propTypes = {
  icon: PropTypes.string,
  category: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  dense: PropTypes.bool,
};

AlertSymbol.defaultProps = {
  dense: false,
};
