
import React, {useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import { IBMiLPARMonitorFrontSide, IBMiLPARMonitorBackSide } from "../../components/IBMiDashboard/IBMiDashboard";
import { Flippy, FrontSide, BackSide } from 'react-flippy';
import { ConnectriaTicketsDashboardTab } from "../../components/ConnectriaTickets/ConnectriaTicketsDashboard";
import { BlueMatadorDashboardCharts } from "../../components/BlueMatadorDashboard/BlueMatadorDashboard";
import { appState } from "../../AppState";
import { IBMCartCard } from "../../components/IBMiCart/IBMCartCard";
import {SolarwindForecast} from "../VMwareSolarwind/SolarwindForecast";

function TicketTabIndicator(props) {
  if (props.enabled) {
    return (
      <i className="fas fa-circle text-blue unread-indicator" style={props.style}></i>
    );
  }
  return (
      <></>
  );
}


const TabbedDashboard = (props) => {
    //local state
    const [activeTab, setActiveTab] = useState('1');
    //global state
    const [stateApp, stateAppActions] = appState();
    
    const toggleTab = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <>
          <ButtonGroup className="d-flex" role="group">
            <Button color="neutral" onClick={() => setActiveTab('1')} active={activeTab === '1'} className="w-100">Dashboard</Button>
            <Button color="neutral" onClick={() => setActiveTab('2')} active={activeTab === '2'} className="w-100">Tickets <TicketTabIndicator enabled={stateApp.inboxCount > 0}/></Button>
          </ButtonGroup>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="mt-2">
              {props.children}
                 
                </div>
                </TabPane>
              <TabPane tabId="2">
                  <ConnectriaTicketsDashboardTab/>
              </TabPane>
            </TabContent>
        </>
    );
}

export default TabbedDashboard;
export {
  TicketTabIndicator
}