import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";
const base_api =
  "https://4jsb2f47s8.execute-api.us-east-1.amazonaws.com/dev/api";

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: base_api,
    headers: { Authorization: idToken },
  });

  return req;
}

const YetiContacts = {
  employee_list: async function () {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/contacts/employeelist`);
  },
  contact_list: async function () {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/contacts/contactlist`);
  }
};

export default YetiContacts;
