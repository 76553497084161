import React from "react";

export const ToggleSwitch = ({checked, onChange, ...props}) => {
  return (
    <label className="switch">
      <input 
        type="checkbox" 
        checked={checked || false} 
        onChange={onChange || false} 
        {...props || false}
      />
      <span className="slider round" />
    </label>
  )
} 