import axios from "axios";
import moment from "moment";

import ConnectriaTicketsService from './ConnectriaTicketsService';
import CookieService from "../services/CookieService";
import Cognito from "../utility/CognitoAuth";
const base_api = 'https://dev.tria.connectria.com';
const _base_accounts_url = 'https://accounts-dev.tria.connectria.com/labs/portal/';
async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
      baseURL: _base_accounts_url,
      headers: { Authorization: idToken },

  });
  //req.defaults.headers['Authorization'] = await Cognito.getIdToken();
  return req
}
async function _getPortalCustomerMeta(filter, params={}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId')
    let response = await req.get(`${orgId}/customer_meta/get`, { ...params, params: filter });
    if ((response.data.response.errors != null) && (response.data.response.errors.length > 0)) {
      return { 
        metadata: {
          meta_value: "FALSE"
      }};
    }
    return response.data.response.data.customer_meta[0];
  } catch (error) {
    console.log(error);
    throw(error);
  }
}


const PortalService = {
  getPortalCustomerMeta: async function (filter, params={}) {
    filter["customer_id"] = await ConnectriaTicketsService.portalUserId(params);
    return await _getPortalCustomerMeta(filter, params);
  },
};

export default PortalService;
