import React, {useEffect} from "react";

import {
    UncontrolledAlert
  } from "reactstrap";

const AppAlert = (props) => {
   
    return (
        <>
            {(props.visible) && 
                <div className={"alert-app" + (props.visible ? '' : 'alert-hidden')}>
                    <UncontrolledAlert className={`${props.className}`} color={props.color}>
                        {props.children}
                    </UncontrolledAlert>
                </div>
            }
        </>
    );
}

export default AppAlert;