import axios from 'axios';
import { useCallback, useState } from 'react';
import useAsyncEffect from '../utility/use-async-effect';

/**
 * Custom hook for fetching data asynchronously, managing loading, error states, and providing a revalidate function.
 *
 * @template T - The type of data to be returned by the fetch function.
 * @param {function(axios.CancelToken): Promise<T>} fetchFn - The async function responsible for fetching data. It receives an axios cancel token as an argument to support request cancellation.
 * @param {Array<any>} [dependencies=[]] - Dependencies array for the effect. The fetchFn will re-run when these dependencies change.
 * @param {axios.CancelTokenSource} [cancelTokenSource=axios.CancelToken.source()] - Axios cancel token source to handle request cancellation on unmount.
 * @returns {{ data: T | null, isLoading: boolean, error: Error | null, revalidate: function }} - An object containing the fetched data, loading state, any error encountered, and a revalidate function to trigger manual refetch.
 *
 * @example
 * // Example usage with different providers for fetching ticket data
 * const TicketComponent = ({ provider, ticketId }) => {
 *   const fetchTicketData = async (cancelToken) => {
 *     switch (provider) {
 *       case 'PROVIDER_A':
 *         const responseA = await ProviderAService.getTicketData(ticketId, { cancelToken });
 *         return responseA.data;
 *       case 'PROVIDER_B':
 *         const responseB = await ProviderBService.fetchTicketDetails(ticketId, { cancelToken });
 *         return responseB.data.details;
 *       default:
 *         throw new Error('Unknown provider');
 *     }
 *   };
 * 
 *   const { data: ticket, isLoading, error, revalidate } = useFetchData(fetchTicketData, [provider, ticketId]);
 *   
 *   if (isLoading) {
 *     return <div>Loading...</div>;
 *   }
 *   
 *   if (error) {
 *     return <div>Error loading ticket: {error.message}</div>;
 *   }
 *   
 *   return (
 *     <div>
 *       <h1>Ticket Details</h1>
 *       <p>ID: {ticket.id}</p>
 *       <p>Status: {ticket.status}</p>
 *       <button onClick={revalidate}>Refetch Ticket Data</button>
 *     </div>
 *   );
 * };
 *
 * // Example usage with stateApp provider (similar to TicketAttachmentList and TicketHistories)
 * const TicketAttachments = (props) => {
 *   const [stateApp] = appState();
 *   const provider = stateApp.orgType || 'DEFAULT_PROVIDER';
 *
 *   const fetchAttachments = async (cancelToken) => {
 *     switch (provider) {
 *       case 'PROVIDER_A':
 *         return props.ticket.ticket_attachments;
 *       case 'PROVIDER_B':
 *         const attachmentsResponse = await ProviderBService.getTicketAttachments(props.ticketId, { cancelToken });
 *         return attachmentsResponse?.data?.results?.map(ProviderBService.parseTicketAttachment) || [];
 *       default:
 *         return [];
 *     }
 *   };
 *
 *   const { data: attachments, isLoading, error, revalidate } = useFetchData(fetchAttachments, [provider, props.ticketId]);
 *
 *   if (isLoading) {
 *     return (
 *       <div className="text-center">
 *         <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: '30px' }}></i>
 *       </div>
 *     );
 *   }
 *   
 *   if (error) {
 *     return <div>Error loading attachments: {error.message}</div>;
 *   }
 *
 *   return (
 *     <div>
 *       {attachments.map((attachment) => (
 *         <div key={attachment.id}>{attachment.fileName}</div>
 *       ))}
 *       <button onClick={revalidate}>Refetch Attachments</button>
 *     </div>
 *   );
 * };
 */
const useFetchData = (fetchFn, dependencies = [], cancelTokenSource = axios.CancelToken.source()) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // fetch data function wrapped inside useCallback to make it re-callable for revalidation
  const fetchData = useCallback(async (isMounted) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await fetchFn(cancelTokenSource.token);
      if (isMounted()) {
        setData(result);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
        if (isMounted()) {
          setError(error); // set error state if not cancelled
        }
      }
    } finally {
      if (isMounted()) {
        setIsLoading(false);
      }
    }
  }, [fetchFn, cancelTokenSource]);

  // call fetchData on initial render and whenever dependencies change
  useAsyncEffect(
    (isMounted) => {
      fetchData(isMounted);
    },
    () => {
      // cleanup fn to cancel the request on unmount
      cancelTokenSource.cancel();
    },
    dependencies
  );

  // revalidate function to trigger a refetch
  const revalidate = useCallback(() => {
    fetchData(() => true); // trigger refetch regardless of mounted status
  }, [fetchData]);

  return { data, isLoading, error, revalidate };
};

export default useFetchData;