import React, { useEffect, useState, useRef } from "react";
import { appState } from "../../AppState";
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { isMobile, isTablet } from "react-device-detect";

export const ToggleSwitch = (props) => {
  return (
    <label className="switch">
      <input type="checkbox" {...props} />
      <span className="slider round" />
    </label>
  )
}

const ButtonDropdown = ({ label, title, icon, className, children, ...props }) => {
  const [stateApp, stateAppActions] = appState();
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
 
  const handleDocumentClick = (e) => {
    var button = buttonRef?.current;
    var container = containerRef?.current;

    if (button && container && e.target !== container && !container.contains(e.target) && !button.contains(e.target)) {
      props.setIsOpen(!props.isOpen)
    }
  }

  const toggle = () => {
    props.setIsOpen(!props.isOpen);
  }

  useEffect(() => {
    if (!isMobile && !isTablet) {
      props.isOpen ?
        document.addEventListener('click', handleDocumentClick, true) :
        document.removeEventListener('click', handleDocumentClick, true)
    }
  }, [props.isOpen]);

  return (
    <div className={(className || "") + " position-relative"}>
      <button 
        ref={buttonRef} 
        className="tria-btn w-sm-100" 
        color={props.color || ""} 
        icon-pos={props.iconPos || ""} 
        sz="lg" 
        onClick={toggle}
        style={props.buttonStyle || null}
      >
        <i className={icon || "far fa-plus"} style={{marginTop: 2}}/>
        {label || ""}
      </button>
      <div
        ref={containerRef}
        className={(props.isOpen && !isMobile && !isTablet) ? '' : 'd-none'}
        style={{zIndex: "999", position: "absolute", right: "0px", width: props.cardWidth || "315px"}}
      >
        <Card 
          className="card-minimal button-dropdown-card" 
          style={{ top: "15px" }}
        >
          <CardHeader className="card-header-minimal" style={{paddingLeft: "25px", paddingTop: "30px"}}>
            <CardTitle className="card-title-minimal tria-card-title">
              {title}
            </CardTitle>
          </CardHeader>
          <CardBody style={{paddingLeft: "30px", paddingRight: "35px"}}>
            {children}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export { ButtonDropdown };
