import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useLightEdgeNotifications } from "../../hooks/useLightEdgeNotifications";

// NotificationItem component
const NotificationItem = ({ notification, onDismiss }) => {
  return (
    <div className="mb-3 rounded shadow-lg d-flex align-items-center bg-white px-2 py-1 border">
      <i className="fas fa-info-circle mr-1 text-danger mr-2" />
      <div
        className="flex-grow-1"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {notification.subject}
      </div>
      <div className="d-flex align-items-center">
        <Button color="link">
          <Link
            className="text-primary font-weight-bold"
            to={`/app/lightedge-notifications/${notification.id}`}
          >
            Details
          </Link>
        </Button>
        {" | "}
        <Button color="link" className="text-primary" onClick={onDismiss}>
          Dismiss
        </Button>
      </div>
    </div>
  );
};

// NotificationList component
const NotificationList = ({ notifications, onDismiss }) => (
  <div
    className="position-fixed bottom-0 end-0 p-3 text-end"
    style={{ maxWidth: "600px", right: "0" }}
  >
    {notifications.map((notif) => (
      <NotificationItem
        key={notif.id}
        notification={notif}
        onDismiss={() => onDismiss(notif.id)}
      />
    ))}
  </div>
);

// NotificationsWidget component
const NotificationsWidget = () => {
  const { loading, latestNotifications, dismissNotification } =
    useLightEdgeNotifications();

  if (loading) {
    return null;
  }

  return typeof document !== "undefined"
    ? ReactDOM.createPortal(
        <NotificationList
          notifications={latestNotifications}
          onDismiss={dismissNotification}
        />,
        document.body
      )
    : null;
};

export default NotificationsWidget;
