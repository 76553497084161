
import { appState } from "../AppState";
import Enum from "./enum";

const PolicyToggle = props => {
  const [stateApp, stateAppActions] = appState();
  let user_roles = stateApp.roles_v2;
  let policy_codes = stateApp.policies;

  const hasPolicies = (policies = []) =>{

    if(policies.length == 0) return true;
      
    if (user_roles.filter((e) => e.RoleName == "OrgAdmin").length > 0) return true;

    if (stateApp.superAdmin) return true;

    for (var i = 0; i < policies.length; i++) {
      if (!policy_codes.includes(policies[i])) {
        console.log('returnign false');
        return false;
      }
    }
    return true;
  }


  const criteriaMet = ( policies) =>{

    return(hasPolicies(policies));
  }
  return criteriaMet(props.policies) ? props.children : null;
};

export default PolicyToggle;


function flatten(ary, prop) {
  var ret = [];
  for (var i = 0; i < ary.length; i++) {
    if (Array.isArray(ary[i][prop])) {
      ret = ret.concat(flatten(ary[i][prop], prop));
    } else {
      ret.push(ary[i]);
    }
  }
  return ret;
}