import { useState, useEffect } from "react";
import React from "react";
const { detect } = require("detect-browser");

const BrowserSupport = props => {
  const [showMsg, setShowMsg] = useState(false);
  useEffect(() => {
    const browser = detect();

    switch (browser && browser.name) {
      case "chrome":
      case "firefox":
        setShowMsg(false);
        break;
        

      case "edge":
      case "ie":
        setShowMsg(true);
        break;

      default:
     
        break;
    }
  }, []);

  return (
    <>
      {!showMsg ? 
        <></>
       : 
        <div className="alert alert-danger" role="alert">
          <p className="h4 text-center" style={{color: 'white'}}>
            <i className="fas fa-info-circle"></i> Internet Explorer is currently
            not compatible with our application - Please use Chrome, Firefox, or Safari.
          </p>
          <button
            type="button"
            className="close"
            onClick={() => {setShowMsg(false)}}
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
    </>
  );
};
export default BrowserSupport;
