import React, { createContext, useContext } from 'react';
import useFetchData from '../../hooks/useFetchData';
import ConnectriaTicketsService from '../../services/ConnectriaTicketsService';
import YetiService from '../../services/YetiServices';
import YetiTicketProvider from '../../services/YetiTickets';
import { PROVIDERS } from './constants';


const fetchAttachments = async (provider, ticketId, cancelToken) => {
  switch (provider) {
    case PROVIDERS.CONNECTRIA:
      return await ConnectriaTicketsService.ticketAttachments(ticketId);
    case PROVIDERS.LIGHTEDGE:
      const attachmentsResponse = await YetiTicketProvider.getTicketAttachments(ticketId, { cancelToken });
      return attachmentsResponse?.data?.results?.map((attachment) =>
        YetiTicketProvider.parseTicketAttachment(attachment, ticketId)
      );
    default:
      throw new Error('Unknown provider');
  }
};

const fetchComments = async (provider, ticketId, cancelToken) => {
  switch (provider) {
    case PROVIDERS.CONNECTRIA:
      const [history, ratings] = await Promise.all([
        ConnectriaTicketsService.ticketHistory(ticketId, { cancelToken }),
        ConnectriaTicketsService.getTicketCommentRatings(ticketId, { cancelToken }),
      ]);
      const comments = (history || []).filter((comment) => !comment.pvt);
      return { comments, ratings };
    case PROVIDERS.LIGHTEDGE:
      const commentsResponse = await YetiTicketProvider.getTicketComments(ticketId, { cancelToken });
      const parsedComments = commentsResponse?.data?.results?.map((comment) =>
        YetiTicketProvider.parseTicketComment(comment)
      ) || [];
      return {
        comments: parsedComments,
        ratings: []
      };
    default:
      throw new Error('Unknown provider');
  }
};

const fetchTicketDetails = async (provider, ticketId, cancelToken) => {
  switch (provider) {
    case PROVIDERS.CONNECTRIA:
      return ConnectriaTicketsService.ticketDetail(ticketId, { cancelToken });
    case PROVIDERS.LIGHTEDGE:
      try {
        const [ticketResponse, recipientsResponse, servicesResponse] = await Promise.all([
          YetiTicketProvider.getTicket(ticketId, { cancelToken }),
          YetiTicketProvider.getTicketRecipients(ticketId),
          YetiService.serviceList(),
        ]);

        const ticket = YetiTicketProvider.parseTicket(ticketResponse.data.results);
        const recipients = recipientsResponse.data.results || [];
        const services = servicesResponse.data.results || [];

        const service = services.find((service) => service.id === ticket.device_id);

        return { ...ticket, recipients, service };
      } catch (error) {
        console.error('Error fetching ticket details:', error);
        return {};
      }
    default:
      return {};
  }
};

const TicketDataContext = createContext();
export const useTicketData = () => useContext(TicketDataContext);

export const TicketDataProvider = ({ ticketId, provider, children }) => {
  const {
    data: attachments = [],
    isLoading: isLoadingAttachments,
    error: attachmentsError,
    revalidate: revalidateAttachments,
  } = useFetchData(
    (cancelToken) => fetchAttachments(provider, ticketId, cancelToken),
    [provider, ticketId]
  );

  const {
    data: commentData = { comments: [], ratings: [] },
    isLoading: isLoadingComments,
    error: commentsError,
    revalidate: revalidateComments,
  } = useFetchData(
    (cancelToken) => fetchComments(provider, ticketId, cancelToken),
    [provider, ticketId]
  );

  const {
    data: ticketDetails = {},
    isLoading: isLoadingTicketDetails,
    error: ticketDetailsError,
    revalidate: revalidateTicketDetails,
  } = useFetchData(
    (cancelToken) => fetchTicketDetails(provider, ticketId, cancelToken),
    [provider, ticketId]
  );

  const comments = commentData?.comments || [];
  const ratings = commentData?.ratings || [];

  return (
    <TicketDataContext.Provider
      value={{
        attachments,
        attachmentsError,
        comments,
        commentsError,
        isLoadingAttachments,
        isLoadingComments,
        isLoadingTicketDetails,
        provider,
        ratings,
        revalidateAttachments,
        revalidateComments,
        revalidateTicketDetails,
        ticketDetails,
        ticketDetailsError,
      }}
    >
      {children}
    </TicketDataContext.Provider>
  );
};