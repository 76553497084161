import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

const AutoCompleteSelectForm = ({ options = [], value, onChange, name, id, className, required, isGrouping, groupingAttr }) => {
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  const wrapperRef = useRef(null);

  const openDropdown = () => {
    setInputValue('');
    setIsDropdownOpen(true);
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleOptionSelect = (selectedValue) => {
    setInputValue(selectedValue.description);
    onChange({ target: { name, value: selectedValue.id } });
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      const selectedOption = options.find((option) => option.id === value);
      setInputValue(selectedOption ? selectedOption.description : '');
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const selectedOption = options.find((option) => option.id === value);
    setInputValue(selectedOption ? selectedOption.description : '');

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [value, options]);

  const filteredOptions = (inputValue
    ? options.filter(option =>
        option.description.toLowerCase().includes(inputValue.toLowerCase())
      )
    : options)
    .reduce((acc, item) => {
      let key = item[groupingAttr];
      if (!key) key = 'Normal';
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

  return (
    <div className={`autocomplete-select-form ${className}`} ref={wrapperRef}>
      <Input
        required={required}
        type="text"
        name={name}
        id={id}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={openDropdown} // Open the dropdown when input is focused
        placeholder="Type to search..."
        className="form-control"
        autoComplete="off"
      />
      {isDropdownOpen && (<Dropdown isOpen={isDropdownOpen} toggle={() => {}}>
        <DropdownToggle tag="div" className="d-none" />
        <DropdownMenu>
          {Object.entries(filteredOptions).length ? (
            Object.entries(filteredOptions).map(([key, groupOptions]) => (
              <Fragment key={key}>
                {isGrouping && <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 5, backgroundColor: '#b3e6ff', padding: 5 }}>{key}</div>}
                {groupOptions.map((option) => (
                  <DropdownItem
                    key={option.id}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option.description}
                  </DropdownItem>
                ))}
              </Fragment>
            ))
          ) : (
            <DropdownItem disabled>No results found</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>)}
    </div>
  );
};

export default AutoCompleteSelectForm;
