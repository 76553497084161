import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ContentBlade = ({
  visible, className, headerClassName, side, noBackdrop, title, children, footer, setPanelState, style,
}) => {
  return (visible && 
    <>
      <div
        className={classNames('content-blade-backdrop', { 'no-backdrop': noBackdrop })}
        onClick={() => setPanelState({visible: false})}
      />
      <div className={`content-blade ${className || ''}`} side={side || "left"} style={style || null}>
        <div className={`content-blade-header ${headerClassName || ''}`}>
          <div className="tria-card-title d-flex justify-content-between align-items-center flex-wrap">
            {title || ""}
          </div>
        </div>
        <div className="content-blade-body">
          {children}
        </div>
        {footer &&
          <div className="tria-card-footer">
            {footer}
          </div>
        }
      </div>
    </>
  );
}

ContentBlade.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  side: PropTypes.string,
  noBackdrop: PropTypes.bool,
  title: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  setPanelState: PropTypes.func,
  style: PropTypes.object,
};

ContentBlade.defaultProps = {
  side: 'left',
  setPanelState: () => {},
};

export default ContentBlade;
