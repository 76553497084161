import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import moment from "moment";
import YetiNotifications from "../services/YetiAnnocuments";

const LOCAL_STORAGE_KEY = "tria_notifications";
const YEARS_AGO = 2;

const LightEdgeNotificationsContext = createContext({
  loading: true,
  notifications: [],
  latestNotifications: [],
  dismissNotification: () => {},
  error: null,
});

const fetchNotificationsFromAPI = async () => {
  try {
    const response = await YetiNotifications.notificationlist(
      moment().subtract(YEARS_AGO, "years").format("YYYY-MM-DD"),
      1,
      50
    );
    return response.data ? response.data.results : [];
  } catch (error) {
    console.error("Failed to fetch notifications:", error);
    throw error;
  }
};

// Get dismissed notifications from local storage
const getDismissedNotifications = () => {
  const dismissed = localStorage.getItem(LOCAL_STORAGE_KEY);
  return dismissed ? JSON.parse(dismissed) : [];
};

// Store dismissed notifications in local storage
const setDismissedNotifications = (notifications) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(notifications));
};

export const LightEdgeNotificationsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [latestNotifications, setLatestNotifications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const allNotifications = await fetchNotificationsFromAPI();
        const dismissed = getDismissedNotifications();

        // Filter out dismissed notifications
        const filteredNotifications = allNotifications.filter(
          (notification) => !dismissed.includes(notification.id)
        );

        setNotifications(allNotifications);
        setLatestNotifications(filteredNotifications.slice(0, 5));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const dismissNotification = useCallback((notificationId) => {
    const dismissed = getDismissedNotifications();
    const updatedDismissed = [...dismissed, notificationId];

    // Store updated dismissed notifications in local storage
    setDismissedNotifications(updatedDismissed);

    // Update latest notifications to exclude the dismissed one
    setLatestNotifications((prev) =>
      prev.filter((notification) => notification.id !== notificationId)
    );
  }, []);

  return (
    <LightEdgeNotificationsContext.Provider
      value={{
        loading,
        notifications,
        latestNotifications,
        dismissNotification,
        error,
      }}
    >
      {children}
    </LightEdgeNotificationsContext.Provider>
  );
};

export const useLightEdgeNotifications = () => {
  const context = useContext(LightEdgeNotificationsContext);
  if (!context) {
    throw new Error(
      "useLightEdgeNotifications must be used within a LightEdgeNotificationsProvider"
    );
  }
  return context;
};
