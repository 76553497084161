const Env = {
    LOCAL: 'local',
    DEV : 'dev',
    PROD: 'prod',
    DEMO: 'demo',
    ALL: 'all'
}

const CloudCategory = {
    AWS: 'aws',
    AZURE: 'azure',
    PUBLIC_CLOUD: 'public_cloud',
    VMWARE: 'vmware',
    IBMI: 'ibmi',
    ALL: 'all'
}

const Integration = {
    BLUEMATADOR: 'bluematador',
    CLOUDWATCH: 'cloudwatch',
    TRENDMICRO: 'trendmicro'
}

const Roles = {
  basicUser: 'basic',
  orgAdmin: 'orgAdmin',
  superAdmin: 'superAdmin'
}
const Policies = {
    BILLING_VIEW: "B_VIEW",
    COMPLIANCE_VIEW: "C_VIEW",
    NOTIFICATIONS_EXPORT: "N_EXPORT",
    NOTIFICATIONS_VIEW: "N_VIEW",
    LIGHT_EDGE_NOTIFICATIONS_VIEW: "LE_N_VIEW",
    VIEW_RESOURCE_PROPERTY: "O_VIEWRESRCPROP",
    ORGANIZATION_DETAIL_VIEW: "O_VIEWDETAIL",
    ADD_PEOPLE: "PEOPLE_ADD",

    RBAC_MOD: "RBAC_MOD",
    VIEW_LPAR: "LPAR_VIEW",
    EXPIRE_MFA: "PEOPLE_MFA",
    VIEW_PEOPLE: "PEOPLE_VIEW",
    RESET_PASSWORD: "PEOPLE_RESTPASSWD",

    EXPORT_DEVICES: "P_EXPORT",
    VIEW_CLOUD_ALARMS: "P_VIEWCLDALARM",
    VIEW_CLOUD_MON: "P_VIEWCLDMON",
    REMOTE_CONSOLE: "R_CONSOLE",
    MANAGED_DEVICE_VIEW: "P_VIEWMNGDEVICE",
    MALWARE_VIEW: "SEC_MALWARE",
    SECURITY_VIEW: "SEC_VIEW",
    SPEND_VIEW: "S_VIEW",
    TICKETS_VIEW: "T_VIEW",
    RESOURCES_VIEW: "R_VIEW",
    RESOURCES_EXPORT: "R_EXPORT",
    RESOURCES_CONSOLE: "R_CONSOLE",
    AUTOMATION_VIEW: "A_VIEW",
    REPORT_VIEW: "RPT_VIEW",
    RUNBOOKS_VIEW: "RNBK_VIEW",
    AUTOMATION_MANAGE: "A_MANAGE",
    AUTOMATION_VIEW: "A_VIEW",
    VIEW_BACKUP: "P_VIEWBCKUP",
    AWS_SUPPORT_TICKETS: "AWS_SUPPORT_TICKETS",
}

const OrgType  ={
    CONNECTRIA: "CONNECTRIA",
    LIGHTEDGE: "LIGHTEDGE"
}

class Enum{}

Enum.Env = Env;

Enum.CloudCategory = CloudCategory;

Enum.Integration = Integration;

Enum.Roles = Roles;

Enum.Policies = Policies

Enum.OrgType = OrgType

export default Enum;