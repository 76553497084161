import React, { useMemo } from "react";
import { useLocation, useRouteMatch, useHistory, useParams } from "react-router-dom";
import queryString from 'query-string';

const useRouter = () => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
  
    return useMemo(() => {
      return {
        push: history.push,
        replace: history.replace,
        pathname: location.pathname,
        // Merge params and parsed query string into single "query" object
        // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
        query: {
          ...queryString.parse(location.search), // Convert string to object
          ...params
        },
        params,
        match,
        location,
        history
      };
    }, [params, match, location, history]);
  }

export default useRouter;