import React, { lazy } from 'react';
import Enum from './utility/enum.js';

import Dashboard from './views/Dashboard.jsx';
// import { IBMiLparMonitorDesktopWidget } from './components/IBMiDashboard/IBMiDashboard.jsx';
// import { BlueMatadorWidget } from './components/BlueMatadorDashboard/BlueMatadorDashboard.jsx';
// import { CloudWatchAlarmsWidget } from './components/CloudWatch/CloudWatchAlarmsDashboard.jsx';
// import { AzureAlertsWidget } from './components/AzureAlerts/AzureAlertsDashboard.jsx';
// import { CostSavingsCard } from './costexplorer/components/CostSavingsWidget.jsx';
// import { DevicesWidget } from './components/Devices/DevicesDashboard.jsx';
// import { TrendMicroWidget } from './components/TrendMicro/TrendMicroDashboard.jsx';
// import { ComplianceDashboardCard } from './components/Compliance/ComplianceDashboardCard.jsx';
// import { SecurityAdvisorWidget } from './public_cloud/components/SecurityAdvisorDashboard.jsx';
import FeatureToggle from './utility/FeatureToggle.js';
import { ConnectriaTicketsDashboardTab } from './components/ConnectriaTickets/ConnectriaTicketsDashboard.jsx';
import { IBMCartCard } from './components/IBMiCart/IBMCartCard.jsx';
import { BackupsDashboardCard } from './components/BackupJobs/BackupsDashboardCard.jsx';
import UpsellCardContainer from './components/Upsell/UpsellCardContainer';
import ResourcesOverview from './components/ResourceManagement/ResourcesOverview';
import { BackupSingleJob } from './components/BackupJobs/BackupSingleJob.jsx';
import { DeviceDetailPage } from './components/Devices/DeviceDetailPage';
import { ACCOUNTS_URL } from './config';

const _legacyComplianceUrl = 'https://dev.tria.connectria.com/insights/saved?hideside&order=-severity';
const _legacyResourceUrl = 'https://dev.tria.connectria.com/resources?hideside';
const _legacyBotUrl = 'https://dev.tria.connectria.com/bots/listing?hideside';
const _legacyBotTemplatesUrl = 'https://dev.tria.connectria.com/bots/templates?hideside';
const _legacyBotCreateUrl = 'https://dev.tria.connectria.com/bots/factory?hideside';
const _legacyTriaTaggingUrl = 'https://dev.tria.connectria.com/tag-manager/tag-analyzer?hideside';
const _legacyDTaggingUrl = 'https://dev.tria.connectria.com/tags/saved?hideside';
const _legacyTriaResourceGroupsUrl = 'https://dev.tria.connectria.com/groups?hideside';
const _legacyTriaComplianceScorecardUrl = 'https://dev.tria.connectria.com/compliance/summary/scorecard?hideside';
const _legacyExemptionsUrl = 'https://dev.tria.connectria.com/exemptions/list?hideside';
const isNextGen = window.location.hostname.startsWith('next-gen');

// lazy loaded dashboard components
const IBMiLparMonitorDesktopWidget = React.lazy(() => import('./components/IBMiDashboard/IBMiDashboard.jsx'));
const BlueMatadorWidget = React.lazy(() => import('./components/BlueMatadorDashboard/BlueMatadorDashboard.jsx'));
const CloudWatchAlarmsWidget = React.lazy(() => import('./components/CloudWatch/CloudWatchAlarmsDashboard.jsx'));
const AzureAlertsWidget = React.lazy(() => import('./components/AzureAlerts/AzureAlertsDashboard.jsx'));
const CostSavingsCard = React.lazy(() => import('./costexplorer/components/CostSavingsWidget.jsx'));
const DevicesWidget = React.lazy(() => import('./components/Devices/DevicesDashboard.jsx'));
const TrendMicroWidget = React.lazy(() => import('./components/TrendMicro/TrendMicroDashboard.jsx'));
const ComplianceDashboardCard = React.lazy(() => import('./components/Compliance/ComplianceDashboardCard.jsx'));
const SecurityAdvisorWidget = React.lazy(() => import('./public_cloud/components/SecurityAdvisorDashboard.jsx'));

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",

    rtlName: "لوحة القيادة",
    icon: "fad fa-fw fa-th-large",
    component: Dashboard,
    layout: "/app",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    clickstreamMeta: {
      event_id: "visited",
      event_target: "tria-dashboard",
      event_data: {
        category: "tria",
      },
    },
  },
  {
   
    path: '/colo/dashboard',
    name: 'Colocation',
    icon: 'far fa-map-marker-alt',
    component: lazy(() => import('./components/Colocation/Colocation.jsx')),
    layout: '/app',
    envs: [Enum.Env.DEV],
    clouds: [Enum.CloudCategory.ALL],
    clickstreamMeta: {
      event_id: 'visited',
      event_target: 'le-colo-dashboard',
      event_data: {
        category: 'tria',
      },
    },
  },
  {
    path: '/cloud/dashboard',
    name: 'Cloud',
    icon: 'far fa-cloud',
    component: lazy(() => import('./components/CloudMenu/CloudMenu.jsx')),
    layout: '/app',
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    clickstreamMeta: {
      event_id: 'visited',
      event_target: 'le-cloud-dashboard',
      event_data: {
        category: 'tria',
      },
    },
  },
  {
    group: 'Resources',
    envs: [Enum.Env.ALL],
    policy: [Enum.Policies.RESOURCES_VIEW],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fab fa-uncharted",
    routes: [
      {
        path: "/resource-management",
        name: "Resource Management",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        policy: [Enum.Policies.RESOURCES_VIEW],
        component: lazy(() =>
          import("./components/ResourceManagement/ResourceManagementPage")
        ),
        layout: "/app",
        exact: true,
      },
    ],
  },
  // {
  //   group: "Clouds",
  //   envs: [Enum.Env.ALL],
  //   clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //   icon: "fal fa-clouds",
  //   routes: [
  //     {
  //       path: "/clouds/listing",
  //       name: "Cloud List",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD, Enum.CloudCategory.VMWARE],
  //       icon: "fas fa-cloud",
  //       component: CloudsListing,
  //       layout: "/app"
  //     },
  //     {
  //       path: "/cloud/resources",
  //       name: "Cloud Resources",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //       rtlName: "لوحة القيادة",
  //       icon: "fas fa-cloud",
  //       props: {url: _legacyResourceUrl},
  //       component: LegacyFrame,
  //       iframe: true,
  //       layout: "/app",
  //       suppress: false
  //     },
  //     {
  //       path: "/cloud/groups",
  //       name: "Resource Groups",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //       rtlName: "لوحة القيادة",
  //       icon: "fas fa-layer-group",
  //       props: {url: _legacyTriaResourceGroupsUrl},
  //       component: LegacyFrame,
  //       iframe: true,
  //       layout: "/app",
  //       suppress: false
  //     },
  //     {
  //       path: "/tag/explorer",
  //       name: "Tag Explorer",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //       rtlName: "لوحة القيادة",
  //       icon: "fas fa-user-tag",
  //       props: {url: _legacyDTaggingUrl},
  //       component: LegacyFrame,
  //       iframe: true,
  //       layout: "/app",
  //       suppress: false
  //     },
  //     {
  //       path: "/tag/analyzer",
  //       name: "Tag Analyzer",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //       rtlName: "لوحة القيادة",
  //       icon: "fas fa-tags",
  //       props: {url: _legacyTriaTaggingUrl},
  //       component: LegacyFrame,
  //       iframe: true,
  //       layout: "/app",
  //       suppress: false
  //     },
  /*{
        path: "/cloud/cost/:cloud_id/:account_id",
        name: "Cloud Cost Dashboard",
        envs: [Enum.Env.DEV],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: CostResourceDashboard,
        layout: "/app",
        suppress: true  
      },*/
  //     {
  //       path: "/cloud/bots_listing",
  //       name: "Bot Factory",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //       rtlName: "لوحة القيادة",
  //       icon: "fas fa-robot",
  //       props: {url: _legacyBotUrl},
  //       component: LegacyFrame,
  //       iframe: true,
  //       layout: "/app",
  //       suppress: true
  //     },
  //       {
  //       path: "/cloud/bots_templates",
  //       name: "Bot Factory",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //       rtlName: "لوحة القيادة",
  //       icon: "fas fa-robot",
  //       props: {url: _legacyBotTemplatesUrl},
  //       component: LegacyFrame,
  //       iframe: true,
  //       layout: "/app",
  //       suppress: true
  //     },

  //     {
  //       path: "/cloud/exemptions",
  //       name: "Exemptions",
  //       envs: [Enum.Env.ALL],
  //       clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //       rtlName: "لوحة القيادة",
  //       icon: "fas fa-table",
  //       props: {url: _legacyExemptionsUrl},
  //       component: LegacyFrame,
  //       iframe: true,
  //       layout: "/app",
  //       suppress: true
  //     },
  //   ]
  // },
  {
    group: "Performance",
    envs: [Enum.Env.ALL],
    policy: [
      Enum.Policies.MANAGED_DEVICE_VIEW,
      Enum.Policies.VIEW_LPAR,
      Enum.Policies.VIEW_CLOUD_MON,
      Enum.Policies.VIEW_CLOUD_ALARMS,
      ,
      Enum.Policies.VIEW_BACKUP,
    ],
    clouds: [Enum.CloudCategory.ALL],
    icon: "fal fa-tachometer-alt-fastest",
    routes: [
      {
        path: "/devices",
        name: "Managed Devices",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.MANAGED_DEVICE_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        rtlName: "لوحة القيادة",
        icon: "fas fa-life-ring",
        component: lazy(() => import("./components/Devices/DevicesPage.jsx")),
        layout: "/app",
        exact: true,
        clickstreamMeta: {
          event_id: "visited",
          event_target: "devices",
          event_data: {
            category: "support",
          },
        },
      },
      {
        path: "/devices/:id",
        name: "Node Detail",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.MANAGED_DEVICE_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        suppress: true,
        component: DeviceDetailPage,
        layout: "/app",
      },
      {
        path: "/ibm-dashboard",
        name: "IBM i LPARs",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.VIEW_LPAR],
        clouds: [Enum.CloudCategory.IBMI],
        rtlName: "لوحة القيادة",
        icon: "fas fa-server",
        component: lazy(() => import("./views/IbmDashboard")),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "ibmi-dashboard",
          event_data: {
            category: "ibmi",
          },
        },
      },
      {
        path: "/ibm/host/report/:id",
        name: "IBM i Report",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.VIEW_LPAR],
        clouds: [Enum.CloudCategory.IBMI],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() => import("./views/IbmHostReportDashboard.jsx")),
        layout: "/app",
        suppress: true,
        clickstreamMeta: {
          event_id: "visited",
          event_target: "ibmi-report",
          event_data: {
            category: "ibmi",
          },
        },
      },
      {
        path: "/ibm/host/:id",
        name: "Dashboard",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.VIEW_LPAR],
        clouds: [Enum.CloudCategory.IBMI],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() => import("./views/IbmHostDashboard.jsx")),
        layout: "/app",
        suppress: true,
        key: "ibm-host-dashboard",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "ibmi-host-dashboard",
          event_data: {
            category: "ibmi",
          },
        },
      },
      {
        path: "/cloud/monitoring",
        name: "Cloud Monitor",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.VIEW_CLOUD_MON],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        integrations: [Enum.Integration.BLUEMATADOR],
        rtlName: "ار تي ال",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() =>
          import("./components/BlueMatadorDashboard/BlueMatadorDetails.jsx")
        ),
        layout: "/app",
        key: "bluematador-events-inbox",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "bluematador-events-inbox",
          event_data: {
            category: "bluematador",
          },
        },
      },
      {
        path: "/cloud/cloudwatch",
        name: "AWS Cloud Alarms",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AWS],
        policy: [Enum.Policies.VIEW_CLOUD_ALARMS],
        // integrations: [Enum.Integration.CLOUDWATCH],
        rtlName: "ار تي ال",
        icon: "fas fa-bell",
        component: lazy(() =>
          import("./components/CloudWatch/CloudWatchAlarms.jsx")
        ),
        layout: "/app",
        key: "cloudwatch-alarms",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "cloudwatch-alarms",
          event_data: {
            category: "cloudwatch",
          },
        },
      },
      {
        path: "/cloud/azure-alerts",
        name: "Azure Monitor",
        policy: [Enum.Policies.VIEW_CLOUD_ALARMS],
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AZURE],
        rtlName: "ار تي ال",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() =>
          import("./components/AzureAlerts/AzureAlerts.jsx")
        ),
        layout: "/app",
        key: "azure-alerts",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "azure-alerts",
          event_data: {
            category: "azure",
          },
        },
      },
      {
        path: "/backup",
        name: "Backup Jobs",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.VIEW_BACKUP],
        clouds: [Enum.CloudCategory.VMWARE],
        rtlName: "لوحة القيادة",
        icon: "fas fa-life-ring",
        component: lazy(() =>
          import("./components/BackupJobs/BackupJobsDashborard")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "backup client",
          event_data: {
            category: "backup",
          },
        },
      },
      {
        path: "/view/backup/jobs/client/:id",
        name: "Backup Jobs",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.VIEW_BACKUP],
        clouds: [Enum.CloudCategory.ALL],
        suppress: true,
        component: lazy(() =>
          import("./components/BackupJobs/BackupAllJobs.jsx")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "backup-jobs",
          event_data: {
            category: "backup",
          },
        },
      },
      {
        path: "/view/backup/job/:id/:status",
        name: "Backup Job",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.VIEW_BACKUP],
        clouds: [Enum.CloudCategory.ALL],
        suppress: true,
        component: BackupSingleJob,
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "backup-job",
          event_data: {
            category: "backup",
          },
        },
      },
    ],
  },
  {
    group: "Spend",
    envs: [Enum.Env.ALL],
    policy: [Enum.Policies.SPEND_VIEW],
    clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
    icon: "fal fa-usd-circle",
    routes: [
      {
        path: "/cloud/cost/dashboard",
        name: "Spend Dashboard",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.SPEND_VIEW],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() =>
          import("./costexplorer/dashboard/cost-dashboard")
        ),
        layout: "/app",
        suppress: false,
      },
      {
        path: "/cloud/cost/spend-resources",
        name: "Spend By Resources",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.SPEND_VIEW],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() =>
          import("./costexplorer/components/SpendByResources")
        ),
        layout: "/app",
        suppress: false,
      },
      {
        path: "/cloud/cost/spend-by-tags-report",
        name: "Spend By Tags",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.SPEND_VIEW],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() =>
          import("./costexplorer/components/SpendByTagsReport")
        ),
        layout: "/app",
        suppress: false,
      },
      {
        path: "/cloud/savings/dashboard",
        name: "Cloud Savings Dashboard",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.SPEND_VIEW],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() =>
          import("./costexplorer/dashboard/cost-savings-dashboard")
        ),
        layout: "/app",
        suppress: true,
      },
    ],
  },
  {
    group: "Security",
    envs: [Enum.Env.ALL],
    policy: [Enum.Policies.SECURITY_VIEW, Enum.Policies.MALWARE_VIEW],
    clouds: [Enum.CloudCategory.PUBLIC_CLOUD, Enum.CloudCategory.VMWARE],
    icon: "far fa-shield-alt",
    routes: [
      {
        path: "/anti-malware/events",
        name: "Anti Malware",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        policy: [Enum.Policies.MALWARE_VIEW],
        integrations: [Enum.Integration.TRENDMICRO],
        rtlName: "لوحة القيادة",
        icon: "fas fa-shield-alt",
        component: lazy(() =>
          import("./components/TrendMicro/TrendMicroDetail.jsx")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "anti-malware-events",
          event_data: {
            category: "security",
          },
        },
      },
      {
        path: "/cloud/aws-security-advisor",
        name: "AWS Security",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.SECURITY_VIEW],
        clouds: [Enum.CloudCategory.AWS],
        rtlName: "ار تي ال",
        icon: "fas fa-shield-alt",
        props: { cloudType: "AWS" },
        component: lazy(() =>
          import("./public_cloud/components/SecurityAdvisor")
        ),
        layout: "/app",
        key: "aws-security-advisor",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "aws-security-advisor",
          event_data: {
            category: "cloud",
          },
        },
      },
      {
        path: "/cloud/azure-security-advisor",
        name: "Azure Security",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.SECURITY_VIEW],
        clouds: [Enum.CloudCategory.AZURE],
        rtlName: "ار تي ال",
        icon: "fas fa-shield-alt",
        props: { cloudType: "AZURE" },
        component: lazy(() =>
          import("./public_cloud/components/SecurityAdvisor")
        ),
        layout: "/app",
        key: "security-advisor",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "azure-security-advisor",
          event_data: {
            category: "cloud",
          },
        },
      },
    ],
  },
  {
    group: "Compliance",
    envs: [Enum.Env.ALL],
    policy: [Enum.Policies.COMPLIANCE_VIEW],
    clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
    icon: "far fa-user-shield",
    routes: [
      {
        path: "/compliance",
        name: "Compliance Management",
        policy: [Enum.Policies.COMPLIANCE_VIEW],
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
        icon: "tim-icons icon-chart-pie-36",
        component: lazy(() =>
          import("./components/Compliance/CompliancePage.jsx")
        ),
        layout: "/app",
        suppress: false,
      },
    ],
  },

  {
    group: "Notifications",
    policy: [Enum.Policies.NOTIFICATIONS_VIEW],
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "far fa-bell",
    routes: [
      {
        path: "/notifications",
        name: "Notification Explorer",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.NOTIFICATIONS_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() =>
          import("./components/Notifications/NotificationsPage")
        ),
        layout: "/app",
        exact: true,
      },
    ],
  },
  {
    group: "Notifications",
    policy: [Enum.Policies.LIGHT_EDGE_NOTIFICATIONS_VIEW],
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "far fa-bell",
    routes: [
      {
        path: "/lightedge-notifications/:id",
        name: "LightEdge Notifications",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.NOTIFICATIONS_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() =>
          import(
            "./components/LightEdgeNotifications/LightEdgeNotifications.js"
          )
        ),
        layout: "/app",
        exact: true,
      },
    ],
  },
  {
    group: "Orders",
    policy: [Enum.Policies.NOTIFICATIONS_VIEW],
    envs: [Enum.Env.DEV],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "far fa-receipt",
    routes: [
      {
        path: "/orders",
        name: "Orders Explorer",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.NOTIFICATIONS_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() => import("./components/Orders/Orders")),
        layout: "/app",
        exact: true,
      },
      {
        path: "/order/:id",
        name: "Orders Explorer",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.NOTIFICATIONS_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() => import("./components/Orders/OrderDetail")),
        layout: "/app",
        exact: true,
        suppress: true,
      },
    ],
  },
  {
    group: 'Runbooks',
    envs: [Enum.Env.DEV, Enum.Env.LOCAL],
    clouds: [Enum.CloudCategory.ALL],
    policy: [Enum.Policies.RUNBOOKS_VIEW],
    rtlName: "لوحة القيادة",
    icon: "far fa-book-spells",
    routes: [
      {
        path: "/runbooks",
        name: "Runbook Management",
        policy: [Enum.Policies.RUNBOOKS_VIEW],
        envs: [Enum.Env.DEV, Enum.Env.LOCAL],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() =>
          import("./components/RunbookManagement/dashboard/RunbookListPage")
        ),
        layout: "/app",
        exact: true,
      },
      {
        path: "/runbook/new",
        name: "Create Runbook",
        policy: [Enum.Policies.RUNBOOKS_VIEW],
        envs: [Enum.Env.DEV, Enum.Env.LOCAL],
        clouds: [Enum.CloudCategory.ALL],
        rtlName: "لوحة القيادة",
        component: lazy(() =>
          import("./components/RunbookManagement/detail/RunbookDetailPage")
        ),
        layout: "/app",
        exact: true,
        suppress: true,
      },
      {
        path: "/runbook/:id",
        name: "Runbook Detail",
        policy: [Enum.Policies.RUNBOOKS_VIEW],
        envs: [Enum.Env.DEV, Enum.Env.LOCAL],
        clouds: [Enum.CloudCategory.ALL],
        rtlName: "لوحة القيادة",
        component: lazy(() =>
          import("./components/RunbookManagement/detail/RunbookDetailPage")
        ),
        layout: "/app",
        suppress: true,
      },
    ],
  },
  // {
  //   path: "/cloud/bots_create",
  //   name: "Bot Factory",
  //   envs: [Enum.Env.ALL],
  //   clouds: [Enum.CloudCategory.PUBLIC_CLOUD],
  //   rtlName: "لوحة القيادة",
  //   icon: "fas fa-robot",
  //   props: {url: _legacyBotCreateUrl},
  //   component: LegacyFrame,
  //   iframe: true,
  //   layout: "/app",
  //   suppress: true
  // },
  {
    group: "Automation",
    envs: [Enum.Env.ALL],
    policy: [Enum.Policies.AUTOMATION_VIEW],
    clouds: [Enum.CloudCategory.ALL],
    icon: "fas fa-life-ring",
    resourceProperty: "autoops",
    routes: [
      {
        path: "/automation/ops",
        name: "Auto Ops",
        policy: [Enum.Policies.AUTOMATION_VIEW],
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() => import("./components/BotsAutomation/OpsList")),
        layout: "/app",
        exact: true,
      },
      {
        path: "/automation/ops/new",
        name: "Ops Configuration",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.AUTOMATION_MANAGE],
        clouds: [Enum.CloudCategory.ALL],
        suppress: true,
        exact: true,
        component: lazy(() =>
          import("./components/BotsAutomation/OpsConfigurationForm")
        ),
        layout: "/app",
      },
      {
        path: "/automation/ops/:id",
        name: "Ops Configuration",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.AUTOMATION_MANAGE],
        clouds: [Enum.CloudCategory.ALL],
        suppress: true,
        component: lazy(() =>
          import("./components/BotsAutomation/OpsDetailPage")
        ),
        layout: "/app",
      },
    ],
  },
  {
    group: "Report (Beta)",
    policy: [Enum.Policies.REPORT_VIEW],
    // roles: [Enum.Roles.superAdmin],
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "far fa-file-spreadsheet",
    routes: [
      {
        path: "/environment-report",
        name: "Environment Report",
        policy: [Enum.Policies.REPORT_VIEW],
        // roles: [Enum.Roles.superAdmin],
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() =>
          import("./components/EnvironmentReport/EnvironmentReportPage")
        ),
        layout: "/app",
        exact: true,
      },
    ],
  },
  {
    group: "Ticketing & Support",
    policy: [Enum.Policies.TICKETS_VIEW, Enum.Policies.AWS_SUPPORT_TICKETS],
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    icon: "fal fa-headset",
    routes: [
      {
        path: "/support",
        name: "Support and Ticketing",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        policy: [Enum.Policies.TICKETS_VIEW],
        rtlName: "لوحة القيادة",
        icon: "fas fa-life-ring",
        component: lazy(() =>
          import(
            "./components/ConnectriaTickets/ConnectriaTicketsSupportDashboard.jsx"
          )
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "ticketing-dashboard",
          event_data: {
            category: "support",
          },
        },
      },
      {
        path: "/aws-support",
        name: "AWS Support Tickets",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.AWS],
        policy: [Enum.Policies.AWS_SUPPORT_TICKETS],
        rtlName: "لوحة القيادة",
        icon: "fas fa-ticket",
        component: lazy(() =>
          import("./components/AWSSupportTickets/AWSSupport.jsx")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "aws-support-tickets",
          event_data: {
            category: "support",
          },
        },
      },
      {
        path: "/view/ticket/:id",
        name: "Ticketing Detail",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.TICKETS_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        suppress: true,
        component: lazy(() =>
          import("./components/ConnectriaTickets/ConnectriaTicketFullPage")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "ticketing-detail-view",
          event_data: {
            category: "support",
          },
        },
      },
      {
        path: "/create-ticket",
        name: "Create Ticket",
        envs: [Enum.Env.ALL],
        policy: [Enum.Policies.TICKETS_VIEW],
        clouds: [Enum.CloudCategory.ALL],
        suppress: true,
        component: lazy(() =>
          import("./components/ConnectriaTickets/ConnectriaNewTicketFullPage")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "create-ticket-view",
          event_data: {
            category: "support",
          },
        },
      },
      // {
      //   path: "/cloud/aws-tickets",
      //   name: "AWS Support Tickets",
      //   envs: [Enum.Env.ALL],
      //   clouds: [Enum.CloudCategory.AWS],
      //   rtlName: "ار تي ال",
      //   icon: "fas fa-list",
      //   component: AWSTickets,
      //   layout: "/app",
      //   key: "aws-tickets",
      //   clickstreamMeta: {
      //     "event_id": "visited",
      //     "event_target": "aws-tickets",
      //     "event_data": {
      //       "category": "aws"
      //     }
      //   }
      // },
      {
        path: "/tickets",
        name: "Ticketing",
        policy: [Enum.Policies.TICKETS_VIEW],
        envs: [Enum.Env.DEV],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() =>
          import("./components/ConnectriaTickets/TicketsPage.jsx")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "ticketing-page",
          event_data: {
            category: "support",
          },
        },
      },
    ],
  },
    {
    group: "Contact Us LE",
    envs: [Enum.Env.DEV],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fal fa-phone-volume",
    routes: [
      {
        path: "/contact",
        name: "Contact Us",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() =>
          import("./components/Contact/ContactDashboard.jsx")
        ),
        layout: "/app",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "contact-us",
          event_data: {
            category: "tria",
            source: "sidebar",
          },
        },
      },
    ],
  },
  {
    group: "Contact Us",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    rtlName: "لوحة القيادة",
    icon: "fal fa-phone-volume",
    routes: [
      {
        path: "/contact",
        name: "Contact Us",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() => import('./components/ContactTab/ContactTab.jsx')),
        layout: '/app',
        clickstreamMeta: {
          event_id: "visited",
          event_target: "contact-us",
          event_data: {
            category: "tria",
            source: "sidebar",
          },
        },
      },
    ],
  },
  {
    group: "Accounts & Settings",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    icon: "fal fa-user-friends",
    routes: [
      {
        url: ACCOUNTS_URL + "/account/profile",
        name: "Profile",
        key: "profile",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        isExternal: true,
      },
      {
        path: "/invoices",
        policy: [Enum.Policies.BILLING_VIEW],
        name: "Invoices",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        component: lazy(() => import("./components/Invoices/InvoicesPage")),
        layout: "/app",
        key: "invoices",
        clickstreamMeta: {
          event_id: "visited",
          event_target: "invoices",
          event_data: {
            category: "invoices",
          },
        },
      },
      {
        url: ACCOUNTS_URL + "/account/organization",
        name: "Organization",
        key: "organization",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        isExternal: true,
      },
      // {
      //   url: ACCOUNTS_URL + '/account/members',
      //   name: 'People',
      //   key: 'members',
      //   envs: [Enum.Env.ALL],
      //   clouds: [Enum.CloudCategory.ALL],
      //   isExternal: true
      // }
    ],
  },
  {
    group: "Docs & References",
    envs: [Enum.Env.ALL],
    clouds: [Enum.CloudCategory.ALL],
    icon: "fal fa-book-spells",
    routes: [
      {
        url: "https://docs.next-gen.tria.connectria.com",
        name: "Documentation",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        icon: "fal fa-external-link-alt",
        isExternal: true,
        clickstreamMeta: {
          event_id: "clicked",
          event_target: "documentation",
          event_data: {
            category: "tria",
            source: "sidebar",
          },
        },
      },
      {
        url: "https://www.connectria.com/terms-conditions",
        name: "Legal",
        envs: [Enum.Env.ALL],
        clouds: [Enum.CloudCategory.ALL],
        icon: "fal fa-external-link-alt",
        isExternal: true,
        clickstreamMeta: {
          event_id: "clicked",
          event_target: "terms-conditions",
          event_data: {
            category: "tria",
            source: "sidebar",
          },
        },
      },
    ],
  },
  // {
  //   name: "Cloud properties export",
  //   path: "/admin-tools/export-cloud-properties",
  //   component: lazy(() =>
  //     import("./components/AdminTools/ExportCloudProperties")
  //   ),
  //   envs: [Enum.Env.ALL],
  //   clouds: [Enum.CloudCategory.ALL],
  //   icon: "fal fa-cloud-download",
  //   layout: "/app",
  //   exact: true,
  //   suppress: true,
  // },
];

const dashBoardConfigIbmi = [
  {
    equalHeightCards: false,

    cards: [
      {
        className: 'col-md-12 d-none d-md-block',
        title: 'Resource Management',
        noCard: true,
        components: [
          <FeatureToggle
            policies={[Enum.Policies.RESOURCES_VIEW]}
            clouds={[Enum.CloudCategory.ALL]}
            envs={[Enum.Env.ALL]}
          >
            <ResourcesOverview></ResourcesOverview>
          </FeatureToggle>,
        ],
      },
    ],
  },
  {
    equalHeightCards: false,
    policies: [Enum.Policies.TICKETS_VIEW],
    cards: [
      {
        className: 'col-md-8 d-none d-md-block',
        title: 'Ticketing',
        components: [
          <FeatureToggle
            policies={[Enum.Policies.TICKETS_VIEW]}
            envs={[Enum.Env.ALL]}
            clouds={[Enum.CloudCategory.ALL]}
          >
            <ConnectriaTicketsDashboardTab></ConnectriaTicketsDashboardTab>
          </FeatureToggle>,
        ],
      },
      {
        nestedConfig: [
          {
            className: 'col-md-4',
            equalHeightCards: false,
            cards: [
              {
                className: 'col-md-12',
                title: 'Security & Compliance at a Glance',
                components: [
                  <FeatureToggle
                    policies={[Enum.Policies.SECURITY_VIEW]}
                    envs={[Enum.Env.ALL]}
                    clouds={[Enum.CloudCategory.ALL]}
                    integrations={[Enum.Integration.TRENDMICRO]}
                  >
                    <TrendMicroWidget></TrendMicroWidget>
                  </FeatureToggle>,
                ],
              },
            ],
          },
          {
            equalHeightCards: false,

            cards: [
              {
                className: 'col-md-12',
                title: 'Operation & Performance at a Glance',
                components: [
                  <FeatureToggle
                    policies={[Enum.Policies.VIEW_LPAR]}
                    clouds={[Enum.CloudCategory.IBMI]}
                    envs={[Enum.Env.ALL]}
                  >
                    <IBMiLparMonitorDesktopWidget></IBMiLparMonitorDesktopWidget>
                  </FeatureToggle>,
                  <FeatureToggle
                    policies={[Enum.Policies.MANAGED_DEVICE_VIEW]}
                    clouds={[Enum.CloudCategory.ALL]}
                    envs={[Enum.Env.ALL]}
                  >
                    <DevicesWidget></DevicesWidget>
                  </FeatureToggle>,
                  <FeatureToggle
                    policies={[Enum.Policies.VIEW_BACKUP]}
                    clouds={[Enum.CloudCategory.VMWARE]}
                    envs={[Enum.Env.ALL]}
                  >
                    <BackupsDashboardCard />
                  </FeatureToggle>,
                ],
              },
            ],
          },
          {
            equalHeightCards: false,
            cards: [
              {
                className: 'col-sm-12',
                noCard: true,
                components: [
                  <FeatureToggle policies={[]} clouds={[Enum.CloudCategory.ALL]} envs={[Enum.Env.ALL]}>
                    <UpsellCardContainer />
                  </FeatureToggle>,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const dashBoardConfig = [
  {
    equalHeightCards: false,

    cards: [
      {
        className: 'col-md-12 d-none d-md-block',
        title: 'Resource Management',
        noCard: true,
        components: [
          <FeatureToggle
            policies={[Enum.Policies.RESOURCES_VIEW]}
            clouds={[Enum.CloudCategory.ALL]}
            envs={[Enum.Env.ALL]}
          >
            <ResourcesOverview></ResourcesOverview>
          </FeatureToggle>,
        ],
      },
    ],
  },
  {
    equalHeightCards: true,

    cards: [
      {
        className: 'col-sm-12 col-lg-4',
        title: 'Cost Optimization at a Glance',

        components: [
          <FeatureToggle
            policies={[Enum.Policies.SPEND_VIEW]}
            clouds={[Enum.CloudCategory.PUBLIC_CLOUD]}
            envs={[Enum.Env.ALL]}
          >
            <CostSavingsCard></CostSavingsCard>
          </FeatureToggle>,
        ],
      },
      {
        className: 'col-sm-12 col-lg-4',
        title: 'Security & Compliance at a Glance',

        components: [
          <FeatureToggle
            policies={[Enum.Policies.COMPLIANCE_VIEW]}
            envs={[Enum.Env.ALL]}
            clouds={[Enum.CloudCategory.PUBLIC_CLOUD]}
          >
            <ComplianceDashboardCard />
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.MALWARE_VIEW]}
            envs={[Enum.Env.ALL]}
            clouds={[Enum.CloudCategory.ALL]}
            integrations={[Enum.Integration.TRENDMICRO]}
          >
            <TrendMicroWidget></TrendMicroWidget>
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.SECURITY_VIEW]}
            envs={[Enum.Env.ALL]}
            clouds={[Enum.CloudCategory.AWS]}
          >
            <SecurityAdvisorWidget cloudType="AWS"></SecurityAdvisorWidget>
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.SECURITY_VIEW]}
            envs={[Enum.Env.ALL]}
            clouds={[Enum.CloudCategory.AZURE]}
          >
            <SecurityAdvisorWidget cloudType="AZURE"></SecurityAdvisorWidget>
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.VIEW_LPAR]}
            envs={[Enum.Env.DEV, Enum.Env.DEMO]}
            clouds={[Enum.CloudCategory.IBMI]}
          >
            <IBMCartCard></IBMCartCard>
          </FeatureToggle>,
        ],
      },
      {
        className: 'col-sm-12 col-lg-4',
        title: 'Operation & Performance at a Glance',

        components: [
          <FeatureToggle
            policies={[Enum.Policies.VIEW_CLOUD_MON]}
            clouds={[Enum.CloudCategory.PUBLIC_CLOUD]}
            envs={[Enum.Env.ALL]}
            integrations={[Enum.Integration.BLUEMATADOR]}
          >
            <BlueMatadorWidget></BlueMatadorWidget>
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.VIEW_CLOUD_ALARMS]}
            clouds={[Enum.CloudCategory.AWS]}
            envs={[Enum.Env.ALL]}
            integrations={[Enum.Integration.CLOUDWATCH]}
          >
            <CloudWatchAlarmsWidget></CloudWatchAlarmsWidget>
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.VIEW_CLOUD_ALARMS]}
            clouds={[Enum.CloudCategory.AZURE]}
            envs={[Enum.Env.ALL]}
          >
            <AzureAlertsWidget></AzureAlertsWidget>
          </FeatureToggle>,
          <FeatureToggle policies={[Enum.Policies.VIEW_LPAR]} clouds={[Enum.CloudCategory.IBMI]} envs={[Enum.Env.ALL]}>
            <IBMiLparMonitorDesktopWidget></IBMiLparMonitorDesktopWidget>
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.MANAGED_DEVICE_VIEW]}
            clouds={[Enum.CloudCategory.ALL]}
            envs={[Enum.Env.ALL]}
          >
            <DevicesWidget></DevicesWidget>
          </FeatureToggle>,
          <FeatureToggle
            policies={[Enum.Policies.VIEW_BACKUP]}
            clouds={[Enum.CloudCategory.VMWARE]}
            envs={[Enum.Env.ALL]}
          >
            <BackupsDashboardCard />
          </FeatureToggle>,
        ],
      },
    ],
  },
  {
    equalHeightCards: false,

    cards: [
      {
        className: 'col-lg-8 d-none d-lg-block',
        title: 'Ticketing',
        components: [
          <FeatureToggle
            policies={[Enum.Policies.TICKETS_VIEW]}
            envs={[Enum.Env.ALL]}
            clouds={[Enum.CloudCategory.ALL]}
          >
            <ConnectriaTicketsDashboardTab></ConnectriaTicketsDashboardTab>
          </FeatureToggle>,
        ],
      },
      {
        nestedConfig: [
          // {
          //   className: "col-sm-12 col-lg-4",
          //   equalHeightCards: false,
          //   cards: [
          //     {
          //       className: "col-sm-12",
          //       title: "Automations",
          //       components: [
          //         <FeatureToggle clouds={[Enum.CloudCategory.PUBLIC_CLOUD]} envs={[Enum.Env.ALL]}>
          //           <div>
          //             <h3 className="text-left">Bot Factory</h3>
          //             <ul className="list-unstyled text-center">
          //               <li><a href="/app/cloud/bots_create" className="btn btn-link" style={{fontSize:"15px"}}><i className="fas fa-plus-square"></i> Create Bot</a></li>
          //               <li><a href="/app/cloud/bots_listing" className="btn btn-link" style={{fontSize:"15px"}}><i className="fas fa-list"></i> Bot Listing</a></li>
          //               <li><a href="/app/cloud/bots_templates" className="btn btn-link" style={{fontSize:"15px"}}><i className="fas fa-ruler-combined"></i> Templates</a></li>
          //             </ul>
          //           </div>
          //         </FeatureToggle>
          //       ]
          //     }
          //   ]
          // },
          {
            equalHeightCards: false,
            className: 'col-sm-12 col-lg-4',
            cards: [
              {
                className: 'col-sm-12',
                noCard: true,
                components: [
                  <FeatureToggle policies={[]} clouds={[Enum.CloudCategory.ALL]} envs={[Enum.Env.ALL]}>
                    <UpsellCardContainer />
                  </FeatureToggle>,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export { routes, dashBoardConfig, dashBoardConfigIbmi };
