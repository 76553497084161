const ChatService = {

    getChatCustomer: function (username) {
        console.log('chat service');
        console.log(username);
        let customer_chat_lookup = {
            'josbornibmi': "T3U2SMKHAGRYFL5B8X",
            'Sean Alsup': "T099JFQ3TGRK5H081Z",
            'seanalsup314': "T099JFQ3TGRK5H081Z"
        }
        if (customer_chat_lookup[username]) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `https://embed.small.chat/${customer_chat_lookup[username]}.js`;

            setTimeout(function () {
                document.head.appendChild(script);
            }, 3000);
            script.onload = function () {
                var styles = document.createElement('link');
                styles.rel = 'stylesheet';
                styles.href = 'https://static.small.chat/messenger.css';
                document.head.appendChild(styles);
                var script = document.createElement('script');
                script.async = true;
                script.src = 'https://static.small.chat/messenger.js';
                document.body.appendChild(script);
            }
        }
    }

};

export default ChatService;