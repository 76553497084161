import React from "react";
import { useState } from "react";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
import { appState } from "../../AppState";
import moment from "moment";
// reactstrap components
import { Card, CardBody, Button, Input, Label, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

moment.locale('en')
momentLocalizer()
import 'react-widgets/dist/css/react-widgets.css';
import { useEffect } from "react";


const AdvancedTicketSearch = (props) => {
  const {inboxFilterState, setInboxFilterState} = props;
  const [filterState, setFilterState] = useState({})
  const [params, setParams] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uniqueCategoryNames, setUniqueCategoryNames] = useState([]);
  const [users, setUsers] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [dateRange, setDateRange] = useState({param: "", start: null, end: null});
  const [dateRangeIsFull, setDateRangeIsFull] = useState(false);
  const [searchComments, setSearchComments] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stateApp, stateAppActions] = appState();

  const source = axios.CancelToken.source();

  useAsyncEffect(
    async isMounted => {
      try {
        let isCustomer = stateApp.userInfo.organization_id != 0;

        let [categories, users, ticketTypes, priorities] = await Promise.all([
          ConnectriaTicketsService.ticketCategories({}),
          ConnectriaTicketsService.getAllUsersLike({}, isCustomer),
          ConnectriaTicketsService.ticketTypes({ cancelToken: source.token }),
          ConnectriaTicketsService.ticketPriorities()
        ]);

        if (!isMounted()) return;

        let uniqueCategoryNames = [...new Set(categories.map(category => { return category.name }))];
        setCategories(categories);
        setUniqueCategoryNames(uniqueCategoryNames);
        setUsers(users);
        setTicketTypes(ticketTypes.filter(e => e.name != 'Problem'));
        setPriorities(priorities);
        setIsLoading(false);

      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          setIsLoading(false);
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  useEffect(() => {
    if (params.length > 0) {
      setShowFilters(true);
    }
  }, [filterState])

  // Add the date range to the param list
  useEffect(() => {
    if (dateRange.param != "" && dateRange.start != null && dateRange.end != null) {
      let start = moment(dateRange.start).format("YYYY-MM-DD");
      let end = moment(dateRange.end).format("YYYY-MM-DD");
      // Filter out current date param, we can only use one date range currently
      let newParams = params.filter(param => param.key != "dateRange");
      setParams([
        ...newParams,
        {key: "dateRange", value: "dateRange", description: <><b className="pr-1">{dateRange.label}</b> between {start} - {end}</>}
      ])
      setDateRangeIsFull(true);
    } else {
      setDateRangeIsFull(false);
    }
  }, [dateRange]);

  // Remove the date range param in the list if the date range isn't fully filled out
  useEffect(() => {
    if (!dateRangeIsFull) {
      setParams([ ...params.filter(param => param.key != "dateRange") ])
    }
  }, [dateRangeIsFull])

  const removeParam = (key, value, i) => {
    let newParams = params;
    let newFilterState = filterState;
    if (Array.isArray(value)) {
      value = value[0]
      newFilterState[key] = newFilterState[key].filter(v => !value.includes(v));
    } else {
      newFilterState[key] = newFilterState[key].filter(v => v != value );
    }

    newParams.splice(i, 1);

    if (newParams.length == 0) {
      setShowFilters(false);
    }

    setParams([...newParams]);
    setFilterState({...newFilterState});
  }

  const resetParams = () => {
    setFilterState({});
    setInboxFilterState({});
    setDateRange({param: "", start: null, end: null});
    setParams([]);
  }

  const handleStatusChange = (e) => {
    let statusCustomerName = e.target.value == "WaitOnCust" ? "Input Required" : e.target.value;
    if (!filterState?.status?.includes(e.target.value)) {
      setFilterState({...filterState, status: [...filterState.status ?? [], e.target.value]});
      setParams([
        ...params,
        {key: "status", value: e.target.value, description: <><b className="pr-1">Ticket Status</b> like "{statusCustomerName}"</>}
      ])
    }
  }
  const handleUserChange = (e) => {
    let index = e.target.value;
    let user = users[index];
    if (!filterState?.user_id?.includes(user.id)) {
      setFilterState({...filterState, user_id: [...filterState.user_id ?? [], user.id] });
      setParams([
        ...params,
        {key: "user_id", value: user.id, description: <><b className="pr-1">User Login</b> like "{user.login}"</>}
      ])
    }
  }
  const handleTicketTypeChange = (e) => {
    let index = e.target.value;
    let ticketType = ticketTypes[index];
    if (!filterState?.ticket_type_id?.includes(ticketType.id)) {
      setFilterState({...filterState, ticket_type_id: [...filterState.ticket_type_id ?? [], ticketType.id] });
      setParams([
        ...params,
        {key: "ticket_type_id", value: ticketType.id, description: <><b className="pr-1">Ticket Type</b> like "{ticketType.name}"</>}
      ])
    }
  }
  const handlePriorityChange = (e) => {
    let index = e.target.value;
    let priority = priorities[index];
    if (!filterState?.priority?.includes(priority.id)) {
      setFilterState({...filterState, priority: [...filterState.priority ?? [], priority.id] });
      setParams([
        ...params,
        {key: "priority", value: priority.id, description: <><b className="pr-1">Ticket Type</b> like "{priority.name}"</>}
      ])
    }
  }
  const handleCategoryChange = (e) => {
    let categoryName = e.target.value;
    let categoryIds = categories
      .filter(category => category.name == categoryName)
      .map(category => category.id);
    let filterIds = filterState.ticketcategory_id ?? [];
    for (var id of categoryIds) {
      if (!filterState?.ticketcategory_id?.includes(id)) {
        filterIds.push(id);
      }
    }
    setFilterState({...filterState, ticketcategory_id: filterIds });
    let newParam = {key: "ticketcategory_id", value: [categoryIds], description: <><b className="pr-1">Category</b> like "{categoryName}"</>};
    let identicalParam = params.find(param => JSON.stringify(param) == JSON.stringify(newParam));
    if (!identicalParam) {
      setParams([...params, newParam])
    }
  }
  const handleSubCategoryChange = (e) => {
    let categoryId = parseInt(e.target.value);
    let subcategory = categories.find(category => category.id == categoryId );
    if (!filterState?.ticketcategory_id?.includes(categoryId)) {
      setFilterState({...filterState, ticketcategory_id: [...filterState.ticketcategory_id ?? [], subcategory.id] });
      setParams([
        ...params,
        {key: "ticketcategory_id", value: subcategory.id, description: <><b className="pr-1">Sub-Category</b> like "{subcategory.name}: {subcategory.subcategory}"</>}
      ])
    }
  }

  const handleDateChange = (e) => {
    let param = e.target.value;
    let label = "";
    switch(param) {
      case "created":
        label = "Created";
        break;
      case "lastUpdate":
        label = "Updated";
        break;
      case "cancelled_on":
        label = "Cancelled";
        break;
      case "resolved_on":
        label = "Resolved";
        break;
      case "closed_on":
        label = "Closed";
        break;
      default:
        label = "";
    }
    setDateRange({...dateRange, param: param, label: label})
  }

  const handleKeywordChange = (e) => {
    e.preventDefault();
    if (!filterState?.keywords?.includes(searchString)) {
      setFilterState({...filterState, keyword: [...filterState.keyword ?? [], searchString] });
      setParams([
        ...params,
        {key: "keyword", value: searchString, description: <><b className="pr-1">Keyword</b> like "{searchString}"</>}
      ])
    }
    setSearchString("");
  }
  const submit = (e) => {
    e.preventDefault();
    let fullFilterState = {...filterState};
    if (!(dateRange.param == "" || dateRange.start == null || dateRange.end == null)) {
      fullFilterState[dateRange.param] = [
        moment(dateRange.start).format("YYYY-MM-DD"),
        moment(dateRange.end).format("YYYY-MM-DD")
      ];
    }
    if (searchComments) {
      fullFilterState.searchComments = true;
    }
    // Set the actual filter for the ticketing inbox. Go get tickets
    setInboxFilterState(fullFilterState);
  }

  const toggleSearchComments = (e) => {
    setSearchComments(!searchComments);
  }

  return (
    <>
    <SearchTicketCommentsWarning searchTicketComments={searchComments}/>
    {isLoading ?
    <div className="text-center">
      <i className="fas fa-spinner m-3 mt-4 fa-spin" style={{ fontSize: "30px" }}></i>
    </div> :
    <div>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-8" style={{maxWidth: "70%", flex: "0 0 70%"}}>
                <Label className="m-0">Keyword</Label>
                <form onSubmit={handleKeywordChange}>
                  <Input id="aKeyword" placeholder="Search subject or description" value={searchString} onChange={(e) => { setSearchString(e.target.value) }}></Input>
                </form>
                </div>
                <div className="col-4" style={{maxWidth: "30%", flex: "0 0 30%"}}>
                  <Button id="aAddKeyword" size="sm" className="keyword-search-btn" disabled={searchString.length == 0} onClick={handleKeywordChange} style={{marginTop: "1.6rem", maxWidth: "100%"}}>
                    Add Keyword
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-1">
            <div className="col-3">
              <Label className="m-0" for="startDatePicker">Date</Label>
              <Input type="select" id="aDatePicker" bsSize="sm" value={dateRange.param} onChange={handleDateChange}>
                <option value=""></option>
                <option value="created">Created</option>
                <option value="lastUpdate">Updated</option>
                <option value="cancelled_on">Cancelled</option>
                <option value="resolved_on">Resolved</option>
                <option value="closed_on">Closed</option>
              </Input>
            </div>
            <div className="col" style={{paddingTop: "1.25rem"}}>
              <DateTimePicker
                id="startDatePicker"
                format={'YYYY-MM-DD'}
                value={dateRange.start}
                max={new Date()}
                time={false}
                placeholder={"YYYY-MM-DD"}
                onChange={(value) => { setDateRange({...dateRange, start: value == "Invalid Date" ? "" : value}) }}/>
            </div>
            <div className="col" style={{paddingTop: "1.25rem"}}>
              <DateTimePicker
                id="endDatePicker"
                format={'YYYY-MM-DD'}
                value={dateRange.end}
                min={dateRange.start}
                max={new Date()}
                time={false}
                placeholder={"YYYY-MM-DD"}
                onChange={(value) => { setDateRange({...dateRange, end: value == "Invalid Date" ? "" : value}) }}/>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="row">
            <div className="col-4">
              <Label className="m-0">Status</Label>
              <Input type="select" id="aStatus" value="" onChange={handleStatusChange}>
                <option disabled></option>
                <option value="New">New</option>
                <option value="Open">Open</option>
                <option value="WaitOnCust">Input Required</option>
                <option value="Scheduled">Scheduled</option>
                <option value="Resolved">Resolved</option>
                <option value="Closed">Closed</option>
                <option value="Suspended">Suspended</option>
                <option value="Cancelled">Cancelled</option>
              </Input>
            </div>
            <div className="col-4">
              <Label className="m-0">Owner</Label>
              <Input type="select" id="aOwner" value="" onChange={handleUserChange}>
                <option disabled></option>
                {users.map((user, i) => {
                  return <option value={i} key={i}>{user.login}</option>
                })}
              </Input>
            </div>
            <div className="col-4">
              <Label className="m-0">Ticket Type</Label>
              <Input type="select" id="aTicketType" value="" onChange={handleTicketTypeChange}>
                <option disabled></option>
                {ticketTypes.map((type, i) => {
                  return <option value={i} key={i}>{type.name}</option>
                })}
              </Input>
            </div>
          </div>
          <div className="row pt-1">
            <div className="col-4">
              <Label className="m-0">Current Priority</Label>
              <Input type="select" id="aCurrentPriority" value="" onChange={handlePriorityChange}>
                <option disabled></option>
                {priorities.map((priority, i) => {
                  return <option value={i} key={i}>{priority.name}</option>
                })}
              </Input>
            </div>
            <div className="col-4">
              <Label className="m-0">Category</Label>
              <Input type="select" id="aCategory" value="" onChange={handleCategoryChange}>
                <option disabled></option>
                {[...new Set(categories.map(category => category.name ))].map(category => {
                  return <option value={category.name}>{category}</option>
                })}
              </Input>
            </div>
            <div className="col-4">
              <Label className="m-0">Sub-Category</Label>
              <Input type="select" id="aSubCategory" value="" onChange={handleSubCategoryChange}>
                <option disabled></option>
                {uniqueCategoryNames.map((categoryName, i) =>
                  <optgroup key={i} label={categoryName}>
                    {[...categories]
                      .sort((a, b) => a.subcategory.localeCompare(b.subcategory))
                      .map((category, i) => {
                        return category.name == categoryName && <option key={i} value={category.id}>{category.subcategory}</option>
                      })
                    }
                  </optgroup>
                )}
              </Input>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pt-2">
          <Button id="aShowFilters" size="sm" onClick={() => setShowFilters(!showFilters)}>Show Filters ({params.length})</Button>
        <div className="row d-flex">
          <div className="inline form-check pr-2 hide-sm">
            <label className="form-check-label">
              <nobr>Search Ticket Comments</nobr>
              <input
                type="checkbox"
                className="form-check-input"
                checked={searchComments}
                onChange={toggleSearchComments}
              />
              <span className="form-check-sign">
                <span className="check"></span>
              </span>
            </label>
          </div>
          <Button id="aReset" size="sm" onClick={resetParams}>Reset</Button>
          <Button id="aSubmit" size="sm" color="primary" className="mx-1" onClick={submit}>Submit</Button>
        </div>
      </div>
      <div className="inline form-check d-lg-none">
        <label className="form-check-label">
          <nobr>Search Ticket Comments</nobr>
          <input
            type="checkbox"
            className="form-check-input"
            checked={searchComments}
            onChange={toggleSearchComments}
          />
          <span className="form-check-sign">
            <span className="check"></span>
          </span>
        </label>
      </div>
      <Collapse isOpen={showFilters}>
        <Card style={{backgroundColor: "#e6e5e5"}}>
          <CardBody>
            {params.map((param, i) => {
              return (
                <div className="row">
                  <i className="fas fa-times text-danger cursor my-auto pr-1" onClick={() => { removeParam(param.key, param.value, i) }}></i>
                  {param.description}
                </div>
              )
            })}
          </CardBody>
        </Card>
      </Collapse>
    </div>}
    </>
  );
}

const SearchTicketCommentsWarning = (props) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(props.searchTicketComments);
  }, [props.searchTicketComments]);

  const toggle = () => setModal(!modal);

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader>Search Ticket Comments</ModalHeader>
      <ModalBody>
        Including ticket comments in your search parameters may cause extended delays in retrieving your information
      </ModalBody>
      <ModalFooter>
        <div></div>
        <Button className="right" color="secondary" onClick={toggle}>Ok</Button>
      </ModalFooter>
    </Modal>
  );
}

export { AdvancedTicketSearch };
