import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { appState } from "../../AppState";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
import { getReadableDate } from "../../utility/DateUtil";
import useAsyncEffect from "../../utility/use-async-effect";
import { AdvancedTicketSearch } from "./AdvancedTicketSearch";
import {
  NewTabLink,
  TicketBladeContainer,
} from "./ConnectriaTicketsBlade";
import useTriaTicketsState, { createdBy } from "./ConnectriaTicketsState";
// reactstrap components
import { Badge, FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import { CSVUtility } from "../../utility/table-to-csv";
import NewTicketButton from "../SupportAndTicketing/NewTicketButton";
import { ClipboardCopyButton } from "../misc";

import momentLocalizer from "react-widgets-moment";
import "react-widgets/dist/css/react-widgets.css";
import "../../assets/scss/search/SearchBar.scss";
import TicketService from "../../services/TicketService";
import YetiTickets from "../../services/YetiTickets";
import MultiProviderPriorityBadge from "./MultiProviderPriorityBadge";
import { PROVIDERS } from "./constants";

moment.locale("en");
momentLocalizer();

const TICKETPULLSIZE = 25;
// TODO: add LE status filters
const statusFilters = [
  ["Open", "Scheduled", "WaitOnCust", "New"],
  ["New"],
  ["Open"],
  ["WaitOnCust"],
  ["Scheduled"],
  ["Resolved"],
  ["Closed"],
  ["Suspended"],
  ["Cancelled"],
];

async function getTickets(offset, filterState, statusId, params = {}) {
  let filter = {
    limit: `${offset},${TICKETPULLSIZE}`,
    select:
      "tickets.id,subject,priority,created,status,user_id,ticket_type_id,lastUpdate",
    ar_assoc: "user",
  };

  filter.sort_by = "created desc";
  if (statusId === 0) {
    filter.sort_by =
      "case when status = 'New' then '1' " +
      "when status = 'Open' then '2' " +
      "when status = 'WaitOnCust' then '3' " +
      "when status = 'Scheduled' then '4' " +
      "else status end asc, created desc";
  }

  filter.status = statusFilters[statusId];

  if (filterState.filterMine) {
    let userId = await ConnectriaTicketsService.portalUserId(params);
    filter.user_id = userId;
  }

  let filters = [];
  if (filterState?.keyword?.length > 0) {
    for (var keyword of filterState.keyword) {
      filters = [
        ...filters,
        { ...filter, subject: keyword },
        { ...filter, issue: keyword },
      ];
    }
  } else {
    filters = [filter];
  }

  let promises = filters.map((filter) => {
    return ConnectriaTicketsService.getAllTicketsLike(filter, params);
  });

  let allTickets = await Promise.all(promises);
  let tickets = allTickets.flat();

  if (allTickets.length > 1) {
    // mergedArray have duplicates, lets remove the duplicates using Set
    tickets = removeDuplicateTickets(tickets);
  }

  return tickets;
}

async function getTicketsAdvancedSearch(
  offset,
  advancedFilterState,
  statusId,
  params = {}
) {
  let filter = {
    ...advancedFilterState,
    limit: `${offset},${TICKETPULLSIZE}`,
    select:
      "tickets.id,subject,priority,created,status,user_id,ticket_type_id,lastUpdate",
    ar_assoc: "user",
    sort_by: "created desc",
  };

  let keywords = filter.keyword;
  if (filter.keyword) {
    // Remove keyword param left over from advancedFilterState
    delete filter.keyword;
  }

  let searchComments = filter.searchComments;
  if (filter.searchComments) {
    filter.ar_assoc += ",comments";
    delete filter.searchComments;
    filter.sort_by = "tickets.created desc";
  }

  let filters = [];
  if (keywords?.length > 0) {
    for (var keyword of keywords) {
      filters = [
        ...filters,
        { ...filter, subject: keyword },
        { ...filter, issue: keyword },
      ];
      if (searchComments) {
        filters.push({
          ...filter,
          select:
            "tickets.id,subject,priority,tickets.created,tickets.status,tickets.user_id,ticket_type_id,lastUpdate",
          sort_by: "tickets.created desc",
          ticket_histories: { comment: keyword, pvt: 0 },
        });
      }
    }
  } else {
    filters = [filter];
  }

  let promises = filters.map((filter) => {
    return ConnectriaTicketsService.getAllTicketsLike(filter, params);
  });

  let allTickets = await Promise.all(promises);
  let tickets = allTickets.flat();

  if (allTickets.length > 1) {
    // mergedArray have duplicates, lets remove the duplicates using Set
    tickets = removeDuplicateTickets(tickets);
  }

  // all incoming tickets by their created time
  tickets = tickets.sort((a, b) => moment(b.created) - moment(a.created));

  return tickets;
}

function removeDuplicateTickets(tickets) {
  let set = new Set();
  let unionArray = tickets.filter((item) => {
    if (!set.has(item.id)) {
      set.add(item.id);
      return true;
    }
    return false;
  }, set);

  return unionArray.slice();
}

function showItemBlade(ticketNo, ticketProvider, stateAppActions) {
  stateAppActions.setBlade({
    title: (
      <span>
        {"#" + ticketNo}{" "}
        <ClipboardCopyButton
          value={`${window.location.origin}/app/view/ticket/${ticketNo}`}
          tooltipMessage={"Copy ticket link"}
          target="copy-ticket-link"
          alertMessage={`Ticket #${ticketNo} link copied.`}
        />
        <NewTabLink
          link={`/app/view/ticket/${ticketNo}`}
          tooltipMessage={"Open In New Tab"}
          target="open-new-tab-link"
          alertMessage={""}
        />
      </span>
    ),
    visible: true,
    content: (
      <TicketBladeContainer id={ticketNo} ticketProvider={ticketProvider} />
    ),
  });
}

const DEFAULT_STATUS = { color: "secondary", label: "Unknown Status" };
const STATUS_MAP_BY_PROVIDER = {
  [PROVIDERS.LIGHTEDGE]: {
    new: { color: "primary", label: "New" },
    in_progress: { color: "success", label: "In Progress" },
    awaiting_client: { color: "", label: "Awaiting Client" },
    closed: { color: "", label: "Closed" },
    assigned: { color: "primary", label: "Assigned" },
    awaiting_problem: { color: "primary", label: "Awaiting Problem" },
    awaiting_third_party: { color: "primary", label: "Awaiting Third Party" },
    resolved: { color: "success", label: "Resolved" },
    reopened: { color: "primary", label: "Reopened" },
    canceled: { color: "danger", label: "Canceled" },
  },
  [PROVIDERS.CONNECTRIA]: {
    new: { color: "primary", label: "New" },
    open: { color: "success", label: "Open" },
    waitoncust: { color: "danger", label: "Waiting on Customer" },
    scheduled: { color: "secondary", label: "Scheduled" },
    resolved: { color: "warning", label: "Resolved" },
    closed: { color: "", label: "Closed" },
  },
};

function StatusBadge({ provider = "", status = "" }) {
  const lowerCaseStatus = status.toLowerCase();
  // use TRIA as a fallback provider
  const providerStatusMap =
    STATUS_MAP_BY_PROVIDER[provider] ||
    STATUS_MAP_BY_PROVIDER[PROVIDERS.CONNECTRIA];
  // use default badge props as fallback
  const badgeProps = providerStatusMap[lowerCaseStatus] || DEFAULT_STATUS;

  return <Badge color={badgeProps.color}>{badgeProps.label || status}</Badge>;
}

function InboxItems(props) {
  const items = props.tickets;
  const [isLoading, setIsLoading] = useState(true);
  const [stateApp] = appState();
  const [, setTypes] = useState();
  const [, setPriorities] = useState();
  const source = axios.CancelToken.source();
  useAsyncEffect(
    async (isMounted) => {
      try {
        setIsLoading(true);
        let resp = await ConnectriaTicketsService.ticketTypes({
          cancelToken: source.token,
        });
        setPriorities(ConnectriaTicketsService.ticketPriorities());
        if (!isMounted()) return;
        setTypes(resp);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  if (isLoading) {
    return null;
  } else {
    return (
      <>
        {items.map((item, i) => {
          return (
            <button
              type="button"
              onClick={(e) =>
                e.target.className.includes("copy-clipboard")
                  ? null
                  : showItemBlade(
                    item.id,
                    item.provider,
                    props.stateAppActions
                  )
              }
              key={item.id}
              className={`mt-1 mb-1 list-group-item list-group-item-action pointer flex-column align-items-start ticket-${item.status}`}
            >
              <div className="d-flex w-100 justify-content-between">
                <div className="row">
                  <h5 className="mb-1 d-block d-sm-none">#{item.id}</h5>
                  <h4 className="mb-1 d-none d-md-block">#{item.id}</h4>
                  <ClipboardCopyButton
                    className="ml-1 mt-1"
                    value={`${window.location.origin}/app/view/ticket/${item.id}`}
                    tooltipMessage={"Copy ticket link"}
                    alertMessage={`Ticket #${item.id} link copied.`}
                    idx={i}
                  />
                  <NewTabLink
                    itemId={item.id}
                    link={`/app/view/ticket/${item.id}`}
                    tooltipMessage={"Open In New Tab"}
                    alertMessage={""}
                  />
                </div>
                <small className="d-block d-sm-none pull-right">
                  Updated:{" "}
                  {getReadableDate(
                    item.lastUpdate,
                    moment(
                      item.lastUpdate,
                      "YYYY-MM-DDThh:mm:ss.SSSZ"
                    ).fromNow()
                  )}
                </small>
                <small className="d-block d-sm-none">
                  Created:{" "}
                  {getReadableDate(
                    item.created,
                    moment(item.created, "YYYY-MM-DDThh:mm:ss.SSSZ").fromNow()
                  )}
                </small>
                <span className="pull-right d-none d-md-block">
                  Updated:{" "}
                  {getReadableDate(
                    item.lastUpdate,
                    moment(
                      item.lastUpdate,
                      "YYYY-MM-DDThh:mm:ss.SSSZ"
                    ).fromNow()
                  )}
                </span>
                <span className="d-none d-md-block">
                  Created:{" "}
                  {getReadableDate(
                    item.created,
                    moment(item.created, "YYYY-MM-DDThh:mm:ss.SSSZ").fromNow()
                  )}
                </span>
              </div>
              <p className="mb-1">{item.subject}</p>
              <div className="lead">
                <span className="">
                  <StatusBadge
                    provider={appState.orgType}
                    status={item.status}
                  />
                </span>
                <span className="pl-3">
                  Priority:
                  <MultiProviderPriorityBadge
                    provider={appState.orgType}
                    priority={item.priority}
                  />
                </span>
                <span className="pl-3">
                  Type:
                  <span className="pl-2">
                    {stateApp.hasPortalAccess
                      ? ConnectriaTicketsService.ticketType(item.ticket_type_id)?.name || ''
                      : item.ticket_type_id}
                  </span>
                </span>
                <div className="d-md-inline-block pl-lg-3 pt-1 pt-lg-0">
                  Owner:
                  <span className="pl-2">{createdBy(item.user)}</span>
                </div>
              </div>
            </button>
          );
        })}
      </>
    );
  }
}

const TicketExportButton = (props) => {
  const [gettingData, setGettingData] = useState(false);
  const [stateApp, stateAppActions] = appState();

  function getDevicesString(devices) {
    var devicesString = "";
    for (var i = 0; i < devices.length; i++) {
      devicesString += devices[i].assettag;
      if (i < devices.length - 1) {
        devicesString += " - ";
      }
    }
    return devicesString;
  }

  function getPrioritiesMap() {
    const priorities = ConnectriaTicketsService.ticketPriorities();
    var prioritiesMap = {};
    for (var p of priorities) {
      prioritiesMap[p.id] = p.name;
    }
    return prioritiesMap;
  }

  function getStatusValue(status) {
    if (status === "New") {
      return 1;
    } else if (status === "Open") {
      return 2;
    } else if (status === "WaitOnCust") {
      return 3;
    }
    return 4;
  }

  const exportTickets = async (statuses, filename_prefix) => {
    let tickets = await ConnectriaTicketsService.exportAllTickets();
    setGettingData(false);
    if (tickets.length === 0) return;

    // convert tickets to CSV format
    tickets = tickets.map((ticket) => {
      let created = moment(ticket.Created).format("MM/DD/YY hh:mm:ss");
      let lastUpdate = moment(ticket["Last Update On"]).format(
        "MM/DD/YY hh:mm:ss"
      );
      return {
        Id: ticket.Id,
        CustomerTicket: ticket.CustomerTicket,
        "Priority/Type": ticket.Priority + " / " + ticket.Type,
        Status:
          ticket.Status === "WaitOnCust" ? "Input Required" : ticket.Status,
        Owner: ticket.Owner,
        Team: ticket.Team,
        Subject: ticket.Subject,
        Created: created + " " + ticket["Last Update By"],
        "Last Update": created === lastUpdate ? null : lastUpdate,
        Devices: ticket.Devices,
        "Ticket Link": `${window.location.origin}/app/view/ticket/${ticket.Id}`,
      };
    });
    tickets.sort((a, b) => getStatusValue(a.Status) - getStatusValue(b.Status));

    const filename =
      stateApp.userInfo.organization_name +
      filename_prefix +
      moment().format("YYYY-MM-DD_hh-mm-ss") +
      ".csv";
    CSVUtility.downloadCSVFromJson(filename, tickets);

    stateAppActions.setAlert({
      content: <p style={{ color: "white" }}>Tickets successfully exported</p>,
      className: "success",
      visible: true,
    });
    setTimeout(() => {
      stateAppActions.setAlert((prevState) => ({
        ...prevState,
        visible: false,
      }));
    }, 2000);
  };

  return (
    <span className="ml-3 my-auto" style={{ fontSize: "15px" }}>
      {gettingData ? (
        <i className="fas fa-spinner fa-spin" id="ticket-export-loading" />
      ) : (
        <>
          <i
            className="fas fa-file-export"
            id="ticket-export-button"
            onClick={() => {
              setGettingData(true);
              exportTickets(
                [
                  "New",
                  "Open",
                  "Scheduled",
                  "WaitOnCust",
                  "Resolved",
                  "Closed",
                  "Suspended",
                  "Cancelled",
                ],
                "_All_Tickets_"
              );
            }}
          />
          <UncontrolledTooltip placement="right" target="ticket-export-button">
            Export All Tickets For 3 Months
          </UncontrolledTooltip>
        </>
      )}
    </span>
  );
};

// TODO: move this somewhere else
function getProviderFromState({ organization }) {
  if (organization.customer_id.startsWith("LE")) return "LE";
  return "TRIA";
}

function Inbox(props) {
  //plugin state
  const [state, stateActions] = useTriaTicketsState();
  //local state
  const [loadMore, setLoadMore] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(true);
  const [offset, setOffset] = useState(0);
  const [filterState, setFilterState] = useState({});
  const [advancedFilterState, setAdvancedFilterState] = useState({});
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const source = axios.CancelToken.source();
  const provider = props.stateApp.orgType || PROVIDERS.CONNECTRIA;

  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchTickets(currentPage);
  }, [currentPage]);

  const fetchTickets = async (page) => {
    stateActions.setIsLoading(true);
    setIsError(false);
    try {
      const ticketsResponse = await TicketService.getTickets(page, itemsPerPage);
      const tickets = ticketsResponse.data.body.tickets || [];
      setTotalItems(ticketsResponse.data.body.totalTickets);
      stateActions.setTickets(tickets);
    } catch (error) {
      setIsError(true);
      console.error("Error fetching tickets:", error);
    } finally {
      stateActions.setIsLoading(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  function handleScroll() {
    const scrollTop = (document.scrollingElement || document.documentElement)
      .scrollTop;
    if (
      window.innerHeight + scrollTop + 1 <
      document.documentElement.offsetHeight
    )
      return;
    setLoadMore(true);
  }

  // fetch tickets
  useAsyncEffect(
    async (isMounted) => {
      let tickets = [];
      const clearInbox = true;

      // set loading state
      stateActions.setIsLoading(true);
      if (clearInbox) {
        stateActions.clearTickets();
      }


      try {
        const ticketsResponse = await TicketService.getTickets();
        tickets = ticketsResponse.data.body.tickets || [];

        // update tickets state
        for (var i = 0; i < tickets.length; i++) {
          stateActions.addTicket(tickets[i]);
        }
        stateActions.setIsLoading(false);

        if (!isMounted()) return;

        setOffset(TICKETPULLSIZE);

        if (tickets.length > 0) {
          setDataAvailable(true);
          window.addEventListener("scroll", handleScroll);
          return () => window.removeEventListener("scroll", handleScroll);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      console.log("unmount canceled");
      source.cancel();
    },
    [currentPage, filterState, advancedFilterState]
  );

  useEffect(() => {
    if (!loadMore || !dataAvailable) return;
    getMoreData(loadMore);
    setLoadMore(false);
  }, [loadMore, dataAvailable]);

  const getMoreData = (load) => {
    if (load && !state.isLoading) {
      (async function loadTickets() {
        let getTicketsFunction;
        let filter;
        if (showAdvancedSearch) {
          getTicketsFunction = getTicketsAdvancedSearch;
          filter = advancedFilterState;
        } else {
          getTicketsFunction = getTickets;
          filter = filterState;
        }

        var cnt = await getTicketsFunction(
          stateActions,
          offset,
          null,
          filter,
          state.statusId,
          { cancelToken: source.token }
        );
        setOffset(offset + TICKETPULLSIZE);
        if (cnt === 0) {
          setLoadMore(false);
          setDataAvailable(false);
          window.removeEventListener("scroll", handleScroll);
        }
      })();
    }
  };

  const toggleAdvancedSearch = (e) => {
    setShowAdvancedSearch(!showAdvancedSearch);
    setOffset(0);
  };

  return (
    <>
      {showAdvancedSearch && (
        <AdvancedTicketSearch
          inboxFilterState={advancedFilterState}
          setInboxFilterState={setAdvancedFilterState}
        />
      )}
      {!showAdvancedSearch && (
        <TicketFilters
          filterState={filterState}
          setFilterState={setFilterState}
          currentPage={currentPage}
        />
      )}
      {!provider === "LIGHTEDGE" && (
        <div className="inline form-check" style={{ paddingLeft: "2px" }}>
          <label className="form-check-label">
            <nobr>Advanced Search</nobr>
            <input
              type="checkbox"
              className="form-check-input"
              checked={showAdvancedSearch}
              onChange={toggleAdvancedSearch}
            />
            <span className="form-check-sign">
              <span className="check"></span>
            </span>
          </label>
        </div>
      )}
      <div className="list-group mt-1" id="connectria-db-tickets">
        <InboxItems
          tickets={state.tickets}
          stateApp={props.stateApp}
          stateAppActions={props.stateAppActions}
          statePlugin={props.statePlugin}
          statePluginActions={props.statePluginActions}
        />
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="btn btn-primary"
        >
          Previous
        </button>

        <span className="mx-2">
          Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)} | Total
          Records: {totalItems}
        </span>

        <button
          onClick={handleNextPage}
          disabled={currentPage >= Math.ceil(totalItems / itemsPerPage)}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>

      {!state.isLoading && isError && (
        <div className="text-center mt-4 text-bold">
          <i className="fas fa-exclamation-triangle"></i> Error Loading Tickets.
        </div>
      )}
      {!state.isLoading && !isError && state.tickets.length === 0 && (
        <div className="text-center mt-4 text-bold">
          No tickets found. Try adjusting your filter.
        </div>
      )}
      {state.isLoading && (
        <div className="text-center">
          <i
            className="fas fa-spinner m-3 mt-4 fa-spin"
            style={{ fontSize: "30px" }}
          ></i>
        </div>
      )}
    </>
  );
}

const ConnectriaTicketsDashboardTab = () => {
  //global state
  const [stateApp, stateAppActions] = appState();

  return (
    <>
      <h3 id="connectria-ticket-db-body-title">Ticketing Inbox</h3>
      <Inbox stateApp={stateApp} stateAppActions={stateAppActions}></Inbox>
    </>
  );
};

const TicketFilters = (props) => {
  const { filterState, setFilterState, currentPage } = props;
  const [stateApp, stateAppActions] = appState();
  const [state, stateActions] = useTriaTicketsState();
  const [statusId, setStatusId] = useState("0");
  const [ticketNo, setTicketNo] = useState("");
  const [keyword, setKeyword] = useState("");
  const [filterMine, setFilterMine] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [originalTickets, setOriginalTickets] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const ticketsResponse = await YetiTickets.getTickets(
          "PORTAL",
          currentPage,
          10
        );
        const ticketsData = ticketsResponse.data.results.map(YetiTickets.parseTicket);
        setOriginalTickets(ticketsData)

        const { priorityOptions, typeOptions } = ticketsData.reduce(
          (acc, ticket) => {
            if (!acc.priorities.has(ticket.priority)) {
              acc.priorities.add(ticket.priority);
              acc.priorityOptions.push({
                value: ticket.priority,
                label: ticket.priority,
              });
            }
            if (!acc.types.has(ticket.ticket_type_id)) {
              acc.types.add(ticket.ticket_type_id);
              acc.typeOptions.push({
                value: ticket.ticket_type_id,
                label: ticket.ticket_type_id,
              });
            }
            return acc;
          },
          {
            priorities: new Set(),
            types: new Set(),
            priorityOptions: [],
            typeOptions: [],
          }
        );

        setPriorityOptions(priorityOptions);
        setTypeOptions(typeOptions);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTickets();
  }, [currentPage]);

  const handlePriorityChange = (selectedOptions) => {
    setSelectedPriority(selectedOptions);
  };

  const handleTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions);
  };

  // Will move this to a separate component later
  const CustomDropdown = ({
    options,
    selectedOptions,
    setSelectedOptions,
    placeholder,
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
      const alreadySelected = selectedOptions.find(
        (selected) => selected.value === option.value
      );

      if (alreadySelected) {
        setSelectedOptions(
          selectedOptions.filter((selected) => selected.value !== option.value)
        );
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    };

    const removeSelectedOption = (optionToRemove) => {
      const newSelectedOptions = selectedOptions.filter(
        (option) => option.value !== optionToRemove.value
      );
      setSelectedOptions(newSelectedOptions);

      if (searchTerm || newSelectedOptions.length > 0 || selectedType.length > 0) {
        let filteredTickets = originalTickets;

        if (searchTerm) {
          filteredTickets = filteredTickets.filter((ticket) => {
            return (
              (ticket.name && ticket.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
              (ticket.subject && ticket.subject.toLowerCase().includes(searchTerm.toLowerCase())) ||
              (ticket.description && ticket.description.toLowerCase().includes(searchTerm.toLowerCase()))
            );
          });
        }

        if (newSelectedOptions.length > 0) {
          filteredTickets = filteredTickets.filter((ticket) =>
            newSelectedOptions.some((p) => p.value === ticket.priority)
          );
        }

        if (selectedType.length > 0) {
          filteredTickets = filteredTickets.filter((ticket) =>
            selectedType.some((t) => t.value === ticket.ticket_type_id)
          );
        }

        stateActions.setTickets(filteredTickets);
      } else {
        stateActions.setTickets(originalTickets);
      }
    };

    const maxVisibleTags = 2;

    return (
      <div className="custom-dropdown" onClick={toggleDropdown}>
        <div className="dropdown-header" tabIndex={0}>
          <div className="selected-tags">
            {selectedOptions.slice(0, maxVisibleTags).map((opt) => (
              <span key={opt.value} className="selected-tag">
                {opt.label}
                <button
                  className="close-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedOption(opt);
                  }}
                >
                  &times;
                </button>
              </span>
            ))}
            {selectedOptions.length > maxVisibleTags && (
              <span className="selected-tag">
                +{selectedOptions.length - maxVisibleTags}{" "}
              </span>
            )}
          </div>
          <span
            className={
              selectedOptions.length > 0 ? "selected-label" : "placeholder"
            }
          >
            {selectedOptions.length === 0 ? placeholder : ""}
          </span>
          <i className="fa fa-caret-down icon"></i>
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            {options.map((option) => (
              <li
                key={option.value}
                className={`dropdown-item ${selectedOptions.some((selected) => selected.value === option.value) ? "selected" : ""}`}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    let filteredTickets = originalTickets;

    // Search term filtering
    if (searchTerm) {
      filteredTickets = filteredTickets.filter((ticket) => {
        return (
          (ticket.name &&
            ticket.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (ticket.subject &&
            ticket.subject.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (ticket.description &&
            ticket.description
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
        );
      });
    }

    // Filter by selected priorities
    if (selectedPriority.length > 0) {
      const selectedPriorityValues = selectedPriority.map(p => p.value);
      filteredTickets = filteredTickets.filter((ticket) =>
        selectedPriorityValues.includes(ticket.priority)
      );
    }

    // Filter by selected types
    if (selectedType.length > 0) {
      const selectedTypeValues = selectedType.map(t => t.value);
      filteredTickets = filteredTickets.filter((ticket) =>
        selectedTypeValues.includes(ticket.ticket_type_id)
      );
    }

    stateActions.setTickets(filteredTickets);
  };

  const handleStatusChange = (e) => {
    let newStatusId = e.target.value;
    setStatusId(statusId);
    stateActions.setStatusId(e.target.value);
    let sort_by = "created desc";
    if (newStatusId === 0) {
      sort_by =
        "case when status = 'New' then '1' " +
        "when status = 'Open' then '2' " +
        "when status = 'WaitOnCust' then '3' " +
        "when status = 'Scheduled' then '4' " +
        "else status end asc, created desc";
    }
    setFilterState({
      ...filterState,
      status: statusFilters[newStatusId],
      sort_by: sort_by,
    });
  };

  const handleFilterMine = async (filterMine) => {
    setFilterMine(filterMine);
    setFilterState({ ...filterState, filterMine });
  };

  const handleKeywordChange = (e, newKeyword) => {
    e.preventDefault();
    setFilterState({ ...filterState, keyword: [newKeyword] });
  };

  const ticketEntry = (e) => {
    if (e) {
      e.preventDefault();
    }
    showItemBlade(ticketNo, PROVIDERS.CONNECTRIA, stateAppActions);
    setTicketNo("");
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedPriority([]);
    setSelectedType([]);

    stateActions.setTickets(originalTickets);
  };

  if (stateApp.orgType === PROVIDERS.CONNECTRIA) {
    return (
      <div className="row">
        <div className="row col-lg-4 col-sm-6 mt-2">
          <div className="col-6">
            <Input
              type="select"
              name="filterStatus"
              id="filterStatus"
              onChange={handleStatusChange}
              value={state.statusId}
            >
              <option value="0">All Open</option>
              <option value="1">New</option>
              <option value="2">Open</option>
              <option value="3">Input Required</option>
              <option value="4">Scheduled</option>
              <option value="5">Resolved</option>
              <option value="6">Closed</option>
              <option value="7">Suspended</option>
              <option value="8">Cancelled</option>
            </Input>
          </div>
          <div className="col-6">
            <form onSubmit={(e) => ticketEntry(e)}>
              <Input
                onChange={(e) => setTicketNo(e.target.value)}
                type="text"
                placeholder="Ticket #"
                value={ticketNo}
              />
            </form>
          </div>
        </div>
        <div className="d-flex flex-fill col-lg-auto col-sm-12 mt-2">
          <form
            className="flex-fill"
            onSubmit={(e) => handleKeywordChange(e, keyword)}
          >
            <Input
              onChange={(e) => setKeyword(e.target.value)}
              type="text"
              placeholder="Search Subject and Issue"
              value={keyword}
            />
          </form>
          <i
            className="fa fa-times mr-2"
            style={{
              marginLeft: -20 + "px",
              marginTop: 12 + "px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setKeyword("");
              handleKeywordChange(e, "");
            }}
          ></i>
        </div>
        <div
          className="col-lg-3 col-sm-6 row justify-content-between mt-2"
          style={{ maxWidth: "175px", marginLeft: "2px" }}
        >
          <FormGroup className="">
            <div className="position-relative form-check">
              <label className="form-check-label">
                <nobr>My Tickets</nobr>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={filterMine}
                  onChange={(e) => handleFilterMine(e.target.checked)}
                />
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </label>
            </div>
          </FormGroup>
          <div className="my-auto">
            <NewTicketButton className="connectria-tickets-inbox-btn">
              <i className="fas fa-plus" id="inbox-new-ticket-button"></i>
            </NewTicketButton>
            <UncontrolledTooltip
              placement="right"
              target="inbox-new-ticket-button"
            >
              Create a ticket
            </UncontrolledTooltip>
          </div>
          <TicketExportButton />
        </div>
      </div>
    )
  }

  return (
    <div className="main-container">
      {/* Search Component */}
      <div className="enhanced-search-bar">
        <input
          type="text"
          placeholder="Search by name and description"
          value={searchTerm}
          onChange={handleInputChange}
        />
        {/* Custom dropdown for Priority */}
        <div className="select-group">
          <CustomDropdown
            options={priorityOptions}
            selectedOptions={selectedPriority}
            setSelectedOptions={handlePriorityChange}
            placeholder="Select Priority"
          />
          {/* Custom dropdown for Type */}
          <CustomDropdown
            options={typeOptions}
            selectedOptions={selectedType}
            setSelectedOptions={handleTypeChange}
            placeholder="Select Type"
          />
        </div>
      </div>
      <div className="button-group">
        <button className="search-button" onClick={handleSearch}>
          <i className="fa fa-search"></i> Search
        </button>
        <button
          className="clear-filters-button"
          onClick={handleClearFilters}
        >
          <i className="fa fa-times"></i> Clear Filters
        </button>

        <div className="my-auto">
          <NewTicketButton className="round-button">
            <i className="fas fa-plus" id="inbox-new-ticket-button"></i>
          </NewTicketButton>
          <UncontrolledTooltip
            placement="right"
            target="inbox-new-ticket-button"
          >
            Create a ticket
          </UncontrolledTooltip>
        </div>
      </div>
    </div >
  );
};

export { ConnectriaTicketsDashboardTab, Inbox };
