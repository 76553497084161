import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";

const base_api =
  "https://4jsb2f47s8.execute-api.us-east-1.amazonaws.com/dev/api";

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: base_api,
    headers: { Authorization: idToken },
  });

  return req;
}

const YetiTicketProvider = {
  async getTickets(sort = 'PORTAL', page = 1, pageSize = 5) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/ticketlist?sort=${sort}&cache=1&page=${page}&pagesize=${pageSize}`);
  },
  async getAllTickets() {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/ticketlist?sort=PORTAL&cache=1`);
  },
  async getAttachment(attachment_id, ticket_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/attachment?attachment_id=${attachment_id}&ticket_id=${ticket_id}`, { headers: { Accept: 'application/octet-stream' } });
  },
  async getTicket(ticket_id, axiosConfig = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/ticket?ticket_id=${ticket_id}&cache=1`, axiosConfig);
  },
  /**
 * Creates a new ticket in the system for the specified organization.
 *
 * @async
 * @function
 * @param {string} orgId - The identifier for the organization creating the ticket.
 * @param {string} requesterId - The identifier for the contact of the person requesting the ticket. This is required.
 * @param {string} shortDescription - A very short description of the ticket. This is required.
 * @param {string} [service_id] - The identifier for the service associated with the ticket. This is optional.
 * @param {string} description - The initial description of the ticket issue/request. This is required.
 * @param {string} priority - The urgency of the ticket as specified by the requester. This is required.
 * 
 * @returns {Promise<Object>} - The response from the API after attempting to create the ticket.
 *
 * @throws {Error} - Throws an error if the request fails.
 */
  async createTicket(orgId, requesterId, shortDescription, service_id, description, priority) {
    const req = await defaultRequest();
    const data = {
      requester_id: requesterId,
      short_description: shortDescription,
      service_id,
      description,
      internal_use: false,
      origination_code: 'CUSTOMER_PORTAL',
      priority
    };

    return req.post(`${base_api}/${orgId}/tickets/ticket`, data);
  },
  async modifyTicketRequester(orgId, ticket_id, requesterId) {
    const req = await defaultRequest();
    const data = {
      requester_id: requesterId,
      ticket_id: ticket_id
    };

    return req.put(`${base_api}/${orgId}/tickets/ticket`, data);
  },
  async createRecipient(orgId, ticket_id, email) {
    const req = await defaultRequest();
    const data = {
      ticket_id: ticket_id,
      email: email
    };

    return req.post(`${base_api}/${orgId}/tickets/recipient`, data);
  },
  /**
 * Fetches the list of comments for a specific ticket.
 *
 * @async
 * @function getTicketComments
 * @param {string} ticket_id - The unique identifier for the ticket whose comments are being fetched.
 * @param {object} [axiosConfig={}] - Optional Axios configuration object, typically used for passing a `cancelToken` to cancel the request if needed.
 * @param {CancelToken} [axiosConfig.cancelToken] - Axios cancel token to allow request cancellation.
 * @returns {Promise<object>} - A promise that resolves with the response data containing the list of comments for the specified ticket.
 *
 * @throws {Error} - Throws an error if the request fails or is canceled.
 */
  async getTicketComments(ticket_id, axiosConfig = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/commentlist?ticket_id=${ticket_id}&cache=1`, axiosConfig);
  },
  async deleteRecipient(orgId, ticket_id, email) {
    const req = await defaultRequest();
    const data = new URLSearchParams();
    data.append('ticket_id', ticket_id);
    data.append('email', email);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data.toString()
    };

    return req.delete(`${base_api}/${orgId}/tickets/recipient`, config);
  },
  async updateRecipients(orgId, ticketId, emails, detail) {
    const inputEmails = emails.filter(email => !detail.recipients.some(recipient => recipient.email === email));
    const deleteEmails = detail.recipients.filter(recipient => !emails.some(email => email === recipient.email)).map(recipient => recipient.email);

    const createPromises = inputEmails.map(recipientEmail =>
      this.createRecipient(orgId, ticketId, recipientEmail)
    );
    await Promise.all(createPromises).catch(error => {
      console.error("Failed to create one or more recipients:", error);
    });

    // ******* This should be live when delete API is available **************
    const deletePromises = deleteEmails.map(recipientEmail =>
      this.deleteRecipient(orgId, ticketId, recipientEmail)
    );
    await Promise.all(deletePromises).catch(error => {
      console.error("Failed to delete one or more recipients:", error);
    });
    detail.recipients = emails.map(email => ({ email }));
  },
  /**
 * Uploads an attachment to a specific ticket for an organization.
 *
 * @async
 * @function createTicketAttachment
 * 
 * @param {string} ticket_id - The unique identifier for the ticket to which the attachment will be associated.
 * @param {string} contact_id - The unique identifier for the contact uploading the attachment.
 * @param {string} name - The human-readable name of the attachment.
 * @param {string} filename - The original filename of the file becoming an attachment.
 * @param {string} document - The file to be uploaded, encoded as a base64 string.
 *
 * @returns {Promise<Object>} A promise that resolves with the server response after the attachment is uploaded.
 *
 * @throws {Error} Will throw an error if the request fails.
 */
  async createTicketAttachment(ticket_id, contact_id, name, filename, document) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.post(base_api + `/${orgId}/tickets/attachment`, { ticket_id, contact_id, name, filename, document });
  },
  async createTicketComment(ticket_id, contact_id, message) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.post(base_api + `/${orgId}/tickets/comment`, { ticket_id, contact_id, message });
  },
  /**
 * Fetches the list of attachments for a specific ticket.
 *
 * @async
 * @function getTicketAttachments
 * @param {string} ticket_id - The unique identifier for the ticket whose attachments are being fetched.
 * @param {object} [axiosConfig={}] - Optional Axios configuration object, typically used for passing a `cancelToken` to cancel the request if needed.
 * @param {CancelToken} [axiosConfig.cancelToken] - Axios cancel token to allow request cancellation.
 * @returns {Promise<object>} - A promise that resolves with the response data containing the list of attachments for the specified ticket.
 *
 * @throws {Error} - Throws an error if the request fails or is canceled.
 */
  async getTicketAttachments(ticket_id, axiosConfig = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/attachmentlist?ticket_id=${ticket_id}&cache=1`, axiosConfig);
  },
  async getTicketRecipients(ticket_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/recipientlist?ticket_id=${ticket_id}&cache=1`);
  },
  async getDocuments(context, context_id) {
    const req = await defaultRequest();
    let request = req.get(
      base_api +
      `/documents/documentlist?context=${context}&context_id=${context_id}`
    );
    return request;
  },
  async download_document(doc_id) {
    const req = await defaultRequest();

    let request = req.get(
      base_api + `/documents/document?document_id=${doc_id}`,
      { Accept: "application/octet-stream" }
    );
    return request;
  },
  parseTicket(ticket) {

    return ({
      id: ticket.id,
      name: ticket.requester_name,
      created: ticket.created,
      priority: ticket.priority,
      description: ticket.description,
      subject: ticket.short_description,
      issue: ticket.description || '',
      errorDesc: '',
      user_id: ticket.requester_id,
      assigned_to: ticket.handler_id || '',
      status: ticket.status,
      lastUpdate: ticket.updated,
      timeSpentMins: 0,
      ticketcategory_id: ticket.category,
      InitialContact: false,
      device: ticket.service || '',
      device_id: ticket.service_id || '',
      closed_on: ticket.closed || ticket.created,
      customer_id: ticket.customer_id,
      enteredBy: ticket.requester_id,
      ticket_update_status_id: ticket.customer_facing_status,
      order_id: ticket.order_id || null,
      nbTimeSpentMins: 0,
      parent_ticket_id: null,
      salesreviewed: false,
      salesreviewedby_id: null,
      salesreviewed_on: null,
      customer_application_name: null,
      team_id: null,
      first_assigned_on: null,
      last_conn_update_on: ticket.created,
      next_conn_update_on: ticket.updated,
      last_cust_update_on: ticket.updated,
      resolved_by_id: ticket.handler_id,
      resolved_on: ticket.resolved,
      closed_by_id: ticket.closed ? ticket.handler_id : null,
      ticket_type_id: ticket.type,
      cancelled_by_id: null,
      cancelled_on: null,
      last_woc_date: null,
      planned_start_date: null,
      planned_end_date: null,
      customer_segment: "Not Assigned",
      user: {
        id: ticket.requester_id,
        login: `${ticket.requester_name?.replace(' ', '').toLowerCase()}`,
        email: `${ticket.requester_name?.replace(' ', '').toLowerCase()}`,
        fname: ticket.requester_name?.split(' ')[0],
        surname: ticket.requester_name?.split(' ')[1] || '',
        role_id: 1,
        customer_id: ticket.customer_id,
        phone: '',
        fax: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        primaryUser: false,
        passresetphrase: null,
        lastpagehit: null,
        lastpagerequest: null,
        lastlogin: null,
        monitorAccess: false,
        homePhone: null,
        cellPhone: null,
        comments: '',
        browserAgent: null,
        active: true,
        lastip: null,
        updated_on: new Date().toISOString(),
        created_on: ticket.created,
        title: '',
        office_hours_start: null,
        office_hours_end: null,
        manager_user_id: null,
        primary_team_id: null,
        serviceUser: false,
        cognito_user_guid: '',
        login_supported: true,
        le_customer_id: null,
        le_contact_id: null
      }
    });
  },
  parseTicketAttachment(original, ticket_id) {
    return {
      id: original.id,
      created: original.created,
      filename: original.filename,
      odin_path: original.author,  // Assuming `author` URL can be used similarly to `odin_path`
      odin_uploaded: true,  // Assuming the file is successfully uploaded
      path: '',  // You might want to generate this dynamically based on some logic
      ticket_id: ticket_id,  // Same as above
      author_id: original.author_id,
      author_name: original.author_name,
      comment_id: original.comment_id,
      mimetype: original.mimetype,
      name: original.name,
      type: original.type,
      updated: original.updated
    };
  },
  // TODO: update this
  parseTicketComment(comment, ticket_id) {
    return {
      id: comment.id,
      ticket_id: ticket_id,
      user_id: comment.author_id,
      comment: comment.message,
      created: comment.created,
      pvt: false,
      billTimeSpentMins: 0,
      nonBillTimeSpentMins: 0,
      status: "LegacyComment",
      updated_on: comment.updated || comment.created,
      user: {
        id: comment.author_id,
        login: "",
        email: "",
        fname: comment.author_name.split(" ")[0] || "",
        surname: comment.author_name.split(" ")[1] || "",
        role_id: 0,
        customer_id: 0,
        phone: "",
        fax: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        primaryUser: false,
        monitorAccess: false,
        homePhone: "",
        cellPhone: "",
        active: true,
        title: "",
        serviceUser: false,
        login_supported: true,
      }
    }
  },
  // TODO: implement this function
  parseTicketRecepient(recipient) {
    return {}
  },
  // TODO: implement this function
  parseTicketDocument(doc) {
    return {}
  }
};

export default YetiTicketProvider;
