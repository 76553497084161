import React, { Fragment } from "react";
import { useEffect, useState } from "react"
import { appState } from "../../AppState";
import useAsyncEffect from "../../utility/use-async-effect";
import axios from "axios";
import ConnectriaBackupService from "../../services/ConnectriaBackupService";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";
import {NewTabComponent} from "../../utility/NewTabComponent";
import { useHistory, Link } from "react-router-dom";
import {AllJobsReusable} from "./AllJobsReusable";
import moment from "moment";
import NewTicketButton from "../SupportAndTicketing/NewTicketButton";

const BackupSingleJob = props => {
  const source = axios.CancelToken.source();
  const [singleJob,setSingleJob] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [IsLoadingSubClient, setIsLoadingSubClient] = useState(false);
  const [stateApp, stateAppActions] = appState();

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async function showItemBlade(job, stateApp, stateAppActions) {
    let details = await ConnectriaBackupService.getCommServeSubclientDetails(job['subclientId'],{cancelToken: source.token})
    let data = details['data']['subClientProperties'].map(client => {
      let clientContent = client['content'] || client['vmContent']['children']
      return clientContent.map(c =>{
        return  c.hasOwnProperty('mssqlDbContent') ? c['mssqlDbContent']['databaseName'] : c['displayName']
      })
    }).flat()
    stateAppActions.setBlade({
      title:
        <span>{'#'+ 'SubClient Name: '}{job['subclientName']}</span>,
      visible: true,
      content: (
          <div className="table-responsive-sm">
            <h1>Contents</h1>
          <table className="table table-hover">
            <tbody>
            { data[0] ? (
                data.map((item,index) => {
              console.log(item)
              return (
                  <tr key={index}>
                    <td>{item}</td>
                  </tr>
              )
            })
            ) : (<tr className="text-center"><td>No Contents found</td></tr>)}
            </tbody>
          </table>
          </div>
      )
    });
  }

  useAsyncEffect(
    async isMounted => {
      try {
        console.log("loading single job" + props.match.params.id);
        console.log(props.match.params.id)
        document.title = "Job ID#" + props.match.params.id;
        stateAppActions.setPageBackTitle(
          <Link to="/app/backup" className="text-primary">
            <i className="fas fa-chevron-left mr-1" />
            <span>Backup Jobs</span>
          </Link>
        );
        setIsLoading(true);
        let details = await ConnectriaBackupService.getCommServeJobDetails(props.match.params.id,{cancelToken: source.token})
        let data = details['data']['job']['jobDetail']
        let dataKey = ''
        let uiData = {}
        //if job is running there is no detailInfo key , instead data has progessInfo
        props.match.params.status.toLowerCase() === 'running' ? dataKey = 'progressInfo' : dataKey = 'detailInfo'
        uiData['sizeOfApplication'] = data[dataKey]['sizeOfApplication']
        uiData['appName'] = data['generalInfo']['subclient']['appName']
        uiData['failures'] = data[dataKey]['failures']
        uiData['transferTime'] = data[dataKey]['transferTime']
        uiData['dataWritten'] = data[dataKey]['dataWritten']
        uiData['throughPut'] = data[dataKey]['throughPut'] + 'GB/hr'
        uiData['encrypted'] = data['generalInfo']['encrypted']
        uiData['jobStartedFrom'] = data['generalInfo']['jobStartedFrom']
        uiData['status'] = data['progressInfo']['state']
        uiData['status'].toLowerCase().indexOf('error') > -1 ? uiData['errorInJob'] = true : uiData['errorInJob'] = false
        uiData['subclientName'] = data['generalInfo']['subclient']['subclientName']
        uiData['destClientId'] = data['generalInfo']['subclient']['clientId']
        uiData['destClientName'] = data['generalInfo']['subclient']['clientName']
        uiData['subclientId'] = data['generalInfo']['subclient']['subclientId']
        uiData['startTime'] = data[dataKey]['startTime']
        uiData['endTime'] = data[dataKey]['endTime']
        setSingleJob(uiData)

        stateAppActions.setPageBackTitle(
          <Link to={`/app/view/backup/jobs/client/${uiData['destClientId']}`} className="text-primary">
            <i className="fas fa-chevron-left mr-1" />
            <span>Backup Jobs</span>
          </Link>
        );

        if (!isMounted()) return;

        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error)
        } else {
          console.log(error)
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  return (
    <div className="row justify-content-center mt-2">
      <div className="col-lg-12">
        <Card className="card-minimal">
          <CardHeader className="card-header-minimal mb-2">
            <CardTitle className="card-title-minimal" tag="h3" style={{fontSize: 2 + "rem"}}>
              Job ID#: {props.match.params.id}
            </CardTitle>
          </CardHeader>
          { isLoading ? (
               <div className="text-center">
          <i
            className="fas fa-spinner m-3 mt-4 fa-spin"
            style={{ fontSize: "30px" }}
          ></i>
        </div>
          ) : ( <div className="table-responsive-sm">
            <table className="table table-hover" id="csDetails">
                <tbody>
                <tr>
                    <td>Job Start Time:</td>
                    <td>
                      <span className="font-weight-bold">
                        {singleJob['startTime'] > 0 ? moment(singleJob['startTime'], 'X').format('lll') : "N/A"}
                      </span>
                    </td>
                  </tr>
                <tr>
                    <td>Job End Time:</td>
                    <td>
                      <span className="font-weight-bold">
                        {singleJob['endTime'] > 0 ? moment(singleJob['endTime'], 'X').format('lll') : "N/A"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Size of Application:</td>
                    <td>
                      <span className="font-weight-bold">
                        {formatBytes(parseInt(singleJob['sizeOfApplication']))}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Agent:</td>
                    <td>
                      <span className="font-weight-bold">
                        {singleJob['appName']}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Failures:</td>
                    <td><span className="font-weight-bold">{singleJob['failures']}</span></td>
                  </tr>
                  <tr>
                    <td>Transfer Time:</td>
                    <td><span className="font-weight-bold">{singleJob['transferTime']}</span></td>
                  </tr>
                  <tr>
                    <td>Data Written:</td>
                    <td><span className="font-weight-bold">{formatBytes(parseInt(singleJob['dataWritten']))}</span></td>
                  </tr>
                  <tr>
                    <td>Average Throughput:</td>
                    <td><span className="font-weight-bold">{singleJob['throughPut']}</span></td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>
                      <span className="font-weight-bold">{singleJob['status']}</span>
                      {singleJob['errorInJob'] ?
                          (<NewTicketButton className="connectria-tickets-inbox-btn my-1">
                            <i className="fas fa-plus" id="inbox-new-ticket-button"></i>
                          </NewTicketButton>) : <span></span>}
                    </td>
                  </tr>
                  <tr>
                    <td>Source Client computer:</td>
                    <td><span className="font-weight-bold">{singleJob['destClientName']}</span></td>
                  </tr>
                  <tr>
                    <td>Subclient:</td>
                    <td>
                      <button type="button"
                        onClick={(e) => showItemBlade(singleJob, stateApp, stateAppActions)}
                      >
                      <span className="font-weight-bold">{singleJob['subclientName']}</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Encryption Enabled:</td>
                    <td><span className="font-weight-bold">{singleJob['encrypted']}</span></td>
                  </tr>
                  <tr>
                    <td>Job Started From:</td>
                    <td><span className="font-weight-bold">{singleJob['jobStartedFrom']}</span></td>
                  </tr>
                </tbody>
            </table>
          </div> )
          }

        </Card>
      </div>
    </div>
  )
}

export {
  BackupSingleJob
};
